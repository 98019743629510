import { Grid, Paper, Typography } from '@material-ui/core';
import {
  Desktop, InfoTooltip, Mobile, SelectField, SkeletonQuestion, TextError
} from 'components';
import { useStores } from 'hooks';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormHelper, translate } from 'utils';
import { INPUT_TYPES, VALID } from 'utils/constants';
import {
  QuestionAddress, QuestionArithmetic, QuestionDate,
  QuestionDocument, QuestionEntity, QuestionEnum, QuestionFeedback,
  QuestionGPS, QuestionSignature, QuestionSurface, QuestionText
} from '../QuestionTypes';
import ErrorBoundaryQuestion from './ErrorBoundaryQuestion';

const TOOLTIP_HEIGHT = '42px';

const StyledQuestion = styled(Paper)`
  padding: 1rem;
  margin: 1rem 0;

  img {
    max-width: 100%;
  }

  button {
    max-width: 100%;
  }

  ${(props) => props.isinvalid && css`
    border-left: 6px solid var(--danger-color);
  `}
  ${(props) => props.isvalid && css`
    border-left: 6px solid var(--success-color);
  `}
`;

const CertificallIndicator = styled(Grid)`
  background: rgba(0, 0, 0, 0.7);
  color: white;
  max-height: 34px;
  width: fit-content !important;
  align-items: center !important;
  align-content: center !important;
  border-radius: 5px;
  padding-left: 10px;

  img {
    height: 20px;
    margin-right: 5px;
  }
`;

const StyledResult = styled(Grid)`
  .selectField > div {
    border-color: ${(props) => props.bgcolor};
    box-shadow: 0 0 0 1px ${(props) => props.bgcolor};
  }

  .selectField > span + div  {
    border-color: var(--tecnea-blue);
    box-shadow: 0 0 0 1px var(--tecnea-blue);
  }
`;

export const SubQuestionContainer = styled.aside`
  padding-left: 1rem;
  border-left: 4px solid var(--grey-dark);
`;

export const Result = ({ hasResult, result, onChangeResult }) => {
  const { referenceDataStore } = useStores();
  const { inspectionFormResultOptions } = referenceDataStore;

  const bgcolor = result ? 'var(--success-color)' : 'var(--danger-color)';

  return hasResult && (
    <StyledResult bgcolor={bgcolor} item sm={3} xs={12}>
      <SelectField
        label="common.result"
        name="result"
        options={inspectionFormResultOptions}
        placeholder="common.selectResult"
        value={result}
        onChange={onChangeResult}
      />
    </StyledResult>
  );
};

export const Declared = ({ hasDeclared, declared }) => (
  <Grid item sm={3} xs={12}>
    {hasDeclared && declared && (
      <>
        <Desktop>
          <Typography>
            {declared}
          </Typography>
        </Desktop>

        <Mobile>
          <Typography
            color="textSecondary"
            component="p"
            style={{ margin: '-2rem 0 0 1.5rem' }}
            variant="subtitle2"
          >
            {`${translate('forms.inputs.declaredShort')}: ${declared}`}
          </Typography>
        </Mobile>
      </>
    )}
  </Grid>
);

export const DeclaredAndResult = ({
  hasDeclared, declared, hasResult, result, onChangeResult
}) => (
  <>
    <Declared
      declared={declared}
      hasDeclared={hasDeclared}
    />

    <Result
      hasResult={hasResult}
      result={result}
      onChangeResult={onChangeResult}
    />
  </>
);

export const InputWrapper = ({
  children, declared, hasDeclared, hasResult = false, result, onChangeResult
}) => (
  <Grid item>
    <Grid alignItems="center" container spacing={2}>
      <Grid item sm={6} xs={12}>
        {children}
      </Grid>

      <Declared
        declared={declared}
        hasDeclared={hasDeclared}
      />

      {hasResult && (
        <Desktop>
          <Result
            hasResult={hasResult}
            result={result}
            onChangeResult={onChangeResult}
          />
        </Desktop>
      )}
    </Grid>
  </Grid>
);

const renderInput = ({
  questionType, name, label, value, options, decimal, certified,
  declared, result, required = true, placeholder = '', questionKey,
  onChange, canCreate, extraValue, isMulti = false, code,
  hasDeclared, hasResult, onChangeResult, maxAnswerCount, unit,
  codeArithmetic, formula, formulaElements, initialized, isDeclaredSet, questionText
}) => {
  switch (questionType) {
  case INPUT_TYPES.ENUM:
    return (
      <QuestionEnum
        canCreate={canCreate}
        declared={declared}
        extraValue={extraValue}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        isMulti={isMulti}
        label={label}
        name={name}
        options={options}
        placeholder={placeholder}
        questionText={questionText}
        required={required}
        result={result}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  case INPUT_TYPES.DATE:
    return (
      <QuestionDate
        declared={declared}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        initialized={initialized}
        isDeclaredSet={isDeclaredSet}
        label={label}
        name={name}
        placeholder={placeholder}
        questionText={questionText}
        required={required}
        result={result}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  case INPUT_TYPES.ENTITY:
    return (
      <QuestionEntity
        declared={declared}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        initialized={initialized}
        isDeclaredSet={isDeclaredSet}
        name={name}
        questionText={questionText}
        required={required}
        result={result}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  case INPUT_TYPES.ADDRESS:
    return (
      <QuestionAddress
        declared={declared}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        initialized={initialized}
        isDeclaredSet={isDeclaredSet}
        name={name}
        questionText={questionText}
        result={result}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  case INPUT_TYPES.SURFACE:
    return (
      <QuestionSurface
        code={code}
        codeArithmetic={codeArithmetic}
        declared={declared}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        maxAnswerCount={maxAnswerCount}
        name={name}
        questionText={questionText}
        required={required}
        result={result}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  case INPUT_TYPES.DOCUMENT:
    return (
      <QuestionDocument
        certified={certified}
        maxAnswerCount={maxAnswerCount}
        name={name}
        questionKey={questionKey}
        required={required}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  case INPUT_TYPES.SIGNATURE:
    return (
      <QuestionSignature
        name={name}
        questionText={questionText}
        value={value}
        onChange={onChange}
      />
    );
  case INPUT_TYPES.NUMBER:
    return (
      <QuestionText
        decimal={decimal}
        declared={declared}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        label={label}
        name={name}
        placeholder={placeholder}
        questionText={questionText}
        required={required}
        result={result}
        unit={unit}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  case INPUT_TYPES.ARITHMETIC:
    return (
      <QuestionArithmetic
        code={code}
        decimal={decimal}
        declared={declared}
        formula={formula}
        formulaElements={formulaElements}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        label={label}
        name={name}
        placeholder={placeholder}
        questionText={questionText}
        required={required}
        result={result}
        type="number"
        unit={unit}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  case INPUT_TYPES.GPS:
    return (
      <QuestionGPS
        value={value}
        onChange={onChange}
      />
    );
  case INPUT_TYPES.FEEDBACK:
    return (
      <QuestionFeedback
        name={name}
        value={value}
        onChange={onChange}
      />
    );
  case INPUT_TYPES.TEXT:
  default:
    return (
      <QuestionText
        declared={declared}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        initialized={initialized}
        isDeclaredSet={isDeclaredSet}
        label={label}
        name={name}
        placeholder={placeholder}
        questionText={questionText}
        required={required}
        result={result}
        type="text"
        unit={unit}
        value={value}
        onChange={onChange}
        onChangeResult={onChangeResult}
      />
    );
  }
};

export const Question = observer(({
  sectionId, id
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { inspectionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  const [question, setQuestion] = useState(null);
  const [options, setOptions] = useState([]);

  const name = inspectionStore.QUESTION_KEY({ sectionId, questionId: id });

  const loadQuestion = useCallback(() => {
    inspectionStore.getQuestion({ key: name })
      .then((storedQuestion) => {
        setQuestion(storedQuestion);
        if (storedQuestion && storedQuestion.questionType && storedQuestion.questionType.type === INPUT_TYPES.ENUM) {
          setOptions(storedQuestion.dataType.values);
        }
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [inspectionStore, name, enqueueSnackbar]);

  useEffect(() => {
    // Load the question from the indexedDB on render
    loadQuestion();
  }, [loadQuestion]);

  useEffect(() => {
    // Reload the question if needed
    if (inspectionStore.reloadKey === name) {
      inspectionStore.resetReloadKey();
      setIsLoading(true);
      loadQuestion();
    }
    // eslint-disable-next-line
  }, [inspectionStore.reloadKey]);

  const handleChange = useCallback((_, value, extraValue = null) => {
    let newQuestion = {
      ...question,
      measured: toJS(value),
      ...extraValue
    };

    // If the question validation process has already been done once, check on every change
    if (question.errorMessage || question.isValid) {
      const result = FormHelper.validateQuestion(newQuestion);
      newQuestion = {
        ...newQuestion,
        isValid: result === VALID,
        errorMessage: result !== VALID ? result : ''
      };
    }

    inspectionStore.saveQuestion({ key: name, question: newQuestion });
    setQuestion(newQuestion);
  }, [inspectionStore, name, question]);

  const handleChangeResult = useCallback((_, value) => {
    const newQuestion = {
      ...question,
      result: toJS(value)
    };

    inspectionStore.saveQuestion({ key: name, question: newQuestion })
      .then(() => setQuestion(newQuestion));
  }, [inspectionStore, name, question]);

  if (isLoading) {
    return <SkeletonQuestion />;
  }

  if (!question) {
    return <TextError>{translate('errors.noQuestion')}</TextError>;
  }

  return (
    <ErrorBoundaryQuestion question={question} questionId={id} sectionId={sectionId}>
      <StyledQuestion isinvalid={question.errorMessage} isvalid={question.isValid ? 1 : 0}>
        <Grid container direction="column" id={`question${id}`} spacing={1} wrap="nowrap">
          <Grid item>
            <Grid container direction="row" style={{ alignItems: 'center', justifyContent: 'space-between' }}>
              <Typography style={{ minHeight: question.tooltip ? TOOLTIP_HEIGHT : 'initial' }} variant="h3">
                {`${question.index}. ${question.title} `}
                {question.required && (<span style={{ color: 'var(--danger-color)', fontWeight: 600 }}>*</span>)}
                {question.tooltip && <InfoTooltip label={question.tooltip} />}
              </Typography>
              {question.certified && question.questionType?.type === INPUT_TYPES.DOCUMENT && (
                <CertificallIndicator container direction="row">
                  <img alt="Certificall logo" src={`${process.env.PUBLIC_URL}/assets/images/icons/certificall_logo.png`} />
                  {translate('commons.certificall.indication')}
                  <InfoTooltip label={translate('commons.certificall.tooltip')} />
                </CertificallIndicator>
              )}
            </Grid>
            {question.errorMessage && (
              <Typography style={{ color: 'var(--danger-color)' }}>
                {question.errorMessage}
              </Typography>
            )}
          </Grid>

          <Grid item>
            {renderInput({
              ...question,
              questionType: question.questionType && question.questionType.type,
              label: question.title,
              name,
              options,
              questionKey: inspectionStore.QUESTION_KEY({ sectionId, questionId: id }),
              value: question.measured,
              onChange: handleChange,
              onChangeResult: handleChangeResult
            })}
          </Grid>
        </Grid>
      </StyledQuestion>
    </ErrorBoundaryQuestion>
  );
});

Question.propTypes = {
  id: PropTypes.string.isRequired,
  sectionId: PropTypes.string.isRequired
};