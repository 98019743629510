import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import shortid from 'shortid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt, faCheck, faTimes
} from '@fortawesome/pro-regular-svg-icons';
import { TableCell } from '@material-ui/core';
import { ToolsService } from 'services';
import { translate } from 'utils';
import {
  Desktop, GenericTable, Mobile,
  PageTitle, SkeletonNoResult
} from 'components';
import { useModal, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { formatDate } from 'utils/helpers';
import { ToolsListNav } from './ToolsListNav';

const listHeaders = [
  {
    name: 'type',
    label: 'common.toolType',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.toolTypeLabel || '-'}
      </TableCell>
    )
  },
  {
    name: 'model',
    label: 'common.model',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.model || '-'}
      </TableCell>
    )
  }, {
    name: 'serialNumber',
    label: 'common.serialNumber',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.serialNumber}
      </TableCell>
    )
  }, {
    name: 'lastCheckupDate',
    label: 'common.lastCheckupDate',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '0.5rem' }} />
        {(row.lastCheckupDate && `${formatDate(row.lastCheckupDate)}`) || '-'}
      </TableCell>
    )
  }, {
    name: 'inspectorName',
    label: 'common.inspectorName',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.inspectorName || '-'}
      </TableCell>
    )
  }, {
    name: 'validated',
    label: 'common.validated',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <FontAwesomeIcon
          color={row.validated ? 'var(--primary-color)' : 'var(--danger-color)'}
          icon={row.validated ? faCheck : faTimes}
        />
      </TableCell>
    )
  }
];

const ToolsList = observer(() => {
  const { userStore, referenceDataStore } = useStores();
  const { userConnected } = userStore;
  const { toolTypes } = referenceDataStore;
  const showModal = useModal();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [toolList, setToolList] = useState([]);

  const getToolsList = useCallback(() => {
    if (userConnected) {
      setIsLoading(true);

      ToolsService.getToolList()
        .then((response) => setToolList(response))
        .catch((error) => enqueueSnackbar(translate(error.message), { variant: 'error' }))
        .finally(() => setIsLoading(false));
    }
  }, [userConnected, enqueueSnackbar]);

  const reloadList = useCallback(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    getToolsList();
  }, [getToolsList]);

  useEffect(() => {
    getToolsList();
  }, [getToolsList]);

  const createTool = useCallback((form) => {
    if (form.id) {
      return ToolsService.updateTool(form.id, form).then(() => {
        enqueueSnackbar(translate('confirms.toolUpdate'), { variant: 'success' });
        reloadList();
      }).catch((error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      });
    }

    return ToolsService.createTool(form).then(() => {
      enqueueSnackbar(translate('confirms.toolCreate'), { variant: 'success' });
      reloadList();
    }).catch((error) => enqueueSnackbar(translate(error.message), { variant: 'error' }));
  }, [enqueueSnackbar, reloadList]);

  const handleCreateTool = useCallback((row) => showModal({
    type: 'CREATE_TOOL',
    onConfirm: createTool,
    defaultValues: row.model ? row : {},
    reloadList,
    toolTypes
  }), [showModal, createTool, toolTypes, reloadList]);

  const ToolsListTable = ({ listHeadersTable, loading }) => (
    <GenericTable
      dataCy="toolList"
      headers={listHeadersTable}
      id="toolList"
      isLoading={loading}
      rows={toolList}
      total={toolList.length}
      onRowCLick={handleCreateTool}
    />
  );

  return (
    <>
      <ToolsListNav getToolsList={getToolsList} />

      <PageTitle title={translate('pageAdminTools.title')} />

      <div data-tour="step-admin-toolList">
        {toolList.length === 0 && !isLoading ? (
          <SkeletonNoResult error="errors.noToolsAvailable" />
        ) : (
          <>
            <Mobile>
              <ToolsListTable listHeadersTable={[...listHeaders].splice(0, 3)} loading={isLoading} />
            </Mobile>
            <Desktop>
              <ToolsListTable listHeadersTable={listHeaders} loading={isLoading} />
            </Desktop>
          </>
        )}
      </div>
    </>
  );
});

ToolsList.propTypes = {
};

export default ToolsList;