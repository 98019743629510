import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET

// POST
const logStackTrace = ({ stackTrace, body }) => RequestHelper.POST(API_ROUTES.LOG_ERRORS, { stackTrace, body });

export const LogService = {
  logStackTrace
};