import React from 'react';
import { PropTypes } from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faQuestionCircle } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';

export const InfoTooltip = ({ label }) => (
  <Tooltip aria-label={label} title={label}>
    <IconButton aria-label={label}>
      <FontAwesomeIcon color="var(--tecnea-blue)" icon={faQuestionCircle} size="xs" />
    </IconButton>
  </Tooltip>
);

InfoTooltip.propTypes = {
  label: PropTypes.string
};

InfoTooltip.defaultProps = {
  label: ''
};

export const VisibilityTooltip = observer(({ isPublic, color = 'inherit' }) => {
  const { userStore } = useStores();
  const { isConnected } = userStore;

  const icon = isPublic ? faEye : faEyeSlash;
  const label = isPublic ? translate('common.isPublic') : translate('common.isPrivate');

  return (
    isConnected && (
      <Tooltip aria-label={label} title={label}>
        <IconButton aria-label={label} style={{ padding: 0 }}>
          <FontAwesomeIcon color={color} icon={icon} />
        </IconButton>
      </Tooltip>
    )
  );
});