import { openDB } from 'idb'; // Docs : https://www.npmjs.com/package/idb

const DB_NAME = 'Datac2e';
// TODO RECURRENT: Increment version when adding new questions to the form
// or when the model of the form changes
const DB_VERSION = 4;
const STORES = [{
  name: 'inspections',
  options: {}
}, {
  name: 'referenceData',
  options: {}
}, {
  name: 'certificall',
  options: {}
}];

let DB;

const openDatabase = async () => {
  DB = await openDB(DB_NAME, DB_VERSION, {
    upgrade(upgradeDB) {
      if (upgradeDB.objectStoreNames && upgradeDB.objectStoreNames.contains('inspections')) {
        upgradeDB.deleteObjectStore('inspections');
      }

      STORES.forEach((store) => {
        if (!upgradeDB.objectStoreNames || !upgradeDB.objectStoreNames.contains(store.name)) {
          upgradeDB.createObjectStore(store.name, store.options);
        }
      });
    }
  })
    .catch((error) => { throw error; });

  return DB;
};

const getData = ({ store, key }) => (
  DB.get(store, key)
    .then((data) => data)
    .catch((error) => { throw error; })
);

const getAllData = ({ store, range }) => (
  DB.getAll(store, range)
    .then((data) => data)
    .catch((error) => { throw error; })
);

const saveData = ({ store, key, data }) => (
  DB.add(store, data, key)
    .catch((error) => { throw error; })
);

const updateData = ({ store, key, data }) => (
  DB.put(store, data, key)
    .catch((error) => { throw error; })
);

const getAllFromStore = (store) => (
  DB.getAll(store)
    .catch((error) => { throw error; })
);

const deleteData = ({ store, key }) => (
  DB.delete(store, key)
    .catch((error) => { throw error; })
);

const deleteAllData = ({ store, range }) => (
  DB.delete(store, range)
    .catch((error) => { throw error; })
);

const clearStore = (store) => (
  DB.clear(store)
    .catch((error) => { throw error; })
);

export const IndexedDBHelper = {
  openDatabase,
  getData,
  getAllData,
  saveData,
  updateData,
  getAllFromStore,
  deleteData,
  deleteAllData,
  clearStore
};