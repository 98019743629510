import { action, decorate, observable } from 'mobx';
import { FilterHelper } from 'utils/helpers';

class UserStore {
  constructor() {
    this.isConnecting = true;
    this.isDisconnecting = false;
    this.isConnected = false;
    this.isOffline = false;
    this.keycloak = null;
    this.userConnected = null;
  }

  setIsConnecting(isConnecting = true) {
    this.isConnecting = isConnecting;
  }

  connectUser(keycloak) {
    this.keycloak = keycloak;

    if (keycloak && keycloak.tokenParsed) {
      this.isConnected = true;
      this.userConnected = keycloak.tokenParsed;
    }
  }

  disconnectUser() {
    this.isDisconnecting = true;
    this.isConnected = false;
    this.userConnected = null;
    this.keycloak.clearToken();
    FilterHelper.deleteStoredFilters();
    return this.keycloak.logout();
  }

  getToken() {
    return this.keycloak ? this.keycloak.token : null;
  }

  isTokenExpired() {
    return this.keycloak ? this.keycloak.isTokenExpired() : null;
  }

  refreshToken() {
    return this.keycloak.updateToken();
  }

  keycloakLogin() {
    return this.keycloak.login();
  }

  setOffline(offline = true) {
    return new Promise((resolve) => {
      this.isOffline = offline;
      resolve();
    });
  }
}

decorate(UserStore, {
  isConnecting: observable,
  isDisconnecting: observable,
  isConnected: observable,
  isOffline: observable,
  userConnected: observable,
  setIsConnecting: action,
  connectUser: action,
  disconnectUser: action,
  getToken: action,
  isTokenExpired: action,
  refreshToken: action,
  keycloakLogin: action,
  setOffline: action
});

export const userStore = new UserStore();