import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FormControl, FormHelperText, FormLabel } from '@material-ui/core';

const StyledFormElement = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0;

  .MuiFormControl-root {
    display: flex;
    flex: 1;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    &.MuiTextField-root {
      flex: 1;
    }

    legend {
      position: absolute;
      top: -5px;
      left: 10px;
      padding: 0 0.5rem;
      margin-bottom: 0.5rem;
      color: var(--grey);
      font-size: 1.2rem;
      background: white;
      z-index: 10;
    }
  }
`;

const StyledFormLabel = styled(FormLabel)`
  max-width: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const FormElement = ({
  label, icon, required, helperText, error, children
}) => (
  <StyledFormElement>
    <FormControl component="fieldset" error={error} required={required}>
      {label && (
        <StyledFormLabel component="legend">
          {label}
          {icon}
        </StyledFormLabel>
      )}
      {children}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  </StyledFormElement>
);

FormElement.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  icon: PropTypes.element,
  label: PropTypes.string,
  required: PropTypes.bool
};

FormElement.defaultProps = {
  error: null,
  helperText: '',
  icon: null,
  label: '',
  required: false
};