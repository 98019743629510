import React from 'react';
import styled from 'styled-components';

import Skeleton from '@material-ui/lab/Skeleton';
import { Table } from '@material-ui/core';

const SkeletonContainer = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2rem;
  border-top: 1px solid var(--grey-lighter);
  border-bottom: 1px solid var(--grey-lighter);

  td {
    width: 20%;
    height: 20px;
  }

  div {
    margin-right: 5%;
  }
`;

export const SkeletonTableChild = () => (
  <>
    <SkeletonContainer className="MuiTableRow-root">
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
    </SkeletonContainer>
    <SkeletonContainer className="MuiTableRow-root">
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
      <td><Skeleton variant="rect" /></td>
    </SkeletonContainer>
  </>
);

export const SkeletonTable = () => (
  <Table data-testid="skeleton-table">
    <tbody>
      <SkeletonTableChild />
    </tbody>
  </Table>
);