import React, { useCallback, useEffect, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

import { Wrapper } from 'components';
import {
  Card, CardContent, CardHeader,
  Grid, Switch, Typography
} from '@material-ui/core';

import { translate } from 'utils';
import { CookiesHelper } from 'utils/helpers/CookiesHelper';

const TitlePage = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  margin: 2rem 0;
  color: var(--tecnea-blue);
  font-size: 2.4rem;
  text-transform: uppercase;
  text-align: center;
`;

export const PageCookies = () => {
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    setAccepted(CookiesHelper.getGAConsent());
  }, []);

  const toggleAccepted = useCallback(() => {
    const currentAccepted = accepted;
    CookiesHelper.setGACookie(!accepted);
    setAccepted((prev) => !prev);
    if (currentAccepted) {
      window.location.reload();
    }
  }, [accepted]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Wrapper>
      <TitlePage>{translate('commons.rgpd.cookies.pageTitle')}</TitlePage>
      <Typography
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('commons.rgpd.cookies.pageIntro')) }}
        style={{ fontSize: '1.7rem' }}
      />
      <Card style={{ marginTop: 30 }}>
        <CardHeader
          action={(
            <Grid alignItems="center" container justifyContent="space-between">
              <Grid item style={{ marginRight: 10 }}>
                <Grid alignItems="center" container direction="row">
                  <Grid item>
                    <Typography variant="subtitle2">{translate('button.refuse')}</Typography>
                  </Grid>
                  <Grid item>
                    <Switch
                      checked={accepted}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      name="accepted"
                      onChange={toggleAccepted}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="subtitle2">{translate('button.accept')}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
          title="Google analytics"
        />
        <CardContent>
          <Typography>{translate('commons.rgpd.googleAnalytics.pageDescription')}</Typography>
        </CardContent>
      </Card>

    </Wrapper>
  );
};