/* eslint-disable react/jsx-no-bind */
import { faShareSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid, Typography
} from '@material-ui/core';
import { InputField } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import sanitizeHtml from 'sanitize-html';
import { translate } from 'utils';
import { ModalHeader } from './_ModalHeader';

export const SubmitInspectionModal = ({
  onConfirm, onClose
}) => {
  const [displayContinue, setDisplayContinue] = useState(true);
  const [statusComment, setStatusComment] = useState('');

  const handleChangeComment = useCallback((_, value) => setStatusComment(value), []);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    onConfirm(statusComment);
    return onClose();
  }, [onConfirm, onClose, statusComment]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader className="warningModal" onClose={onClose}>
        <FontAwesomeIcon className="mr1" icon={faShareSquare} />
        {translate('pageInspectionForm.submitInspection')}
      </ModalHeader>

      <DialogContent>
        <Typography
          dangerouslySetInnerHTML={{ __html: sanitizeHtml(translate('warnings.submitInspectionIncomplete')) }}
          gutterBottom
        />

        {displayContinue && (
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button color="primary" variant="contained" onClick={() => setDisplayContinue(false)}>
                {translate('button.sure')}
              </Button>
            </Grid>
          </Grid>
        )}

        {!displayContinue && (
          <div style={{ marginTop: '2rem' }}>
            <Typography gutterBottom>
              <strong>
                {translate('pageInspectionForm.fillComment')}
              </strong>
            </Typography>

            <InputField
              label={translate('forms.inputs.comment')}
              minRows={3}
              multiline
              name="statusComment"
              required
              value={statusComment}
              onChange={handleChangeComment}
            />
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!statusComment}
          key={`isDisabled_${!!statusComment}`}
          startIcon={<FontAwesomeIcon icon={faShareSquare} />}
          type="submit"
        >
          {translate('button.submit')}
        </Button>
      </DialogActions>
    </form>
  );
};

SubmitInspectionModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};