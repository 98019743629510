import React from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-use-form-state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/pro-regular-svg-icons';
import {
  Button, DialogActions, DialogContent, Typography
} from '@material-ui/core';
import sanitizeHtml from 'sanitize-html';

import { STATUS } from 'utils/constants';
import { useStores } from 'hooks';
import { translate } from 'utils';
import { ModalHeader } from './_ModalHeader';
import { SelectField } from '../Form';

export const ReassignInspectorModal = ({
  onClose, onConfirm, currentInspector, inspectionStatus
}) => {
  const { referenceDataStore } = useStores();
  const { inspectorList } = referenceDataStore;
  const [formState] = useFormState({
    inspector: inspectorList.find((ope) => ope.label === currentInspector)
  });

  const isDisabled = !formState.values.inspector || formState.values.inspector.label === currentInspector;

  const handleValidateModal = (e) => {
    if (isDisabled) return;

    e.preventDefault();
    onConfirm(formState.values.inspector);
    onClose();
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding} />
        {currentInspector
          ? translate('modalReassignInspector.titleReassign')
          : translate('modalReassignInspector.titleAssign')}
      </ModalHeader>

      <DialogContent>
        {currentInspector && (
          <Typography gutterBottom>
            {`${translate('modalReassignInspector.inspectorCurrentlyAssigned')} : `}
            <strong>{currentInspector}</strong>
          </Typography>
        )}

        {(inspectionStatus === STATUS.READY.key
        || inspectionStatus === STATUS.IN_PROGRESS.key) && (
          <Typography
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(
                translate('modalReassignInspector.warningMessage',
                  { status: translate(STATUS[inspectionStatus].label) })
              )
            }}
            style={{ color: 'var(--warning-color)', margin: '1rem 0 2rem 0' }}
          />
        )}

        <SelectField
          id="selectInspector"
          isClearable
          isSearchable
          label="modalReassignInspector.selectInspector"
          name="selectInspector"
          options={inspectorList.filter((insp) => insp.value !== formState.values.inspector?.value)}
          required
          value={formState.values.inspector || ''}
          onChange={(_, value) => formState.setField('inspector', value)}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={isDisabled}
          type="submit"
        >
          {currentInspector
            ? translate('button.reassign')
            : translate('button.assign')}
        </Button>
      </DialogActions>
    </form>
  );
};

ReassignInspectorModal.propTypes = {
  currentInspector: PropTypes.string,
  inspectionStatus: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

ReassignInspectorModal.defaultProps = {
  currentInspector: ''
};