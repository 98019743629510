/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faHistory } from '@fortawesome/pro-regular-svg-icons';
import PropTypes from 'prop-types';
import {
  Button, DialogActions, DialogContent, IconButton, TableCell, Tooltip, Typography
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { DocumentHelper, translate } from 'utils';
import { GenericTable, Status, TextError } from 'components';
import { InspectionService } from 'services';
import shortid from 'shortid';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { ModalHeader } from './_ModalHeader';

const listHeadersTable = (handleDownloadReport) => [
  {
    name: 'creationDate',
    label: 'common.lastModificationDate',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {(row.creationDate && new Date(row.creationDate).toLocaleString()) || '-'}
      </TableCell>
    )
  }, {
    name: 'status',
    label: 'common.status',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.status ? (
          <Status status={row.status} />
        ) : '-'}
      </TableCell>
    )
  }, {
    name: 'conformity',
    label: 'common.conformity',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.conformity ? translate(`results.${row.conformity}`) : '-'}
      </TableCell>
    )
  }, {
    name: 'statusComment',
    label: 'common.comment',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.statusComment || '-'}
      </TableCell>
    )
  }, {
    name: 'userLogin',
    label: 'common.modificationUserName',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.userLogin || '-'}
      </TableCell>
    )
  }, {
    name: 'actions',
    label: 'common.actions',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <Tooltip aria-label={translate('button.downloadPDF')} title={translate('button.downloadPDF')}>
          <IconButton
            color="primary"
            onClick={() => handleDownloadReport(row)}
          >
            <FontAwesomeIcon color="var(--tecnea-blue)" icon={faFilePdf} />
          </IconButton>
        </Tooltip>
      </TableCell>
    )
  }
];

export const InspectionHistoricModal = observer(({
  onClose, inspectionId, inspectionIdentifier
}) => {
  const { inspectionStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();
  const [inspectionHistoric, setInspectionHistoric] = useState([]);
  const [isRowGeneratingReport, setIsRowGeneratingReport] = useState(false);

  useEffect(() => {
    InspectionService.getInpectionHistoric(inspectionId)
      .then((resp) => setInspectionHistoric(resp))
      .catch((error) => enqueueSnackbar(translate(error.message), { variant: 'error' }));
  }, [inspectionId, enqueueSnackbar]);

  const handleDownloadReport = useCallback((row) => {
    setIsRowGeneratingReport(true);

    inspectionStore.generateReportPDF({ inspectionId: row.id, needLoader: false })
      .then(({ fileReader, blob }) => {
        fileReader.readAsArrayBuffer(blob);

        const PDFname = translate('file.inspectionReportFileName', { reportIdentifier: row.reportIdentifier });

        // eslint-disable-next-line no-param-reassign
        fileReader.onload = () => {
          DocumentHelper.createDownloadableDocument({
            result: fileReader.result,
            linkTitle: PDFname
          });
        };
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
      .finally(() => setIsRowGeneratingReport(false));
  }, [inspectionStore, enqueueSnackbar]);

  return (
    <>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faHistory} />
        {translate('pageInspectionDetail.historic', { inspectionIdentifier })}
      </ModalHeader>

      <DialogContent>
        <Typography gutterBottom>
          {translate('pageInspectionDetail.historicExplanation')}
        </Typography>

        {inspectionHistoric.length > 0 ? (
          <GenericTable
            headers={listHeadersTable(handleDownloadReport)}
            isLoading={isRowGeneratingReport}
            rows={inspectionHistoric}
            total={inspectionHistoric.length}
          />
        ) : <TextError>{translate('errors.noInspectionHistoric')}</TextError>}
      </DialogContent>

      <DialogActions>
        <Button
          color="primary"
          variant="text"
          onClick={onClose}
        >
          {translate('button.close')}
        </Button>
      </DialogActions>
    </>
  );
});

InspectionHistoricModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  inspectionId: PropTypes.string.isRequired,
  inspectionIdentifier: PropTypes.string.isRequired
};