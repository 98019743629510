import React, { lazy } from 'react';
import {
  InspectionList
} from 'components';
import { UserHelper } from 'utils';
import { CUSTOMER_ACCESS_ROLES } from 'utils/constants';

const CustomerPage = lazy(() => import('components/Campaign/CampaignList/CustomerCampaignList'));

const PageHome = () => (UserHelper.hasAccessRight([...CUSTOMER_ACCESS_ROLES]) ? (
  <>
    <CustomerPage />
  </>
) : (
  <>
    <InspectionList />
  </>
));

export default PageHome;