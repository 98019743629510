import React, { useCallback } from 'react';
import styled from 'styled-components';
import { faFile as faFileEmpty, faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useModal } from 'hooks';
import { Button } from '..';

const DocumentContainer = styled.div`
  min-width: 100px;
`;

export const Document = ({ name, url }) => {
  const showModal = useModal();

  const handleOpenPDFModal = useCallback(() => showModal({
    type: 'DISPLAY_PDF',
    name,
    url
  }), [showModal, name, url]);

  return (
    <DocumentContainer>
      {url ? (
        <Button type="secondary" onClick={handleOpenPDFModal}>
          <Grid alignItems="center" container direction="column">
            <FontAwesomeIcon icon={faFileUpload} size="3x" style={{ marginBottom: '1rem' }} />
            {name}
          </Grid>
        </Button>
      ) : (
        <Paper>
          <Grid alignItems="center" container direction="column">
            <FontAwesomeIcon icon={faFileEmpty} size="3x" style={{ marginBottom: '1rem' }} />
            {name}
          </Grid>
        </Paper>
      )}
    </DocumentContainer>
  );
};

Document.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string
};

Document.defaultProps = {
  name: '',
  url: ''
};