import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { InfoTooltip } from 'components';
import { observer } from 'mobx-react-lite';

export const TooltipWrapper = observer(({ tooltip, children }) => (
  <Grid alignItems="center" container>
    <Grid item xs={10}>
      {children}
    </Grid>
    <Grid item xs={2}>
      <InfoTooltip label={tooltip} />
    </Grid>
  </Grid>
));

const InputField = ({
  name, value, label, onChange, tooltip, fullWidth, ...props
}) => {
  const changeHandler = useCallback((event) => {
    onChange(event.target.name, event.target.value);
  }, [onChange]);

  const input = (
    <TextField
      autoComplete="off"
      fullWidth={fullWidth}
      InputLabelProps={{
        shrink: true
      }}
      label={label}
      margin="dense"
      name={name}
      value={value === null ? '' : value}
      variant="outlined"
      onChange={changeHandler}
      {...props}
    />
  );

  return tooltip ? <TooltipWrapper tooltip={tooltip}>{input}</TooltipWrapper> : input;
};

InputField.propTypes = {
  fullWidth: PropTypes.bool
};

InputField.defaultProps = {
  fullWidth: true
};

export default InputField;