// import { API_ROUTES } from 'utils/constants';
// import { RequestHelper } from 'utils';

// GET
const getTranslations = () => new Promise((resolve) => {
  const translationsFr = {
    errors: {
      missing: {
        inspection: {
          identifier: "Identifiant d'inspection manquant",
          siren: 'Siren manquant.',
          customer: {
            name: 'Nom bénéficiaire ou raison sociale manquant(e).',
            firstName: 'Prénom bénéficiaire manquant.'
          }
        }
      },
      invalid: {
        inspection: {
          siren: 'Siren invalide.'
        }
      },
      offline: `Vous êtes hors ligne, certaines fonctionnalités ne seront pas accessible.
      Vous devez être en ligne et connecté pour enregistrer ou publier votre inspection.`
    },
    pageHome: {
      welcome: 'Texte accueil.'
    }
  };

  resolve(translationsFr);
});

// TODO uncomment this one and delete the one above once the translations are fonctionnal on the backend
// const getTranslations = () => RequestHelper.GET(API_ROUTES.TRANSLATIONS());

export const TranslationService = {
  getTranslations
};