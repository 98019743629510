/* eslint-disable react/no-array-index-key */
import { faFileImport, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Divider, Grid, ListItemText, Tooltip, Typography
} from '@material-ui/core';
import debounce from 'debounce-promise';
import { useImportingReducer } from 'hooks';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import AsyncSelect from 'react-select/async';
import sanitizeHtml from 'sanitize-html';
import { CampaignService } from 'services';
import { DocumentHelper, translate } from 'utils';

import { InputField, InputFile, StyledListItem } from '../Form';
import { TextError } from '../Text';
import { ModalHeader } from './_ModalHeader';

export const ImportCampaignModal = ({ onConfirm, onClose }) => {
  const [state, dispatch] = useImportingReducer();
  const { enqueueSnackbar } = useSnackbar();

  const [campaignImported, setCampaignImported] = useState(null);
  const [campaignName, setCampaignName] = useState('');
  const [isImportButtonDisabled, setIsImportButtonDisabled] = useState(true);
  const [institutionIdentifier, setInstitutionIdentifier] = useState('');
  const [institutionName, setInstitutionName] = useState('');
  const [selectedInstitution, setSelectedInstitution] = useState(null);

  const handleChangeCampaignName = useCallback((_, inputVal) => {
    setCampaignName(inputVal);
  }, []);

  const handleImportCampaign = useCallback((doc) => {
    if (doc) {
      setCampaignImported(doc);
      if (doc.name) {
        setCampaignName(`${institutionName} ${institutionIdentifier}`);
      }

      dispatch({ type: 'reset' });
    }
  }, [dispatch, institutionName, institutionIdentifier]);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    if (!campaignImported || !campaignName) return null;

    const sendCampaign = {
      fileName: campaignImported.name,
      file: DocumentHelper.getDocumentWithoutBase64(campaignImported),
      customerHashId: null,
      name: campaignName,
      institutionHashId: selectedInstitution?.value
    };

    dispatch({ type: 'isImporting' });

    CampaignService.importCampaign(sendCampaign);
    onConfirm();
    return onClose();
  }, [campaignName, campaignImported, selectedInstitution, onConfirm, onClose, dispatch]);

  const handleSelectInstitution = useCallback((value) => {
    if (!value) setIsImportButtonDisabled(true);
    setInstitutionIdentifier(value.label?.split(/[()]+/)[1].trim());
    setInstitutionName(value.label?.split(/[()]+/)[0].trim());
    setSelectedInstitution(value);
    setIsImportButtonDisabled(false);
  }, []);

  const getAsyncOptions = useCallback((inputValue) => new Promise((resolve) => CampaignService.getInstitutionOptions({ freeSearch: inputValue, page: 0 })
    .then((response) => resolve(response)))
    .catch((err) => enqueueSnackbar(err.message, { variant: 'error' })), [enqueueSnackbar]);

  const debouncedLoadOptions = debounce(getAsyncOptions, 500);

  const CustomInstitutionOption = ({ data, innerProps }) => (
    <StyledListItem {...innerProps} style={{ cursor: 'pointer' }}>
      <ListItemText primary={data.label} />
    </StyledListItem>
  );

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('button.importCampaign')}</ModalHeader>

      <DialogContent>
        {state.error && state.error.message ? (
          <Typography component="div" gutterBottom>
            <TextError style={{ margin: 0, textAlign: 'left' }}>
              {state.error.message}
            </TextError>
          </Typography>
        ) : state.error && Object.keys(state.error).length > 0 && (
          <>
            <div>
              {Object.keys(state.error).map((err, index) => (
                <Typography component="div" gutterBottom key={index}>
                  <TextError style={{ margin: 0, textAlign: 'left' }}>
                    {`${err} : `}
                  </TextError>
                  {state.error[err].map((e, i) => (
                    <Typography component="p" key={i}>{e}</Typography>
                  ))}
                </Typography>
              ))}
            </div>
            <Divider style={{ margin: '2rem 0' }} />
          </>
        )}

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Tooltip placement="top" title={translate('institution.tooltip.institutionSearchOptionToolTip')}>
              <AsyncSelect
                cacheOptions
                closeMenuOnSelect
                components={{ Option: CustomInstitutionOption }}
                defaultOptions
                loadingMessage={() => translate('common.loading')}
                loadOptions={(inputValue) => debouncedLoadOptions(inputValue)}
                menuPortalTarget={document.body}
                name="institutions"
                noOptionsMessage={() => translate('common.noOptions')}
                placeholder={translate('institution.selectInstitution')}
                required
                styles={{
                  menu: (baseMenu) => ({ ...baseMenu, zIndex: 9999 }),
                  menuPortal: (baseMenuPortal) => ({ ...baseMenuPortal, zIndex: 9999 }),
                  container: (baseContainer) => ({ ...baseContainer, width: 450 }),
                  input: (baseInput) => ({ ...baseInput, '& input': { width: '350px !important' } })
                }}
                value={selectedInstitution}
                onChange={handleSelectInstitution}
              />
            </Tooltip>
          </Grid>
          <Grid item>
            {campaignImported === null || campaignImported?.name.toLowerCase().includes(institutionName.toLowerCase()) ? (<></>) : (
              <Typography
                dangerouslySetInnerHTML={{
                  __html:
                    sanitizeHtml(translate('modalImportCampaign.warning.nameMatching'))
                }}
                style={{ color: 'orangered' }}
              />
            )}
          </Grid>
          <Grid item>
            {isImportButtonDisabled ? (
              <Tooltip placement="top" title={translate('institution.tooltip.selectInstitutionBefore')}>
                <span>
                  <InputFile
                    disabled
                    document={campaignImported}
                    fileAccepted=".xlsx"
                    handleAddDocument={handleImportCampaign}
                    handleDeleteDocument={null}
                    id="importCampaign"
                    label="button.importCampaign"
                    maxSize={100}
                    required
                  />
                </span>
              </Tooltip>
            ) : (
              <InputFile
                document={campaignImported}
                fileAccepted=".xlsx"
                handleAddDocument={handleImportCampaign}
                handleDeleteDocument={null}
                id="importCampaign"
                label="button.importCampaign"
                maxSize={100}
                required
              />
            )}
          </Grid>
          <Grid item>
            <InputField
              label={translate('common.campaignName')}
              name="campaignName"
              required
              value={campaignName}
              onChange={handleChangeCampaignName}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={state.isImporting} variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!campaignImported || state.isImporting}
          startIcon={<FontAwesomeIcon icon={state.isImporting ? faSpinner : faFileImport} spin={state.isImporting} />}
          type="submit"
        >
          {translate('button.import')}
        </Button>
      </DialogActions>
    </form>
  );
};

ImportCampaignModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};