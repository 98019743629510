import {
  action, decorate, observable, runInAction
} from 'mobx';
import { TranslationService } from 'services';

class TranslationStore {
  constructor() {
    this.translations = {};
  }

  getTranslations() {
    return TranslationService.getTranslations()
      .then((resp) => runInAction('LoadTranslationsFromBackend', () => {
        this.translations = resp;
      }));
  }
}

decorate(TranslationStore, {
  translations: observable,
  getTranslations: action
});

export const translationStore = new TranslationStore();