import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import * as Locales from 'date-fns/locale';
import { i18nStore } from 'stores';
import { BrowserDetectionHelper } from './BrowserDetectionHelper';
import { DocumentHelper } from './DocumentHelper';
import { FilterHelper } from './FilterHelper';
import { FormHelper } from './FormHelper';
import { IndexedDBHelper } from './IndexedDBHelper';
import { NavigatorHelper } from './NavigatorHelper';
import { RequestHelper } from './RequestHelper';
import { StorageHelper } from './StorageHelper';
import { UserHelper } from './UserHelper';

const useDebounce = (value, delay = 2000) => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const getDateFnsLocale = (locale) => {
  const splitLocale = locale.replaceAll(/([^(a-zA-Z)])+/ig, '#').split('#');
  return Locales[splitLocale[0] + splitLocale[1]] ?? Locales[splitLocale[0]] ?? Locales.fr;
};

const formatDate = (date, dateFormat = 'dd/MM/yyyy') => {
  if (!date) return '';
  return format(new Date(date), dateFormat);
};

const formatNiceDate = (date, showFull = false) => {
  if (!date) return '';

  const formattedDate = new Date(date);

  if (!(formattedDate instanceof Date)) return '';

  const options = { locale: getDateFnsLocale(i18nStore.currentLanguage) };
  return showFull
    ? format(formattedDate, 'eeee PP - HH:mm', options)
    : format(formattedDate, 'dd/MM/yyyy - HH:mm', options);
};

export {
  BrowserDetectionHelper,
  DocumentHelper,
  FilterHelper,
  FormHelper,
  IndexedDBHelper,
  NavigatorHelper,
  RequestHelper,
  StorageHelper,
  UserHelper,
  formatDate,
  formatNiceDate,
  useDebounce
};