import React from 'react';
import styled from 'styled-components';

const StyledSubModal = styled.section`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--white);
  animation: slideUp 0.3s ease-in-out;

  @keyframes slideUp {
    0% { top: 100%; }
    100% { top: 0; }
  }
`;

export const SubModal = ({
  children
}) => (
  <StyledSubModal>
    {children}
  </StyledSubModal>
);