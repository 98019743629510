import { SelectLanguage } from 'components';
import { frenchLocale } from 'locales/i18n';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeEurope } from '@fortawesome/pro-regular-svg-icons';
import { DialogContent, Grid, Typography } from '@material-ui/core';
import { i18nStore } from 'stores';
import { translate } from 'utils';
import { ModalHeader } from './_ModalHeader';
import { CustomActionButtons } from './_CustomActionButtons';

export const ChangeLanguageModal = ({
  onConfirm, onClose, defaultValues
}) => {
  const [language, setLanguage] = useState(null);
  const { languageList } = i18nStore;

  useEffect(() => {
    if (languageList.length > 0 && defaultValues) {
      setLanguage(
        languageList.find((lg) => lg.value.toUpperCase().indexOf(defaultValues.toUpperCase()) !== -1)
        || languageList.find((lg) => lg.value === frenchLocale)
      );
    }
  }, [languageList, defaultValues]);

  const handleSelectLanguage = useCallback((value) => setLanguage(value), []);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    onConfirm(language);
    onClose();
  }, [onClose, onConfirm, language]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faGlobeEurope} />
        <Typography component="span">
          {translate('modalChangeLanguage.title')}
        </Typography>
      </ModalHeader>

      <DialogContent>
        <Grid container direction="column">
          <SelectLanguage
            languages={languageList}
            required
            value={language}
            onChange={handleSelectLanguage}
          />
        </Grid>
      </DialogContent>

      <CustomActionButtons
        isValidated={defaultValues.validated}
        onClose={onClose}
      />
    </form>
  );
};

ChangeLanguageModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.string
};

ChangeLanguageModal.defaultProps = {
  defaultValues: {}
};