import React from 'react';
import PropTypes from 'prop-types';

import { STATUS } from 'utils/constants';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledStatus = styled.span`
  color: ${(props) => props.color};
`;

const Status = ({ status }) => {
  const currentStatus = STATUS[status.value];
  return currentStatus ? (
    <StyledStatus color={currentStatus.color}>
      <FontAwesomeIcon className="mr1" icon={currentStatus.icon} size="lg" />
      {status.label}
    </StyledStatus>
  ) : <>{status.label}</>;
};

Status.propTypes = {
  status: PropTypes.shape({}).isRequired
};

export default Status;