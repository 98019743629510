import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import 'tui-image-editor/dist/tui-image-editor.css';
import styled from 'styled-components';
import { isMobileOnly } from 'react-device-detect';
import ImageEditor from '@toast-ui/react-image-editor'; // Docs: http://nhn.github.io/tui.image-editor/latest/tutorial-example01-includeUi
// Bug sliders on mobile : https://github.com/nhn/tui.image-editor/issues/365
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons';
import {
  Button, DialogActions, DialogContent, Typography
} from '@material-ui/core';
import { translate } from 'utils';
import { ModalHeader } from './_ModalHeader';

const imageEditorTheme = {
  'common.bi.image': null
};

const imageEditorLocale = {
  Undo: 'Annuler',
  Redo: 'Refaire',
  Reset: 'Reinitaliser',
  Delete: 'Supprimer',
  DeleteAll: 'Supprimer les dessins',
  Crop: 'Cropper',
  Custom: 'Libre',
  Square: 'Carré',
  Apply: 'Appliquer',
  Cancel: 'Annuler',
  Draw: 'Dessiner',
  Range: 'Rayon',
  Free: 'Crayon',
  Straight: 'Ligne',
  Color: 'Couleur',
  Filter: 'Filtres',
  Rotate: 'Rotation',
  'Remove White': 'Retirer le blanc',
  Brightness: 'Luminosité',
  Noise: 'Bruit'
};

const StyledEditorContainer = styled.section`
  .tui-image-editor-header {
    display: none;
  }

  .tui-image-editor-container {
    margin-top: 2rem;
    background-color: #1e1e1e;

    .tui-image-editor-main-container {
      bottom: 84px;
    }

    .tui-image-editor-main {
      top: 0;

      .tui-image-editor-partition > div {
        height: 78px !important;
      }
    }

    .tui-image-editor-menu {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
    }

    /* Hide unwanted filters */
    .tui-image-editor-menu-filter .tui-image-editor-submenu-item {
      li:first-child,
      li:nth-child(3) > div:last-of-type,
      li:nth-child(5),
      li:nth-child(6),
      li:nth-child(7) {
        display: none !important;
      }
    }

    /* Hide unwanted range selector */
    .tui-image-editor-menu-rotate .tui-image-editor-range-wrap,
    .tui-image-editor-menu-draw .tui-image-editor-range-wrap,
    .tie-btn-delete {
      display: none !important;
    }

    .tui-image-editor-controls {
      display: block;
      height: 84px;
    }

    .tui-image-editor-submenu {
      height: auto;
      padding: 0 1rem;

      /* Hide unwanted crop ratios */
      .tie-crop-preset-button > div {
        &:first-child,
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(5),
        &:nth-child(6) {
          display: none;
        }
      }

      .tui-image-editor-button {
        margin-bottom: 5px !important;
      }

      label {
        cursor: pointer;
      }
    }

    .tui-image-editor-icpartition {
      background-color: #dedede;
    }
  }

  .tui-image-editor-container.bottom .tui-image-editor-submenu > div {
    padding-bottom: 0px !important;
  }

  .tui-colorpicker-clearfix li {
    width: 20px;
    height: 20px;
    float: left;

    input {
      width: 16px;
      height: 16px;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    .tui-image-editor-container {
      margin-top: 0;
    }
  }
`;

const TextEllipsis = styled(Typography)`
  display: inline-block;
  max-width: 90%;
  vertical-align: middle;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ImageEditorModal = ({
  onClose, onConfirm, image
}) => {
  const imageEditorRef = useRef();

  const handleValidateModal = useCallback(() => {
    const imageEditorInstance = imageEditorRef.current.getInstance();
    const newImage = imageEditorInstance.toDataURL();

    onConfirm({
      ...image,
      base64Content: newImage
    });

    return onClose();
  }, [image, onConfirm, onClose]);

  if (!image) return null;

  return (
    <>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faFileImage} />
        <TextEllipsis component="span">
          {`${translate('common.editImage')}: ${image.name}`}
        </TextEllipsis>
      </ModalHeader>

      <DialogContent style={{ padding: '8px' }}>
        <StyledEditorContainer>
          <ImageEditor
            cssMaxHeight={isMobileOnly ? 300 : 600}
            cssMaxWidth={isMobileOnly ? 300 : 600}
            includeUI={{
              loadImage: {
                path: (image && image.base64Content) || '',
                name: (image && image.name) || ''
              },
              theme: imageEditorTheme,
              menu: ['crop', 'draw', 'rotate'], // 'filter', 'shape', 'flip', 'icon', 'text', 'mask'
              // initMenu: 'crop',
              locale: imageEditorLocale,
              uiSize: {
                width: '100%',
                height: isMobileOnly ? '430px' : '700px'
              },
              menuBarPosition: 'bottom'
            }}
            ref={imageEditorRef}
            usageStatistics={false}
          />
        </StyledEditorContainer>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" onClick={handleValidateModal}>
          {translate('button.save')}
        </Button>
      </DialogActions>
    </>
  );
};

ImageEditorModal.propTypes = {
  image: PropTypes.shape({}).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};