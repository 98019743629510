import { faFile, faFileImage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, InputLabel, Typography } from '@material-ui/core';
import { Button } from 'components/_commons/Button';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { isMobileOnly } from 'react-device-detect';
import styled, { css } from 'styled-components';
import { DocumentHelper, translate } from 'utils';
import { MAX_FILE_SIZE } from 'utils/constants';

const CustomInputFile = styled(Button)`
  max-width: ${(props) => props.maxwidth};
  padding: 0.8rem;
  border: 1px solid var(--grey-light);
  transition: border var(--transitionTime);
  pointer-events: none;

  ${(props) => props.disabled && css`
    background-color: var(--grey-light);
  `}
`;

const InputFileContainer = styled.div`
  width: 100%;

  input[type="file"] {
    position: absolute;
    visibility: hidden;
    z-index: -1;
  }

  ${(props) => !props.disabled && css`
    label {
      cursor: pointer;
    }
  `}

  @media (max-width: 560px) {
    max-width: 260px;
  }
`;

const InputFile = observer(({
  labelButton, document, handleAddDocument, maxSize,
  id, disabled, fileAccepted, maxWidth, required, needResize, certified
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { userStore } = useStores();

  const getPosition = () => new Promise((resolve) => navigator.geolocation.getCurrentPosition(
    (position) => {
      resolve(position.coords);
    },
    // If the position cannot be retrieved, return empty coordinates to avoid blocking the process
    () => resolve({}),
    { enableHighAccuracy: true }
  ));

  const handleCertifiedImage = useCallback(() => {
    // Generate a random id
    const certificallId = crypto.randomUUID();
    // Create an empty image that will be syncronized with CertifiCall
    getPosition().then((position) => {
      handleAddDocument({
        positionCertifRequired: certified,
        certificallId,
        name: certificallId,
        creationDate: new Date(),
        latitude: position.latitude,
        longitude: position.longitude,
        positionSystem: 'wgs84'

      });
    });
  }, [handleAddDocument, certified]);

  const handleFileChange = useCallback(() => {
    setTimeout(() => {
      DocumentHelper.handleFileUpload({ target: window.document.getElementById(`${id}_file`) }, needResize, maxSize)
        .then((doc) => {
          getPosition().then((position) => {
            handleAddDocument({
              ...doc,
              positionCertifRequired: certified,
              creationDate: new Date(),
              latitude: position.latitude,
              longitude: position.longitude,
              positionSystem: 'wgs84',
              positionNotCertifJustif: certified ? 'OFFLINE' : ''
            });
          });
        })
        .catch((error) => enqueueSnackbar(translate(error.message), { variant: 'error' }));
    }, 10);
  }, [handleAddDocument, needResize, maxSize, enqueueSnackbar, certified, id]);

  const isValid = Boolean((required && document) || !required);
  const customMaxWidth = isMobileOnly ? '170px' : maxWidth;

  if (certified && !userStore.isOffline) {
    return (
      <InputFileContainer disabled={disabled}>
        <InputLabel>
          <Grid
            alignItems="center"
            container
            justifyContent="space-between"
            spacing={2}
            wrap="wrap"
          >
            <Grid item xs={8}>
              <CustomInputFile
                disabled={disabled}
                isvalid={String(isValid)}
                maxwidth={customMaxWidth}
                startIcon={<FontAwesomeIcon icon={needResize ? faFileImage : faFile} />}
                type="secondary"
                onClick={handleCertifiedImage}
              >
                <Typography noWrap>{translate(labelButton)}</Typography>
              </CustomInputFile>
            </Grid>
          </Grid>
        </InputLabel>
      </InputFileContainer>
    );
  }

  return (
    <InputFileContainer disabled={disabled}>
      <InputLabel htmlFor={`${id}_file`}>
        <Grid
          alignItems="center"
          container
          justifyContent="space-between"
          spacing={2}
          wrap="wrap"
        >
          <Grid item xs={8}>
            <CustomInputFile
              disabled={disabled}
              isvalid={String(isValid)}
              maxwidth={customMaxWidth}
              startIcon={<FontAwesomeIcon icon={needResize ? faFileImage : faFile} />}
              type="secondary"
            >
              {document && document.name ? (
                <Typography noWrap>
                  {document.name}
                </Typography>
              ) : <Typography noWrap>{translate(labelButton)}</Typography>}
            </CustomInputFile>
          </Grid>
        </Grid>
      </InputLabel>
      <input
        accept={fileAccepted}
        disabled={disabled}
        id={`${id}_file`}
        type="file"
        onChange={handleFileChange}
      />
    </InputFileContainer>
  );
});

InputFile.propTypes = {
  document: PropTypes.shape({}),
  disabled: PropTypes.bool,
  fileAccepted: PropTypes.string,
  handleAddDocument: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  label: PropTypes.string,
  labelButton: PropTypes.string,
  labelVar: PropTypes.shape({}),
  maxSize: PropTypes.number,
  maxWidth: PropTypes.string,
  needResize: PropTypes.bool
};

InputFile.defaultProps = {
  disabled: false,
  document: null,
  fileAccepted: '.pdf, .doc, .docx, .jpg, .jpeg, .png, .xlsx',
  id: 1,
  label: 'forms.inputs.inputFile',
  labelButton: 'forms.inputs.selectFile',
  labelVar: null,
  maxSize: MAX_FILE_SIZE,
  maxWidth: '300px',
  needResize: false
};

export default InputFile;