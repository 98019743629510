/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { translate } from 'utils';
import { InputField, SelectField } from '../../Inputs';
import { DeclaredAndResult, SubQuestionContainer } from '../../Question';

const QuestionEnum = ({
  name, value, label, placeholder, options, declared, result,
  required, onChange, isMulti, canCreate = false, extraValue = '',
  hasDeclared, hasResult, onChangeResult, questionText
}) => {
  const handleChangeExtra = useCallback((_, inputVal) => {
    onChange(name, null, inputVal);
  }, [onChange, name]);

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item sm={6} xs={12}>
        <SelectField
          isMulti={isMulti}
          label={questionText || ''}
          name={name}
          needTranslation={false}
          options={options}
          placeholder={placeholder || 'common.selectOption'}
          required={!extraValue && required}
          value={value}
          onChange={onChange}
        />
        {canCreate && (
          <SubQuestionContainer>
            <Typography variant="body2">{translate('forms.inputs.extraEnum')}</Typography>
            <InputField
              label={label}
              name="extraValue"
              value={extraValue}
              onChange={handleChangeExtra}
            />
          </SubQuestionContainer>
        )}
      </Grid>
      <DeclaredAndResult
        declared={declared}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        result={result}
        onChangeResult={onChangeResult}
      />
    </Grid>
  );
};

export default QuestionEnum;