import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils/helpers';

// GET
const getToolList = () => RequestHelper.GET(API_ROUTES.TOOLS());

// POST
const createTool = (tool) => RequestHelper.POST(API_ROUTES.TOOLS(), tool);

const mergeTool = (hashIdEdited, hashIdMerged) => RequestHelper.POST(
  API_ROUTES.TOOLS_MERGE(hashIdEdited), { otherId: hashIdMerged }
);

const addToolForInspector = (toolId, inspectorId) => RequestHelper.POST(
  API_ROUTES.TOOLS_INSPECTOR(toolId, inspectorId)
);

// PUT
const updateTool = (hashId, tool) => RequestHelper.PUT(
  API_ROUTES.TOOLS_UPDATE(hashId), tool
);

// DELETE
const removeToolForInspector = (toolId, inspectorId) => RequestHelper.DELETE(
  API_ROUTES.TOOLS_INSPECTOR(toolId, inspectorId)
);

export const ToolsService = {
  getToolList,
  createTool,
  mergeTool,
  addToolForInspector,
  removeToolForInspector,
  updateTool
};