/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import { Grid, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import { FEEDBACK_TYPES } from 'utils/constants';
import { translate } from 'utils';
import { isMobile } from 'react-device-detect';

const FeedbackContainer = styled(Grid)`
  padding: 1rem 0;

  button {
    min-width: 120px;
  }

  svg {
    transition: all var(--transitionTime);

    &.selected {
      transform: scale(1.2);
    }
  }
`;

const ButtonFeedback = ({
  currentValue, value, name, onClick, colorDefault, colorSelected, icon
}) => (
  <Button data-feedback={value} variant="text" onClick={onClick}>
    <Grid
      alignItems="center"
      container
      direction="column"
      spacing={1}
      wrap="wrap"
    >
      <Grid item>
        <FontAwesomeIcon
          className={currentValue === value ? 'selected' : ''}
          color={currentValue === value ? colorSelected : colorDefault}
          icon={icon}
          size="3x"
        />
      </Grid>
      <Grid item>
        <Typography style={{ color: currentValue === value ? colorSelected : colorDefault }}>
          {translate(`feedback.${name}`)}
        </Typography>
      </Grid>
    </Grid>
  </Button>
);

const QuestionFeedback = ({
  name, value, onChange
}) => {
  const handleChangeFeedback = useCallback((event) => {
    const { currentTarget: { dataset } = {} } = event;
    onChange(name, Number(dataset.feedback));
  }, [name, onChange]);

  return (
    <FeedbackContainer
      alignItems="center"
      container
      justifyContent={isMobile ? 'center' : 'flex-start'}
      spacing={1}
    >
      {FEEDBACK_TYPES.map((FB) => (
        <Grid item key={FB.value}>
          <ButtonFeedback
            {...FB}
            currentValue={value}
            onClick={handleChangeFeedback}
          />
        </Grid>
      ))}
    </FeedbackContainer>
  );
};

export default QuestionFeedback;