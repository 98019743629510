import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import {
  faBallotCheck,
  faBookReader,
  faCalendarAlt,
  faCalendarTimes,
  faClipboardCheck,
  faClipboardList,
  faEdit,
  faFolders,
  faFrown,
  faGrinBeam,
  faHome,
  faMehBlank,
  faPauseCircle,
  faSmile,
  faUserCheck,
  faUserEdit,
  faUserSlash,
  faUserTag
} from '@fortawesome/pro-regular-svg-icons';

/* eslint max-len: ["error", { "ignoreTemplateLiterals": true }] */
// eslint-disable-next-line no-underscore-dangle
const getEnv = (key) => (window._env && window._env[`REACT_APP_${key}`])
  || process.env[`REACT_APP_${key}`]
  || '';

const setCurrentVersion = (version = 1) => `v${version}`;

export const ROOT_PATH = getEnv('ROOT_PATH');
export const API_URL = getEnv('API_URL');

export const KEYCLOAK_REALM = getEnv('KEYCLOAK_REALM');
export const KEYCLOAK_AUTH_SERVER_URL = getEnv('KEYCLOAK_AUTH_SERVER_URL');
export const KEYCLOAK_RESOURCE = getEnv('KEYCLOAK_RESOURCE');

export const RGPD_EMAIL = 'rgpd@tecnea.fr';
export const ASSISTANCE_EMAIL = 'inspection.cee@tec-energie.fr';

export const APP_VERSION = getEnv('VERSION');
export const ENVIRONMENT = getEnv('ENVIRONMENT');
export const CREATED = getEnv('CREATED');
export const REVISION = getEnv('REVISION');
export const GA4_TAG_ID = getEnv('TAG_MANAGER_ID');
export const CERTIFICALL_NIE = getEnv('CERTIFICALL_NIE');

export const LIST_SIZE = 20;
export const MAX_FILE_SIZE = 4; // in Mo

export const GOOGLE_MAPS = 'https://www.google.com/maps/search/?api=1&query=';

export const PATHS = {
  ADMINISTRATION: `${ROOT_PATH}administration`,
  ADMINISTRATION_TOOLS: `${ROOT_PATH}administration/outillages`,
  PROFILE: `${ROOT_PATH}profil/:userAccountHashId`,
  HOME: `${ROOT_PATH}accueil`,
  CAMPAIGN_LIST: `${ROOT_PATH}campagnes`,
  CUSTOMER_PAGE: `${ROOT_PATH}espace-client`,
  CUSTOMER_CAMPAIGN: `${ROOT_PATH}espace-client/:campaignId`,
  INSPECTION_LIST: `${ROOT_PATH}inspections`,
  INSPECTION: `${ROOT_PATH}inspections/:folderId`,
  INSPECTION_FORM: `${ROOT_PATH}inspections/:folderId/formulaire/:inspectionId`,
  RGPD: '/mentions-legales',
  COOKIES: '/gestion-cookies'
};

export const ROUTES = {
  ...PATHS,
  CUSTOMER_CAMPAIGN: (campaignId) => `${ROOT_PATH}espace-client/${campaignId}`,
  INSPECTION: (folderId) => `${ROOT_PATH}inspections/${folderId}`,
  INSPECTION_FORM: (folderId, inspectionId) => `${ROOT_PATH}inspections/${folderId}/formulaire/${inspectionId}`,
  PROFILE: (userAccountHashId) => `${ROOT_PATH}profil/${userAccountHashId}`
};

export const API_ROUTES = {

  CAMPAIGNS: (page) => `${API_URL}/${setCurrentVersion()}/campaigns?page=${page}`,
  CAMPAIGN: (campaignId) => `${API_URL}/${setCurrentVersion()}/campaigns/${campaignId}`,
  CAMPAIGN_IMPORT: () => `${API_URL}/${setCurrentVersion()}/campaigns/import`,
  CAMPAIGN_HAS_INSPECTION_IN_PROGRESS: (campaignId) => `${API_URL}/${setCurrentVersion()}/campaigns/${campaignId}/has-inspection-in-progress`,
  CAMPAIGN_REPORT: (campaignId) => `${API_URL}/${setCurrentVersion()}/campaigns/${campaignId}/report`,

  CERTIFICALL_IMAGE: (certificallId) => `${API_URL}/${setCurrentVersion()}/certificall/${certificallId}/preview`,

  INSPECTIONS: ({ search, filters, page }) => `${API_URL}/${setCurrentVersion()}/inspection-requests?search=${search}&page=${page}${filters}`,
  INSPECTION: (folderId) => `${API_URL}/${setCurrentVersion()}/inspection-requests/${folderId}`,
  INSPECTION_STATUSES: () => `${API_URL}/${setCurrentVersion()}/inspections/statuses`,
  INSPECTION_FORM_RESULT_OPTIONS: () => `${API_URL}/${setCurrentVersion()}/inspections/results`,
  INSPECTION_FORM: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/survey`,
  INSPECTION_UPDATE: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/update`,
  INSPECTION_REASSIGN: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/reassign`,
  INSPECTION_SAVE: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/survey`,
  INSPECTION_PUBLISH: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/survey/publish`,
  INSPECTION_VALIDATE: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/survey/validate`,
  INSPECTION_ARCHIVE: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/close`,
  INSPECTION_EXPORT_PLANIFICATION: ({
    search,
    filters
  }) => `${API_URL}/${setCurrentVersion()}/inspection-requests/planification-export?search=${search}${filters}`,
  INSPECTION_ASYNCHRONOUS_EXPORT_PLANIFICATION: ({
    search,
    filters
  }) => `${API_URL}/${setCurrentVersion()}/inspection-requests/planification-asynchronous-export?search=${search}${filters}`,
  INSPECTION_EXPORT_AFFECTATION: ({
    search,
    filters
  }) => `${API_URL}/${setCurrentVersion()}/inspection-requests/affectation-export?search=${search}${filters}`,
  INSPECTION_ASYNCHRONOUS_EXPORT_AFFECTATION: ({
    search,
    filters
  }) => `${API_URL}/${setCurrentVersion()}/inspection-requests/affectation-asynchronous-export?search=${search}${filters}`,
  INSPECTION_EXPORT_SYNTHESIS: ({
    search,
    filters
  }) => `${API_URL}/${setCurrentVersion()}/inspection-requests/export?search=${search}${filters}`,
  INSPECTION_EXPORT_FORM: (inspectionType) => `${API_URL}/${setCurrentVersion()}/inspection-requests/survey-export?inspectionType=${inspectionType}`,
  INSPECTION_IMPORT_PLANIFICATION: () => `${API_URL}/${setCurrentVersion()}/inspection-requests/planification-import`,
  INSPECTION_IMPORT_AFFECTATION: () => `${API_URL}/${setCurrentVersion()}/inspection-requests/affectation-import`,
  INSPECTION_IMPORT_FORM: () => `${API_URL}/${setCurrentVersion()}/inspection-requests/form-import`,
  INSPECTION_APPOINTMENT: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/appointment`,
  INSPECTION_HISTORIC: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspection-requests/${inspectionId}/history`,
  INSPECTION_GENERATE_PDF: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/report`,
  INSPECTION_GENERATE_EXCEL: (inspectionId) => `${API_URL}/${setCurrentVersion()}/inspections/${inspectionId}/manual-pdf-report`,
  INSPECTION_SURVEY_TYPES: (withEmptyForm) => `${API_URL}/${setCurrentVersion()}/inspections/survey_types?withEmptyForm=${withEmptyForm}`,

  INSPECTORS: () => `${API_URL}/${setCurrentVersion()}/inspectors`,

  INSTITUTION_OPTIONS: (freeSearch) => `${API_URL}/${setCurrentVersion()}/institutions/options?search=${freeSearch}`,

  TOOLS: () => `${API_URL}/${setCurrentVersion()}/tools`,
  TOOLS_UPDATE: (toolId) => `${API_URL}/${setCurrentVersion()}/tools/${toolId}`,
  TOOLS_MERGE: (toolId) => `${API_URL}/${setCurrentVersion()}/tools/${toolId}/fuse`,
  TOOLS_TYPES: () => `${API_URL}/${setCurrentVersion()}/tools/tool-types`,
  TOOLS_INSPECTOR: (toolId, inspectorId) => `${API_URL}/${setCurrentVersion()}/tools/${toolId}/inspector/${inspectorId}`,

  REPORT_DETAIL: (reportInstanceId) => `${API_URL}/${setCurrentVersion()}/reports/instance/${reportInstanceId}`,
  REPORT_CREATE: (type) => `${API_URL}/${setCurrentVersion()}/reports/${type}`,

  USER_ACCOUNTS: () => `${API_URL}/${setCurrentVersion()}/user-accounts`,
  USER_ACCOUNTS_DETAIL: (userAccountHashId) => `${API_URL}/${setCurrentVersion()}/user-accounts/${userAccountHashId}`,
  RESET_PASSWORD: () => `${API_URL}/${setCurrentVersion()}/user-accounts/resetPassword`,
  UPDATE_LOCALE: (locale) => `${API_URL}/${setCurrentVersion()}/user-accounts/locale?locale=${locale}`,
  CURRENT_SCOPE: () => `${API_URL}/${setCurrentVersion()}/user-accounts/current/scope`,
  CURRENT_STRUCTURE_LEVEL_OPTIONS: () => `${API_URL}/${setCurrentVersion()}/user-accounts/current/structure_level/options`,

  CUSTOMER_CAMPAIGNS: (page) => `${API_URL}/${setCurrentVersion()}/customer/campaigns/filter?page=${page}`,
  CUSTOMER_INSPECTIONS: (campaignId) => `${API_URL}/${setCurrentVersion()}/customer/campaigns/${campaignId}/inspections/filter`,
  CUSTOMER_INSPECTION_REPORTS: (campaignId) => `${API_URL}/${setCurrentVersion()}/customer/campaigns/${campaignId}/reports`,

  GET_LANGUAGE_LIST: () => `${API_URL}/${setCurrentVersion()}/application-languages`,

  TRANSLATIONS: (locale) => `${API_URL}/${setCurrentVersion()}/translations/front-labels?locale=${locale}`,

  LOG_ERRORS: `${API_URL}/${setCurrentVersion()}/logs`,
  HEALTH_CHECK: `${API_URL}/actuator/health`,
  PING: `${API_URL}/ping`
};

export const APPLICATION_ROLES = {
  SUPER_ADMIN: 'DATAC2E_SUPER_ADMIN',
  ADMIN: 'DATAC2E_ADMIN',
  CUSTOMER: 'DATAC2E_CUSTOMER',
  // SUMMARY_MANAGER: 'DATAC2E_SUMMARY_MANAGER',
  VALIDATOR: 'DATAC2E_VALIDATOR',
  // AFFECTATION_MANAGER: 'DATAC2E_AFFECTATION_MANAGER',
  CAMPAIGN_MANAGER: 'DATAC2E_CAMPAIGN_MANAGER',
  INSPECTOR: 'DATAC2E_INSPECTOR'
};

export const ADMIN_ROLES = [
  APPLICATION_ROLES.SUPER_ADMIN,
  APPLICATION_ROLES.ADMIN
];

export const CAMPAIGN_ACCESS_ROLES = [
  APPLICATION_ROLES.CAMPAIGN_MANAGER
];

export const CUSTOMER_ACCESS_ROLES = [
  APPLICATION_ROLES.CUSTOMER
];

export const INSPECTIONS_ACCESS_ROLES = [
  APPLICATION_ROLES.INSPECTOR,
  APPLICATION_ROLES.VALIDATOR,
  APPLICATION_ROLES.CAMPAIGN_MANAGER
];

export const MENU_HOME = {
  title: 'menu.home',
  icon: faHome,
  url: PATHS.HOME
};

export const MENU_ITEMS = [
  {
    title: 'menu.customerPage',
    icon: faFolders,
    url: PATHS.CUSTOMER_PAGE,
    accessRights: [APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN,
      APPLICATION_ROLES.CUSTOMER]
  },
  {
    title: 'menu.campaigns',
    icon: faFolders,
    url: PATHS.CAMPAIGN_LIST,
    accessRights: [APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN,
      APPLICATION_ROLES.CAMPAIGN_MANAGER]
  },
  {
    title: 'menu.inspections',
    icon: faClipboardList,
    url: PATHS.INSPECTION_LIST,
    accessRights: [APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN,
      APPLICATION_ROLES.VALIDATOR, APPLICATION_ROLES.CAMPAIGN_MANAGER,
      APPLICATION_ROLES.INSPECTOR]
  }
];

export const REF_DATA_MULTIPLE_MAP = [];

export const INSPECTION_SUBMIT_ACTIONS = {
  SAVE: 'SAVE',
  PUBLISH: 'PUBLISH',
  VALIDATE: 'VALIDATE'
};

export const INPUT_TYPES = {
  TEXT: 'TEXT_QUESTION',
  NUMBER: 'NUMBER_QUESTION',
  ARITHMETIC: 'ARITHMETIC_QUESTION',
  ENUM: 'DATA_QUESTION',
  DATE: 'DATE_QUESTION',
  ADDRESS: 'ADDRESS_QUESTION',
  ENTITY: 'ENTITY_QUESTION',
  SURFACE: 'SURFACE_QUESTION',
  DOCUMENT: 'IMAGE_QUESTION',
  SIGNATURE: 'SIGNATURE_QUESTION',
  GPS: 'GPS_QUESTION',
  FEEDBACK: 'FEEDBACK_QUESTION'
};

export const FEEDBACK_TYPES = [
  {
    value: 1,
    name: 'NA',
    colorDefault: 'var(--grey)',
    colorSelected: 'var(--grey-dark)',
    icon: faMehBlank
  },
  {
    value: 2,
    name: 'BAD',
    colorDefault: 'var(--grey)',
    colorSelected: 'var(--danger-color)',
    icon: faFrown
  },
  {
    value: 3,
    name: 'MEH',
    colorDefault: 'var(--grey)',
    colorSelected: 'var(--success-color)',
    icon: faSmile
  },
  {
    value: 4,
    name: 'GOOD',
    colorDefault: 'var(--grey)',
    colorSelected: 'var(--tecnea-blue)',
    icon: faGrinBeam
  }
];

export const VALID = 'VALID';

export const EXPORT_TYPES = {
  AFFECTATION: 'AFFECTATION',
  PLANIFICATION: 'PLANIFICATION',
  SYNTHESIS: 'SYNTHESIS'
};

export const INSPECTION_TYPES = [
  {
    value: '7f2747c2-f194-487e-a8a4-9400e5aa7463',
    label: 'BAR-EN-101'
  },
  {
    value: 'b132f7a1-adf9-499a-ba2c-6cddb84a9626',
    label: 'BAR-EN-102'
  },
  {
    value: '3a4c35ca-6e41-4b03-b9f5-8c4375d277b1',
    label: 'BAR-EN-103'
  }
];

export const DAYS = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche'
];

export const STATUS = {
  PENDING: {
    key: 'PENDING',
    icon: faPauseCircle,
    color: 'var(--warning-color)'
  },
  IN_RESERVE: {
    key: 'IN_RESERVE',
    icon: faPauseCircle,
    color: 'var(--warning-color)'
  },
  TO_ASSIGN: {
    key: 'TO_ASSIGN',
    icon: faUserTag,
    color: 'var(--tecnea-blue-light)'
  },
  TO_PLAN: {
    key: 'TO_PLAN',
    icon: faCalendarAlt,
    color: 'var(--tecnea-blue-light)'
  },
  NOT_REALIZED: {
    key: 'NOT_REALIZED',
    icon: faCalendarTimes,
    color: 'var(--delete-color)'
  },
  READY: {
    key: 'READY',
    icon: faClipboardCheck,
    color: 'var(--tecnea-blue-light)'
  },
  IN_PROGRESS: {
    key: 'IN_PROGRESS',
    icon: faSpinnerThird,
    color: 'var(--primary-color-light)'
  },
  TO_VALIDATE: {
    key: 'TO_VALIDATE',
    icon: faUserEdit,
    color: 'var(--warning-color)'
  },
  VALIDATING: {
    key: 'VALIDATING',
    icon: faBookReader,
    color: 'var(--warning-color)'
  },
  NOT_REALIZABLE: {
    key: 'NOT_REALIZABLE',
    icon: faCalendarTimes,
    color: 'var(--delete-color)'
  },
  TO_COMPLETE: {
    key: 'TO_COMPLETE',
    icon: faBookReader,
    color: 'var(--tecnea-blue-light)'
  },
  INCOMPLETE: {
    key: 'INCOMPLETE',
    icon: faBallotCheck,
    color: 'var(--delete-color)'
  },
  VALIDATED: {
    key: 'VALIDATED',
    icon: faUserCheck,
    color: 'var(--success-color)'
  },
  MODIFIED: {
    key: 'MODIFIED',
    icon: faEdit,
    color: 'var(--warning-color)'
  },
  INCOMPLETE_CUST: {
    key: 'INCOMPLETE_CUST',
    icon: faBallotCheck,
    color: 'var(--delete-color)'
  },
  CANCELED: {
    key: 'CANCELED',
    icon: faUserSlash,
    color: 'var(--danger-color)'
  },
  ABANDONNED: {
    key: 'ABANDONNED',
    icon: faUserSlash,
    color: 'var(--danger-color)'
  }
};

export const STATUS_FILTERS_INSPECTOR = [
  STATUS.TO_PLAN.key, STATUS.READY.key, STATUS.TO_COMPLETE.key,
  STATUS.IN_PROGRESS.key
];
export const STATUS_FILTERS_VALIDATOR = [
  STATUS.TO_VALIDATE.key, STATUS.VALIDATING.key
];
export const STATUS_FILTERS_CAMPAIGN_MANAGER = [
  STATUS.READY.key, STATUS.TO_COMPLETE.key, STATUS.IN_PROGRESS.key,
  STATUS.TO_VALIDATE.key, STATUS.VALIDATING.key, STATUS.VALIDATED.key,
  STATUS.NOT_REALIZABLE.key
];