import styled, { css } from 'styled-components';

export const Wrapper = styled.section`
  max-width: var(--wrapperWidthMax);
  min-width: var(--wrapperWidthMin);
  margin: 0 auto;
  padding: 0 2rem;
  ${(props) => props.height && css`height: ${props.height};`}
`;

export const SmallWrapper = styled(Wrapper)`
  max-width: var(--smallWrapperWidthMax);
`;

export const LargeWrapper = styled(Wrapper)`
  max-width: var(--largeWrapperWidthMax);
`;

export const FullWrapper = styled(Wrapper)`
  width: 100%;
  max-width: none;
`;