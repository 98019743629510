import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'utils';
import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const PageSubtitle = ({ subtitle }) => (
  <Typography
    align="center"
    style={{ color: 'var(--primary-color)', margin: '1rem 0' }}
    variant="h2"
  >
    {subtitle}
  </Typography>
);

PageSubtitle.propTypes = {
  subtitle: PropTypes.string.isRequired
};

export const PageTitle = ({
  title, icon, iconColor, iconTooltip, withMargin, button, ...props
}) => (
  <Typography
    align="center"
    style={{ margin: withMargin ? '3rem 0' : 0 }}
    variant="h1"
    {...props}
  >
    {title}

    {icon && (
      <span className="ml1">
        <Tooltip aria-label={translate(iconTooltip)} title={translate(iconTooltip)}>
          <IconButton>
            <FontAwesomeIcon color={iconColor} icon={icon} />
          </IconButton>
        </Tooltip>
      </span>
    )}

    {button && (
      <span className="ml1">
        {button}
      </span>
    )}
  </Typography>
);

PageTitle.propTypes = {
  button: PropTypes.element,
  icon: PropTypes.shape({}),
  iconColor: PropTypes.string,
  iconTooltip: PropTypes.string,
  title: PropTypes.string.isRequired,
  withMargin: PropTypes.bool
};

PageTitle.defaultProps = {
  button: null,
  icon: null,
  iconColor: 'var(--success-color)',
  iconTooltip: '',
  withMargin: true
};