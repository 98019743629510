import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions, DialogContent } from '@material-ui/core';
import { translate } from 'utils';
import { SelectField } from 'components';
import { isMobileOnly } from 'react-device-detect';
import { ModalHeader } from './_ModalHeader';

export const MergeDataModal = ({
  onClose, onConfirm, title, dataList
}) => {
  const [elementSelected, setElementSelected] = useState(null);

  const handleSelectElement = useCallback((element) => setElementSelected(element), []);

  const handleFormSubmit = useCallback((e) => {
    e.preventDefault();

    onConfirm(elementSelected);
    return onClose();
  }, [elementSelected, onConfirm, onClose]);

  return (
    <form onSubmit={handleFormSubmit}>
      <ModalHeader onClose={onClose}>
        {title}
      </ModalHeader>

      <DialogContent>
        <SelectField
          isClearable
          menuPlacement={isMobileOnly ? 'top' : 'bottom'}
          options={dataList}
          placeholder={translate('common.selectOption')}
          value={elementSelected || ''}
          onChange={handleSelectElement}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.merge')}</Button>
      </DialogActions>
    </form>
  );
};

MergeDataModal.propTypes = {
  dataList: PropTypes.arrayOf(PropTypes.shape({})),
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

MergeDataModal.defaultProps = {
  dataList: []
};