/* eslint-disable react/jsx-no-bind */
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { FormHelper, translate } from 'utils';
import { InputField, ToggleDeclaredValues } from '../../Inputs';
import { DeclaredAndResult } from '../../Question';

const cleanNumber = (name, value, onChange) => onChange(name, value.replace(/^0+(?!\.|$)/, ''));

const QuestionText = ({
  name, value, placeholder, decimal, declared,
  result, required, onChange, type, unit, initialized, questionText,
  hasDeclared, hasResult, onChangeResult, isDeclaredSet = null
}) => {
  const [declaredValueAccepted, setDeclaredValueAccepted] = useState(isDeclaredSet);

  const displayDeclaredValues = Boolean(initialized && declared && declaredValueAccepted === null);

  return (
    <>
      {displayDeclaredValues && (
        <Grid alignItems="center" container spacing={2}>
          <Grid item>
            <Typography>{translate('pageInspectionForm.declaredValues')}</Typography>
          </Grid>
          <Grid item>
            <ToggleDeclaredValues
              declared={declared}
              declaredValueAccepted={declaredValueAccepted}
              name={name}
              setDeclaredValueAccepted={setDeclaredValueAccepted}
              value={value}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      )}

      <Grid alignItems="center" container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputField
            autoComplete="off"
            disabled={displayDeclaredValues}
            InputProps={{
              endAdornment: (unit && (
                <InputAdornment position="end">{unit}</InputAdornment>
              )),
              lang: 'en',
              step: decimal ? '0.01' : '1'
            }}
            label={questionText || ''}
            maxRows={4}
            multiline={type === 'text'}
            name={name}
            placeholder={displayDeclaredValues ? declared : placeholder}
            required={required}
            type="text"
            value={value}
            onChange={(eventName, eventValue) => cleanNumber(eventName, eventValue, onChange)}
            onKeyPress={(e) => type === 'number' && FormHelper.formatNumberInput(e, value, decimal)}
          />
        </Grid>
        <DeclaredAndResult
          declared={declared}
          hasDeclared={hasDeclared}
          hasResult={hasResult}
          result={result}
          onChangeResult={onChangeResult}
        />
      </Grid>
    </>
  );
};

export default QuestionText;