/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-use-form-state';
import styled from 'styled-components';
import {
  DialogContent, Grid, TextField
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { DocumentHelper } from 'utils/helpers';
import { Datepicker, InputFile, SelectField } from 'components';
import { ToolsService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import { format } from 'date-fns';
import { CustomActionButtons } from './_CustomActionButtons';
import { ModalHeader } from './_ModalHeader';
import { SubModal } from './_SubModal';
import { MergeDataModal } from './_MergeDataModal';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButton-root:first-child.Mui-selected {
    background-color: var(--primary-color);
  }

  .MuiToggleButton-root:nth-child(2).Mui-selected {
    background-color: var(--danger-color);
  }
`;

export const CreateToolModal = ({
  onConfirm, onClose,
  defaultValues, toolTypes,
  reloadList
}) => {
  const toolPictureFormatted = (defaultValues.documents && defaultValues.documents.length > 0 ? ({
    ...defaultValues.documents[0],
    base64Content: DocumentHelper.getDocumentWithBase64(defaultValues.documents[0].base64Content)
  }) : null);

  const [toolPicture, setToolPicture] = useState(toolPictureFormatted);
  const [mergeModalIsVisible, setMergeDataModalIsVisible] = useState(false);
  const [toolType, setToolType] = useState(defaultValues.toolType
    ? { value: defaultValues.toolType.value, label: defaultValues.toolType.value }
    : toolTypes[0]);
  const [formState, { text }] = useFormState({
    ...defaultValues,
    activated: !!defaultValues.activated || true,
    lastCheckupDate: defaultValues.lastCheckupDate || new Date(),
    acquisitionDate: defaultValues.acquisitionDate || new Date()
  });

  const filteredLeakDetectorList = defaultValues.leakDetectorList && defaultValues.leakDetectorList
    .filter((leakDetector) => leakDetector.value !== defaultValues.id);

  const handleAddPicture = useCallback((doc) => setToolPicture(doc), []);

  const handleChangeToolType = useCallback((_, value) => setToolType(value), []);

  const handleChangeActivated = useCallback((_, activated) => {
    if (activated === null) return;

    formState.setField('activated', activated);
  }, [formState]);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    const tool = {
      ...formState.values,
      id: defaultValues.id || null,
      documents: toolPicture ? [{
        ...toolPicture,
        base64Content: DocumentHelper.getDocumentWithoutBase64(toolPicture)
      }] : null,
      type: toolType,
      lastCheckupDate: format(new Date(formState.values.lastCheckupDate), 'yyyy-MM-dd'),
      acquisitionDate: format(new Date(formState.values.acquisitionDate), 'yyyy-MM-dd')
    };

    onConfirm(tool);
    onClose();
  }, [defaultValues, toolPicture, formState.values, toolType, onConfirm, onClose]);

  const handleMergeSubmit = useCallback((elementSelected) => {
    ToolsService.mergeTool(defaultValues.id, elementSelected.value).then((response) => {
      response && onConfirm(reloadList);
    });
  }, [defaultValues, onConfirm, reloadList]);

  const openMergeDataModal = useCallback(() => setMergeDataModalIsVisible(true), []);

  const closeMergeDataModal = useCallback(() => setMergeDataModalIsVisible(false), []);

  const displayMergeButtonAction = () => Boolean(filteredLeakDetectorList && filteredLeakDetectorList.length > 0);

  return (
    <>
      <form onSubmit={handleValidateModal}>
        <ModalHeader onClose={onClose}>
          <FontAwesomeIcon className="mr1" icon={faTools} />
          {defaultValues.model ? translate('modalTools.editTool') : translate('modalTools.newTool')}
        </ModalHeader>

        <DialogContent>
          <Grid container direction="column" spacing={2}>
            {defaultValues.id && (
              <Grid item>
                <StyledToggleButtonGroup
                  aria-label={translate('modalTools.toolStatus')}
                  exclusive
                  name="activated"
                  size="small"
                  value={formState.values.activated}
                  onChange={handleChangeActivated}
                >
                  <ToggleButton aria-label={translate('common.activated')} value>
                    {translate('common.activated')}
                  </ToggleButton>
                  <ToggleButton aria-label={translate('common.deactivated')} value={false}>
                    {translate('common.deactivated')}
                  </ToggleButton>
                </StyledToggleButtonGroup>
              </Grid>
            )}

            <Grid item>
              <SelectField
                isClearable={false}
                label="common.toolType"
                name="toolType"
                options={toolTypes}
                required
                value={toolType}
                onChange={handleChangeToolType}
              />
            </Grid>

            <Grid item>
              <TextField
                error={formState.errors.brand && formState.errors.brand !== null}
                fullWidth
                label={translate('pageToolList.brand')}
                name="brand"
                required
                {...text('brand')}
              />
            </Grid>

            <Grid item>
              <TextField
                error={formState.errors.model && formState.errors.model !== null}
                fullWidth
                label={translate('common.model')}
                name="model"
                required
                {...text('model')}
              />
            </Grid>

            <Grid item>
              <TextField
                error={formState.errors.serialNumber && formState.errors.serialNumber !== null}
                fullWidth
                label={translate('common.serialNumber')}
                name="serialNumber"
                required
                {...text('serialNumber')}
              />
            </Grid>

            <Grid item>
              <Datepicker
                disableFuture
                fullWidth
                label="common.acquisitionDate"
                name="acquisitionDate"
                required
                value={formState.values.acquisitionDate}
                onChange={(_, value) => formState.setField('acquisitionDate', value)}
              />
            </Grid>

            <Grid item>
              <Datepicker
                disableFuture
                fullWidth
                label="common.lastCheckupDate"
                name="lastCheckupDate"
                required
                value={formState.values.lastCheckupDate}
                onChange={(_, value) => formState.setField('lastCheckupDate', value)}
              />
            </Grid>

            <Grid item>
              <InputFile
                document={toolPicture}
                fileAccepted=".jpg, .jpeg, .png"
                handleAddDocument={handleAddPicture}
                id="toolPicture"
                label="modalTools.toolPicture"
                labelButton="forms.inputs.selectImage"
                needResize
              />
            </Grid>

            {toolPicture && (
              <Grid alignItems="center" container item justifyContent="center">
                <img
                  alt={toolPicture.name}
                  src={toolPicture.base64Content}
                  style={{ maxHeight: '200px' }}
                />
              </Grid>
            )}
          </Grid>
        </DialogContent>

        <CustomActionButtons
          disabled={!formState.values.model}
          isValidated={formState.values.validated}
          mergeButtonAction={displayMergeButtonAction() ? openMergeDataModal : null}
          onClose={onClose}
        />
      </form>

      {mergeModalIsVisible && (
        <SubModal>
          <MergeDataModal
            dataList={filteredLeakDetectorList}
            title={translate('common.mergeWithExistingData')}
            onClose={closeMergeDataModal}
            onConfirm={handleMergeSubmit}
          />
        </SubModal>
      )}
    </>
  );
};

CreateToolModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({}),
  reloadList: PropTypes.func
};

CreateToolModal.defaultProps = {
  defaultValues: {},
  reloadList: () => { }
};