import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { translate } from 'utils';
import shortid from 'shortid';

const InfoLine = ({
  label, info, displayIf, type
}) => {
  const isDisplayed = useCallback(() => {
    if (displayIf !== undefined) return Boolean(displayIf);
    if (info instanceof Array) return Boolean(info.length);
    if (info instanceof Object) return Boolean(info.label);

    return Boolean(info);
  }, [displayIf, info]);

  const displayInfo = useCallback(() => {
    if (info instanceof Array) {
      return info.map((inf, index) => {
        const infoLabel = `${inf.label}${index < info.length - 1 ? ', ' : ''}`;

        return <strong key={shortid.generate()}>{infoLabel}</strong>;
      });
    }

    if (info instanceof Object) {
      if (info.label) { return <strong>{info.label}</strong>; }

      return info;
    }

    if (type) {
      switch (type) {
      case 'email':
        return <a href={`mailto:${info}`}><strong>{info}</strong></a>;
      case 'phone':
        return <a href={`tel:${info}`}><strong>{info}</strong></a>;
      default:
        return info;
      }
    }

    return <strong>{info}</strong>;
  }, [info, type]);

  if (!isDisplayed()) return null;

  return (
    <Typography component="div" variant="body1">
      {`${translate(label)} : `}
      {displayInfo()}
    </Typography>
  );
};

InfoLine.propTypes = {
  displayIf: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number
  ]),
  info: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  label: PropTypes.string,
  type: PropTypes.string
};

InfoLine.defaultProps = {
  displayIf: undefined,
  info: '',
  label: '',
  type: ''
};

export default InfoLine;