/* eslint-disable no-param-reassign */
/* eslint-disable class-methods-use-this */
import {
  action, decorate, observable, runInAction
} from 'mobx';

import { IndexedDBHelper } from 'utils';

const CERTIFICALL_STORE = 'certificall';

class CertificallStore {
  constructor() {
    this.syncedImages = [];
  }

  reload() {
    return IndexedDBHelper.getAllData({
      store: CERTIFICALL_STORE
    }).then((images) => runInAction('LoadSyncedImages', () => {
      // Update the global variable with the stored data
      this.syncedImages = images ?? [];
    })).catch(() => runInAction('LoadSyncedImages', () => {
      this.syncedImages = [];
    }));
  }

  addImage(image) {
    return IndexedDBHelper.updateData({
      store: CERTIFICALL_STORE,
      key: image.certificallId,
      data: image
    }).then(() => this.reload()).catch((error) => { throw error; });
  }

  cancelImage(certificallId) {
    IndexedDBHelper.deleteData({
      store: CERTIFICALL_STORE,
      key: certificallId
    }).then(() => this.reload()).catch((error) => { throw error; });
  }
}

decorate(CertificallStore, {
  syncedImages: observable,
  reload: action,
  addImage: action,
  cancelImage: action
});

export const certificallStore = new CertificallStore();