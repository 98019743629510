import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Grid, Paper } from '@material-ui/core';
import { Wrapper } from '../Wrapper';

export const SkeletonInspectionDetail = () => (
  <Wrapper>
    <Skeleton height={40} style={{ margin: '3rem 0' }} variant="rect" width="100%" />
    <Grid container spacing={4} wrap="wrap">
      <Grid item sm={6} xs={12}>
        <Paper style={{ height: '250px' }}>
          <Skeleton height={40} style={{ marginBottom: '1rem' }} variant="rect" width="100%" />
          <Skeleton height={200} variant="rect" width="100%" />
        </Paper>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Paper style={{ height: '250px' }}>
          <Skeleton height={40} style={{ marginBottom: '1rem' }} variant="rect" width="100%" />
          <Skeleton height={200} variant="rect" width="100%" />
        </Paper>
      </Grid>
    </Grid>
  </Wrapper>
);