const MAX_WIDTH = 800;
const MAX_HEIGHT = 800;

const resizeImage = (reader) => new Promise((resolve) => {
  const img = document.createElement('img');
  img.src = reader.result;
  img.crossOrigin = 'anonymous';

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);

    // eslint-disable-next-line prefer-destructuring
    let width = img.width;
    // eslint-disable-next-line prefer-destructuring
    let height = img.height;

    if (width > height) {
      if (width > MAX_WIDTH) {
        height *= MAX_WIDTH / width;
        width = MAX_WIDTH;
      }
    } else if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }

    canvas.width = width;
    canvas.height = height;
    const ctxx = canvas.getContext('2d');
    ctxx.drawImage(img, 0, 0, width, height);

    resolve(canvas.toDataURL());
  };
});

const handleFileUpload = (event, needResize, maxSize) => new Promise((resolve, reject) => {
  const allFiles = event.target.files;
  if (allFiles) {
    const readerOnLoad = async (readerLoaded, currentFileLoaded) => {
      const fileUploaded = {
        name: currentFileLoaded.name,
        base64Content: readerLoaded.result
      };

      if (!needResize) return resolve(fileUploaded);

      const imgResized = await resizeImage(readerLoaded);

      return resolve({
        ...fileUploaded,
        base64Content: imgResized
      });
    };

    const reader = new FileReader();
    const currentFile = allFiles[0];
    const filesize = ((currentFile.size / 1024) / 1024).toFixed(2); // in Mo

    if (filesize <= maxSize || needResize) {
      reader.readAsDataURL(currentFile);
      reader.onload = () => readerOnLoad(reader, currentFile);
    } else {
      reject(Error('errors.documentTooBig'));
    }
  }
});

const convertBytesToStream = (response) => {
  const reader = response.body.getReader();

  return new ReadableStream({
    start(controller) {
      function pump() {
        return reader.read().then(({ done, value }) => {
          if (done) {
            controller.close();
            return null;
          }
          controller.enqueue(value);
          return pump();
        });
      }

      return pump();
    }
  });
};

const createDownloadableDocument = ({ result, linkTitle, type = 'application/pdf' }) => {
  const documentData = new Uint8Array(result);
  const blob = new Blob([documentData], { type });
  const link = document.createElement('a');
  link.download = linkTitle;
  link.href = window.URL.createObjectURL(blob);
  link.click();
};

const getDocumentWithBase64 = (base64Content) => (
  `data:image/png;base64,${base64Content}`
);

const getPDFWithBase64 = (base64Content) => (
  `data:application/pdf;base64,${base64Content}`
);

const getExcelWithBase64 = (base64Content) => (
  `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64Content}`
);

const getZipWithBase64 = (base64Content) => (
  `data:application/zip;base64,${base64Content}`
);

const stripBase64 = (base64Content) => {
  if (!base64Content) {
    return '';
  }
  return base64Content.split(',')[1];
};

const getDocumentWithoutBase64 = (document) => stripBase64(document.base64Content);

const getFileNameFromHeaders = (request) => {
  let filename = request.headers.get('content-disposition');
  // eslint-disable-next-line prefer-destructuring
  filename = filename.substring(filename.lastIndexOf('filename=')).split('; ')[0];
  // eslint-disable-next-line prefer-destructuring
  filename = filename.split('"')[1];
  return filename.substring(filename.lastIndexOf('=') + 1);
};

const downloadFile = ({ response, name }) => {
  const fileReader = new FileReader();
  const stream = convertBytesToStream(response);
  new Response(stream).blob().then((blob) => {
    fileReader.readAsArrayBuffer(blob);
    // eslint-disable-next-line no-param-reassign
    fileReader.onload = () => {
      createDownloadableDocument({
        result: fileReader.result,
        linkTitle: name,
        type: response.headers.get('content-type')
      });
    };
  });
};

export const DocumentHelper = {
  handleFileUpload,
  resizeImage,
  convertBytesToStream,
  createDownloadableDocument,
  getDocumentWithBase64,
  getPDFWithBase64,
  getExcelWithBase64,
  getZipWithBase64,
  stripBase64,
  getDocumentWithoutBase64,
  getFileNameFromHeaders,
  downloadFile
};