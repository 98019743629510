const clipboardWrite = (data) => navigator.clipboard.write(data);

const clipboardWriteText = (text) => navigator.clipboard.writeText(text);

const clipboardRead = () => navigator.clipboard.read();

const clipboardReadText = () => navigator.clipboard.readText();

export const NavigatorHelper = {
  clipboardWrite,
  clipboardWriteText,
  clipboardRead,
  clipboardReadText
};