import {
  faCalendarAlt, faClipboardList, faCloudDownloadAlt, faCopy, faHistory, faMapMarkedAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Card, CardContent, Divider, Grid, Typography
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import {
  ButtonBigIcon, InfoLine, PageTitle, SkeletonInspectionDetail, Status,
  TextAddress, TextError, Wrapper
} from 'components';
import { TextEntity } from 'components/_commons/ListCells';
import { useAccessRights, useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, {
  lazy, Suspense, useCallback, useEffect
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { NavigatorHelper, translate } from 'utils';
import { GOOGLE_MAPS } from 'utils/constants';
import { formatNiceDate } from 'utils/helpers';

const InspectionDetailNav = lazy(() => import('./InspectionDetailNav'));

const InspectionSubtitle = styled.h2`
  position: relative;
  margin: -2rem auto 3rem auto;
  color: var(--grey-dark);
  font-size: 2rem;
  text-align: center;

  & > *:not(:last-child) {
    margin-right: 3rem;
  }

  @media (max-width: 768px) {
    span {
      display: block;
    }

    & > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 1rem;
    }
  }
`;

const CustomCardTitleItem = styled.span`
  position: absolute;
  top: 0;
  right: 5px;
  min-width: 200px;
`;

export const CustomCardTitle = ({
  title, icon, item = null
}) => (
  <div style={{ position: 'relative' }}>
    <Typography
      align="center"
      component="h3"
      style={{
        padding: '1rem 0',
        borderBottom: '1px solid var(--grey-lighter)',
        textTransform: 'uppercase',
        backgroundColor: '#e4e4e4'
      }}
      variant="h3"
    >
      {icon && <FontAwesomeIcon icon={icon} style={{ marginRight: '1rem' }} />}
      {translate(title)}

    </Typography>

    {item && (
      <CustomCardTitleItem>
        {item}
      </CustomCardTitleItem>
    )}
  </div>
);

const InspectionDetail = observer(() => {
  const history = useHistory();
  const showModal = useModal();
  const { folderId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { userStore, inspectionStore } = useStores();
  const { isOffline } = userStore;
  const {
    isLoading, inspection, isInspectionSaved
  } = inspectionStore;
  const hasAccessRights = useAccessRights({ checkOffline: true });

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });

    inspectionStore.resetCurrentInspection().then(() => {
      inspectionStore.loadInspection({ folderId, isOffline })
        .then((insp) => {
          insp && inspectionStore.loadInspectionForm({ inspectionId: insp.inspectionId, isOffline })
            .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
        }).catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));

      inspectionStore.checkInspectionSaved({ folderId });
    });
  }, [isOffline, inspectionStore, hasAccessRights, folderId, history, enqueueSnackbar]);

  const handleShowHistoric = useCallback(() => showModal({
    type: 'INSPECTION_HISTORIC',
    inspectionId: inspection.id,
    inspectionIdentifier: inspection.inspectionIdentifier
  }), [inspection, showModal]);

  const getInspectionAddress = useCallback(() => {
    if (inspection.address) {
      const {
        address: {
          address1, address2, postalCode, city
        } = {}
      } = inspection;

      return `${address1 || ''} ${address2 || ''} ${postalCode || ''} ${city || ''}`;
    }

    return '-';
  }, [inspection]);

  const getGoogleMapUrl = useCallback(() => `${GOOGLE_MAPS}${getInspectionAddress()}`, [getInspectionAddress]);

  const handleCopyAddress = useCallback(() => {
    const addressFormatted = getInspectionAddress();
    NavigatorHelper.clipboardWriteText(addressFormatted)
      .then(() => enqueueSnackbar(translate('confirms.addressCopied'), { variant: 'success' }));
  }, [getInspectionAddress, enqueueSnackbar]);

  if (isLoading) {
    return <SkeletonInspectionDetail />;
  }

  if (!isLoading && !inspection) {
    return (
      <Wrapper>
        <TextError data-testid="errors.noInspectionDetail">{translate('errors.noInspectionDetail')}</TextError>
        <TextError>{translate('errors.noInspectionDetailExplanation')}</TextError>
      </Wrapper>
    );
  }

  const {
    appointment, inspectionStatus, batchIdentifier,
    address = null, beneficiary = null, installator = null, inspectionIdentifier
  } = inspection;

  const mapUrl = getGoogleMapUrl();

  return (
    <>
      <Suspense fallback={<Skeleton height={56} variant="rect" />}>
        <InspectionDetailNav />
      </Suspense>

      <Wrapper data-testid="inspection-container">
        <PageTitle
          button={(inspection.canStart || inspection.canValidate || inspection.canCorrect) ? (
            <Button
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faHistory} />}
              onClick={handleShowHistoric}
            >
              {translate('button.historic')}
            </Button>
          ) : null}
          data-testid="pageInspectionDetail.title"
          icon={isInspectionSaved ? faCloudDownloadAlt : null}
          iconTooltip="pageInspectionDetail.iconTooltipDownloaded"
          title={translate('pageInspectionDetail.title', { inspectionIdentifier })}
        />
        <InspectionSubtitle align="center">
          {batchIdentifier && (
            <span>
              <FontAwesomeIcon className="mr1" icon={faClipboardList} size="lg" />
              {batchIdentifier}
            </span>
          )}
          {inspectionStatus && (
            <Status status={inspectionStatus} />
          )}
        </InspectionSubtitle>

        <Grid container spacing={4} wrap="wrap">
          {appointment && (
            <Grid item sm={6} xs={12}>
              <Card>
                <CustomCardTitle title="pageInspectionDetail.appointment" />
                <CardContent>
                  {appointment.date && (
                    <Typography gutterBottom>
                      <FontAwesomeIcon className="mr1" icon={faCalendarAlt} size="lg" />
                      {formatNiceDate(appointment.date, true)}
                    </Typography>
                  )}

                  {appointment.comment && (
                    <InfoLine info={appointment.comment} label="forms.inputs.comment" />
                  )}
                </CardContent>
              </Card>
            </Grid>
          )}

          <Grid item sm={6} xs={12}>
            <Card>
              <CustomCardTitle title="common.address" />
              <CardContent>
                <TextAddress address={address} />
                {address && address.address1 && (
                  <Grid alignItems="center" container justifyContent="center" spacing={6}>
                    <Grid item>
                      <a
                        href={mapUrl}
                        rel="noopener noreferrer"
                        target="_blank"
                        title={translate('button.map')}
                      >
                        <ButtonBigIcon
                          icon={faMapMarkedAlt}
                          label="button.map"
                        />
                      </a>
                    </Grid>
                    <Divider orientation="vertical" style={{ height: '80px' }} variant="middle" />
                    <Grid item>
                      <ButtonBigIcon
                        icon={faCopy}
                        label="button.copy"
                        onClick={handleCopyAddress}
                      />
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Card>
              <CustomCardTitle title="common.details" />
              <CardContent>
                <TextEntity entity={beneficiary} error="errors.noBeneficiary" title="common.detailBeneficiary" />
                <Divider style={{ margin: '1rem 0' }} />
                <TextEntity entity={installator} error="errors.noWorkforce" title="common.detailWorkforce" />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Wrapper>
    </>
  );
});

export default InspectionDetail;