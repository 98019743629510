import React, { Component } from 'react';
import { translate } from 'utils';
import { Grid } from '@material-ui/core';
import { PageTitle } from 'components';
import { LogService } from 'services';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidCatch(error) {
    this.setState({ error: error.message });
    LogService.logStackTrace({
      stackTrace: error.message,
      body: 'Error boundary at the root of the application'
    })
      .catch((err) => console.error(err));
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <Grid alignItems="center" container justifyContent="center" style={{ height: '100vh', padding: '5rem' }}>
          <Grid item>
            <PageTitle title={error || translate('errors.default')} />
          </Grid>
        </Grid>
      );
    }

    return children;
  }
}

export default ErrorBoundary;