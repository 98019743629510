import React, { useCallback, useRef, useState } from 'react';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DropdownButton = ({
  label, icon, disabled, placement, children
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const anchorRef = useRef();

  const handleToggle = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleClose = useCallback(() => setIsOpen(false), []);

  return (
    <>
      <Button
        color="primary"
        disabled={disabled}
        icon={faAngleDown}
        ref={anchorRef}
        startIcon={icon && <FontAwesomeIcon icon={icon} />}
        variant="contained"
        onClick={handleToggle}
      >
        {translate(label)}
      </Button>
      <Popper anchorEl={anchorRef.current} disablePortal open={isOpen} placement={placement} transition>
        {({ TransitionProps }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'top center' }}
          >
            <Paper style={{ padding: '1rem' }}>
              <ClickAwayListener onClickAway={handleClose}>
                {children}
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

DropdownButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  placement: PropTypes.string
};

DropdownButton.defaultProps = {
  disabled: false,
  placement: 'bottom-end'
};

export default DropdownButton;