import React, { Component } from 'react';
import { translate } from 'utils';
import { LogService } from 'services';
import { Typography } from '@material-ui/core';

class ErrorBoundaryQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null
    };
  }

  componentDidCatch(error) {
    const { sectionId, questionId, question } = this.props;
    this.setState({ error: error.message });
    LogService.logStackTrace({
      stackTrace: error.message,
      body: `Error boundary for question :
        sectionId ${sectionId} //
        questionId ${questionId} //
        question ${question ? JSON.stringify(question.measured) : 'No question available'}
      `
    }).catch((err) => console.error(err));
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return (
        <Typography
          style={{ color: 'var(--danger-color)', margin: '1rem 0' }}
          variant="h3"
        >
          {translate('errors.displayQuestion')}
        </Typography>
      );
    }

    return children;
  }
}

export default ErrorBoundaryQuestion;