import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { DialogActions, DialogContent } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { Document as DocumentPDF, Page, pdfjs } from 'react-pdf'; // Docs : https://www.npmjs.com/package/react-pdf
import { ModalHeader } from './_ModalHeader';
import { SkeletonPagePDF } from '..';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export const DisplayPDFModal = ({
  onClose, name, url
}) => {
  const [pageTotal, setPageTotal] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const handleFileSuccess = useCallback(({ numPages }) => setPageTotal(numPages), []);

  const handleChange = useCallback((event, value) => setPageNumber(value), []);

  return (
    <>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faFilePdf} />
        {name}
      </ModalHeader>

      <DialogContent style={{ minHeight: 700 }}>
        <DocumentPDF
          file={`${url}`}
          loading={<SkeletonPagePDF />}
          onLoadSuccess={handleFileSuccess}
        >
          <Page
            height={660}
            loading={<SkeletonPagePDF />}
            pageNumber={pageNumber}
          />
        </DocumentPDF>
      </DialogContent>

      <DialogActions>
        <Pagination
          boundaryCount={2}
          color="primary"
          count={pageTotal}
          page={pageNumber}
          size="large"
          onChange={handleChange}
        />
      </DialogActions>
    </>
  );
};

DisplayPDFModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string
};

DisplayPDFModal.defaultProps = {
  name: 'PDF'
};