/* eslint-disable react/no-array-index-key */
import { faFileImport } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent, Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import { DocumentHelper, translate } from 'utils';
import { InputFile } from '../Form';
import { ModalHeader } from './_ModalHeader';

export const ImportFileModal = ({
  onConfirm, onClose
}) => {
  const [fileImported, setFileImported] = useState(null);

  const handleImportTemplate = useCallback((doc) => {
    if (doc) {
      setFileImported(doc);
    }
  }, []);

  const handleValidateModal = (e) => {
    e.preventDefault();

    onConfirm({
      base64Content: DocumentHelper.getDocumentWithoutBase64(fileImported),
      name: fileImported.name
    });

    onClose();
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('button.importFile')}</ModalHeader>

      <DialogContent style={{ minWidth: 420 }}>

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <InputFile
              document={fileImported}
              handleAddDocument={handleImportTemplate}
              handleDeleteDocument={null}
              id="importFile"
              label="forms.inputs.file"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!fileImported}
          startIcon={<FontAwesomeIcon icon={faFileImport} />}
          type="submit"
        >
          {translate('button.import')}
        </Button>
      </DialogActions>
    </form>
  );
};

ImportFileModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};