import {
  Button, DialogActions, DialogContent, Grid, TextField
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useFormState } from 'react-use-form-state';
import { translate } from 'utils';
import { DateTimepicker } from '..';
import { ModalHeader } from './_ModalHeader';

export const BookAppointmentModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues
}) => {
  const { date, comment } = defaultValues;
  const defaultDate = new Date().setHours(8, 0);
  const [formState, { text, raw }] = useFormState({
    date: date || defaultDate,
    comment: comment || ''
  });

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();
    // When sending the request to the server, the offset is applied and only the resulting datetime is processed
    // For example, if the timezone is GMT+2, the processed datetime will be 2 hours less than the selected datetime
    // As such, we remove the offset before sending the request to avoid problems
    const tempDate = new Date(formState.values.date);
    const newDate = new Date(tempDate.getTime() - tempDate.getTimezoneOffset() * 60000);
    onConfirm({
      ...formState.values,
      date: newDate
    });

    if (closeOnSubmit) {
      onClose();
    }
  }, [closeOnSubmit, formState.values, onConfirm, onClose]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('button.bookAppointment')}</ModalHeader>

      <DialogContent>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <DateTimepicker
              clearable
              disablePast
              error={formState.errors.name && formState.errors.name !== null}
              label="forms.inputs.date"
              name="date"
              required
              {...raw('date')}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              InputLabelProps={{
                shrink: true
              }}
              label={translate('forms.inputs.comment')}
              margin="dense"
              minRows={3}
              multiline
              name="comment"
              variant="outlined"
              {...text('comment')}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" disabled={!formState.values.date} type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};

BookAppointmentModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({})
};

BookAppointmentModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {}
};