import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, DialogActions } from '@material-ui/core';
import { APPLICATION_ROLES } from 'utils/constants';
import { translate } from 'utils';
import { useAccessRights } from 'hooks';

export const CustomActionButtons = ({
  isValidated, onCancel, onClose, disabled,
  additionalRoles, mergeButtonAction
}) => {
  const hasAccessRights = useAccessRights({
    roles: [
      APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.CAMPAIGN_MANAGER, ...additionalRoles
    ]
  });

  const isValidateMode = hasAccessRights && !isValidated;

  const handleClick = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  return (
    <DialogActions>
      <Button
        variant="text"
        onClick={handleClick}
      >
        {translate(isValidateMode ? 'button.reportValidation' : 'button.cancel')}
      </Button>

      <Button
        color="primary"
        disabled={disabled}
        type="submit"
      >
        {translate(isValidateMode ? 'button.validateData' : 'button.save')}
      </Button>

      {mergeButtonAction && (
        <Button color="secondary" onClick={mergeButtonAction}>{translate('button.merge')}</Button>
      )}
    </DialogActions>
  );
};

CustomActionButtons.propTypes = {
  additionalRoles: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  isValidated: PropTypes.bool,
  mergeButtonAction: PropTypes.func,
  onCancel: PropTypes.func,
  onClose: PropTypes.func
};

CustomActionButtons.defaultProps = {
  additionalRoles: [],
  disabled: false,
  isValidated: true,
  mergeButtonAction: null,
  onCancel: () => {},
  onClose: () => {}
};