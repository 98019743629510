/* eslint-disable react/no-array-index-key */
import {
  Button, DialogActions, DialogContent, Typography
} from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { InputField } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { translate } from 'utils';
import { ModalHeader } from './_ModalHeader';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)`
  .MuiToggleButton-root:first-child.Mui-selected {
    background-color: var(--danger-color);
  }

  .MuiToggleButton-root:nth-child(2).Mui-selected {
    background-color: var(--primary-color);
  }

  .MuiToggleButton-root:last-child.Mui-selected {
    background-color: var(--grey);
  }
`;

export const ValidateInspectionModal = ({
  onConfirm, onClose
}) => {
  const [isValidated, setIsValidated] = useState(null);
  const [statusComment, setStatusComment] = useState('');

  const handleChangeValidated = useCallback((_, status) => {
    if (!status) return;

    setIsValidated(status);
  }, []);

  const handleChangeComment = useCallback((_, value) => setStatusComment(value), []);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    onConfirm(isValidated, statusComment);
    return onClose();
  }, [onConfirm, onClose, isValidated, statusComment]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('pageInspectionForm.validateInspection')}</ModalHeader>

      <DialogContent>
        <Typography gutterBottom>
          {translate('pageInspectionForm.thisInspectionIs')}
        </Typography>

        <StyledToggleButtonGroup
          exclusive
          required
          value={isValidated}
          onChange={handleChangeValidated}
        >
          <ToggleButton value="invalid">
            {translate('common.invalid')}
          </ToggleButton>
          <ToggleButton value="valid">
            {translate('common.valid')}
          </ToggleButton>
          <ToggleButton value="noResult">
            {translate('common.noResult')}
          </ToggleButton>
        </StyledToggleButtonGroup>

        <br />
        <br />
        <InputField
          label={translate('forms.inputs.comment')}
          minRows={3}
          multiline
          name="statusComment"
          value={statusComment}
          onChange={handleChangeComment}
        />
      </DialogContent>

      <DialogActions>
        <Button variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" disabled={!isValidated} type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};

ValidateInspectionModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};