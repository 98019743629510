import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Text } from 'components';
import { Grid } from '@material-ui/core';
import { Wrapper } from '../Wrapper';

const BannerWrapper = styled(Wrapper)`
  width: 100%;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  min-height: 30vh;
  position: relative;
  color: #fff;
  padding: 6rem 0;
  background: var(--primary-color) url('/assets/images/bg/cover-decoration.png');
  background-repeat: no-repeat;
  background-size: cover;
`;

export const Banner = ({
  backgroundImage, title, children
}) => (
  <BannerContainer
    backgroundImage={backgroundImage}
    data-testid="banner"
  >
    <BannerWrapper>
      <Grid alignItems="flex-start" container direction="column" spacing={4}>
        <Grid item>
          {title && (
            <Text as="h1">
              {title}
            </Text>
          )}
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </BannerWrapper>
  </BannerContainer>
);

Banner.propTypes = {
  backgroundImage: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element
  ])
};

Banner.defaultProps = {
  backgroundImage: '',
  children: null,
  title: ''
};