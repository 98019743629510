import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar, List, ListItemAvatar, ListItemIcon, ListItemText, Typography
} from '@material-ui/core';
import {
  DragDropContext, Draggable, Droppable
} from 'react-beautiful-dnd'; // Docs : https://github.com/atlassian/react-beautiful-dnd/
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { translate } from 'utils';
import { StyledListItem } from 'components';
import { IconButton } from '..';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const ReorderableList = ({
  title, list, onDelete, onReorder
}) => {
  const onDragEnd = useCallback((result) => {
    const { destination, source } = result;

    // If the item is dropped outside the list
    if (!destination) {
      return;
    }

    const items = reorder(
      list,
      source.index,
      destination.index
    );

    onReorder(items);
  }, [list, onReorder]);

  return (
    <>
      {list && list.length > 0 && <Typography>{title}</Typography>}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <List ref={provided.innerRef} {...provided.droppableProps}>
              {list.map((item, index) => (
                <Draggable draggableId={item.id} index={index} key={item.id}>
                  {(prov) => (
                    <StyledListItem
                      {...prov.draggableProps}
                      {...prov.dragHandleProps}
                      ref={prov.innerRef}
                    >
                      <ListItemIcon style={{ minWidth: '30px' }}>
                        <>
                          <FontAwesomeIcon icon={faEllipsisV} />
                          <FontAwesomeIcon icon={faEllipsisV} />
                        </>
                      </ListItemIcon>
                      {item.logoURL && (
                        <ListItemAvatar>
                          <Avatar
                            src={`${item.logoURL}`}
                            style={{ boxShadow: 'rgba(0,0,0,0.2) 0px 0px 10px' }}
                          />
                        </ListItemAvatar>
                      )}
                      <ListItemText primary={item.name} secondary={item.description} />
                      {onDelete && (
                        <IconButton
                          aria-label={translate('button.delete')}
                          edge="end"
                          type="danger"
                          variant="text"
                          onClick={() => onDelete(item)}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} size="xs" />
                        </IconButton>
                      )}
                    </StyledListItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </List>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

ReorderableList.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onDelete: PropTypes.func,
  onReorder: PropTypes.func.isRequired
};

ReorderableList.defaultProps = {
  onDelete: null
};