export const LOCATION = [
  {
    value: 'client',
    label: 'Client'
  },
  {
    value: 'externe',
    label: 'Externe'
  },
  {
    value: 'interne',
    label: 'Interne'
  }
];

export const TOOL_TYPE = [
  {
    value: 'hammer',
    label: 'Marteau'
  },
  {
    value: 'screwdriver',
    label: 'Tournevis'
  },
  {
    value: 'saw',
    label: 'Scie'
  }
];

export const BOOLEAN = [
  {
    value: 'yes',
    label: 'Oui'
  },
  {
    value: 'no',
    label: 'Non'
  }
];

export const referenceDataFixture = {
  BOOLEAN,
  LOCATION,
  TOOL_TYPE
};