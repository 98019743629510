import { faSignalAltSlash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Snackbar } from '@material-ui/core';
import { SkeletonMain } from 'components';
import { useStores } from 'hooks';
import i18n from 'i18next';
import { frenchLocale } from 'locales/i18n';

import GoogleAnalytics from 'components/_commons/GoogleAnalytics/GoogleAnalytics';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Routes } from 'routes/routes';
import { HealthcheckService, InspectionService } from 'services';
import {
  DocumentHelper,
  FilterHelper, IndexedDBHelper, KeyCloakUtils, StorageHelper,
  translate,
  UserHelper
} from 'utils';
import {
  APPLICATION_ROLES,
  STATUS_FILTERS_CAMPAIGN_MANAGER,
  STATUS_FILTERS_INSPECTOR,
  STATUS_FILTERS_VALIDATOR
} from 'utils/constants';

import { toLower } from 'lodash';

import ErrorBoundary from './ErrorBoundary';

const CustomSnackBar = ({ open, message }) => (
  <Snackbar
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center'
    }}
    message={message}
    open={open}
  />
);

export const App = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    userStore, referenceDataStore, translationStore, scopeStore, certificallStore, inspectionStore, i18nStore
  } = useStores();
  const { isOffline, isDisconnecting } = userStore;

  const [isUserLoading, setIsUserLoading] = useState(true);
  const [translationLoaded, setTranslationsLoaded] = useState(false);
  const [isDBopen, setIsDBopen] = useState(false);
  const [isIntegrityCompromised, setIsIntegrityCompromised] = useState(false);
  const [isIndexedDBNotSupported, setIsIndexedDBNotSupported] = useState(false);

  i18n.on('languageChanged', () => setTranslationsLoaded(true));

  const checkHealth = useCallback(() => HealthcheckService.healthcheck()
    .then((healthcheck) => {
      userStore.setOffline(false);
      setIsIntegrityCompromised(false);

      const serviceNotOk = Object.values(healthcheck).find((status) => status !== 'UP');
      serviceNotOk && setIsIntegrityCompromised(true);
    }), [userStore]);

  const checkToken = useCallback(() => {
    if (!userStore.isOffline) {
      if (userStore.keycloak && userStore.keycloak.authenticated && userStore.isTokenExpired()) {
        return userStore.refreshToken()
          .then(() => checkHealth())
          .catch(() => userStore.disconnectUser()
            .then(() => userStore.keycloakLogin()));
      }

      return checkHealth().catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
    }

    return null;
  }, [userStore, checkHealth, enqueueSnackbar]);

  const setDefaultFilters = (defaultFilters, roleStatus) => {
    defaultFilters.push({ key: 'statuses', label: roleStatus, value: roleStatus });
    FilterHelper.setFilters('inspectionList', defaultFilters);
    return defaultFilters;
  };

  const setInspectionListDefaultFilters = useCallback(({ user, availableStatus, availableInspectors }) => {
    if (!availableStatus || availableStatus.length === 0) return [];
    if (FilterHelper.getFilters('inspectionList')) return [];

    // Initialise the default filters using the most restrictive role
    const defaultFilters = [];

    if (UserHelper.hasAccessRight([APPLICATION_ROLES.INSPECTOR])) {
      // If the user is only inspector, there is no need to filter on the inspector
      if (!UserHelper.hasSingleAccessRight(APPLICATION_ROLES.INSPECTOR)) {
        const currentInspector = availableInspectors.filter((i) => toLower(i.label) === toLower(user.name));
        if (currentInspector) {
          defaultFilters.push({
            key: 'inspectorId',
            label: user.name,
            value: currentInspector
          });
        }
      }

      const inspectorStatus = availableStatus.filter((stat) => STATUS_FILTERS_INSPECTOR.includes(stat.value));
      return setDefaultFilters(defaultFilters, inspectorStatus);
    }
    if (UserHelper.hasAccessRight([APPLICATION_ROLES.VALIDATOR])) {
      const validatorStatus = availableStatus.filter((stat) => STATUS_FILTERS_VALIDATOR.includes(stat.value));
      return setDefaultFilters(defaultFilters, validatorStatus);
    }
    if (UserHelper.hasAccessRight([APPLICATION_ROLES.CAMPAIGN_MANAGER])) {
      const campaignStatus = availableStatus.filter((stat) => STATUS_FILTERS_CAMPAIGN_MANAGER.includes(stat.value));
      return setDefaultFilters(defaultFilters, campaignStatus);
    }

    FilterHelper.setFilters('inspectionList', defaultFilters);
    return defaultFilters;
  }, []);

  const syncCertificallImage = useCallback((image) => {
    if (userStore.isOffline) {
      // When offline, ignore the pending syncronizations
      return {};
    }
    return inspectionStore.getQuestion({ key: image.questionKey }).then((question) => {
      if (!question) {
        // If the corresponding question doesn't exist, cancel the sync
        certificallStore.cancelImage(image.certificallId);
        return { image, reason: 'NO QUESTION' };
      }
      const updatedImages = question.measured.images;
      const index = updatedImages.findIndex((i) => i.certificallId === image.certificallId);
      if (index < 0) {
        // If the corresponding image doesn't exist, cancel the sync
        certificallStore.cancelImage(image.certificallId);
        return { image, reason: 'NO IMAGE' };
      }
      // Try to fetch the image from the server
      return InspectionService.getCertificallImage(image.certificallId).then((response) => {
        // If the image is correctly synced, update the question data
        const updatedImage = {
          ...updatedImages[index],
          certificateUrl: response.certificateUrl,
          base64Content: DocumentHelper.getDocumentWithBase64(response.base64Content),
          questionKey: image.questionKey
        };
        return { image: updatedImage, reason: 'SUCCESS' };
      }).catch(() => {
        // If the sync failed and the max wait is exceeded, cancel the sync
        const time = (new Date()).getTime() - image.creationDate.getTime();
        if (time > 60 * 20 * 1000) {
          enqueueSnackbar(translate('commons.certificall.syncFailed', { questionName: question.title }), { variant: 'error' });
          return { image, reason: 'TIME EXCEEDED' };
        }
        return {};
      });
    });
  }, [certificallStore, enqueueSnackbar, userStore.isOffline, inspectionStore]);

  const updateQuestion = useCallback((updateData) => {
    // Load the question
    inspectionStore.getQuestion({ key: updateData.key }).then((question) => {
      if (!question) {
        return;
      }
      const updatedImages = [...question.measured.images];
      // For each image, update or remove the data
      updateData.updatedImages.forEach((image) => {
        certificallStore.cancelImage(image.certificallId);
        const index = updatedImages.findIndex((i) => i.certificallId === image.certificallId);
        if (index < 0) {
          return;
        }
        if (!image.base64Content) {
          // Remove the image
          updatedImages.splice(index, 1);
          return;
        }
        // Update the image
        const updatedImage = updatedImages[index];
        updatedImages[index] = {
          ...updatedImage,
          ...image
        };
      });

      // Update the question
      inspectionStore.saveQuestion({
        key: updateData.key,
        question: {
          ...question,
          measured: {
            ...question.measured,
            images: updatedImages
          }
        }
      }).then(() => {
        // Refresh the question
        inspectionStore.setReloadKey(updateData.key);
      });
    });
  }, [certificallStore, inspectionStore]);

  const syncCertificallImages = useCallback(() => {
    const imagesToSync = [...certificallStore.syncedImages];
    Promise.all(imagesToSync.map(syncCertificallImage)).then((updateData) => {
      // Create a map per question
      const questionMap = updateData.reduce((updatedQuestions, data) => {
        if (!data.image) {
          return updatedQuestions;
        }
        const index = updatedQuestions.findIndex((q) => q.key === data.image.questionKey);
        if (index >= 0) {
          updatedQuestions[index] = {
            ...updatedQuestions[index],
            updatedImages: [...updatedQuestions[index].updatedImages, data.image]
          };
          return updatedQuestions;
        }
        return [
          ...updatedQuestions,
          {
            key: data.image.questionKey,
            updatedImages: [data.image]
          }
        ];
      }, []);
      // Update each question one by one
      questionMap.forEach(updateQuestion);
    });
  }, [certificallStore.syncedImages, syncCertificallImage, updateQuestion]);

  useEffect(() => {
    if (!isDBopen) {
      return;
    }
    certificallStore.reload();
    // Check the certificall images every 15 seconds
    setInterval(() => {
      syncCertificallImages();
    }, 1000 * 15);
  // eslint-disable-next-line
  }, [isDBopen]);

  useEffect(() => {
    // Check indexedDB support
    if (!window.indexedDB) {
      setIsIndexedDBNotSupported(true);
    } else {
      // Open main database
      IndexedDBHelper.openDatabase()
        .then(() => setIsDBopen(true))
        .catch((error) => enqueueSnackbar((error && error.message) || error, { variant: 'error' }));
    }

    // Persist storage on load
    StorageHelper.checkStoragePersisted();

    translationStore.getTranslations();
  }, [translationStore, scopeStore, enqueueSnackbar]);

  useEffect(() => {
    window.addEventListener('online', () => userStore.setOffline(!navigator.onLine));
    window.addEventListener('offline', () => userStore.setOffline(!navigator.onLine));

    userStore.setOffline(!navigator.onLine);
    i18nStore.loadLanguageList();

    checkHealth()
      .then(() => {
        KeyCloakUtils.init()
          .then(async (keycloak) => {
            await userStore.connectUser(keycloak);

            if (keycloak.tokenParsed) {
              // Load translations
              i18nStore.checkLanguage(keycloak.tokenParsed.locale || frenchLocale);
              i18nStore.loadLanguage(keycloak.tokenParsed.locale || frenchLocale);
              scopeStore.getCurrentScope()
                .catch((error) => console.error(error));

              userStore.setIsConnecting(false);
              setIsUserLoading(false);
            } else {
              if (!isOffline) {
                userStore.keycloakLogin();
              }
              if (localStorage.getItem('i18nextLng')) {
                i18nStore.checkLanguage(localStorage.getItem('i18nextLng'));
                i18nStore.loadLanguage(localStorage.getItem('i18nextLng'));
              }
            }
          });
      }).catch(() => {
        userStore.setOffline(!navigator.onLine);
        userStore.setIsConnecting(false);
        setIsUserLoading(false);
      }).finally(() => {
        if (!localStorage.getItem('i18nextLng')) {
          i18nStore.loadLanguage(frenchLocale);
        }
      });

    // Check the token then health every minute
    setInterval(() => {
      userStore.setOffline(!navigator.onLine)
        .then(() => checkToken());
    }, 1000 * 60);
    // eslint-disable-next-line
  }, [userStore, isOffline, referenceDataStore]);

  useEffect(() => {
    if (translationLoaded && !scopeStore.isCurrentScopeLoading) {
      const connectedUser = userStore.userConnected;

      const loadSharedData = async () => {
        let allInspectors = [];
        if (UserHelper.hasAccessRight([
          APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN,
          APPLICATION_ROLES.CAMPAIGN_MANAGER, APPLICATION_ROLES.VALIDATOR
        ])) {
          await referenceDataStore.loadInspectorList()
            .then((inspectorList) => {
              allInspectors = inspectorList;
            })
            .catch((error) => console.error(error));
        }
        await referenceDataStore.loadInpectionStatuses()
          .then((allStatus) => setInspectionListDefaultFilters({
            user: connectedUser,
            availableStatus: allStatus,
            availableInspectors: allInspectors
          }))
          .catch((error) => console.error(error));
        referenceDataStore.loadInpectionFormResultOptions()
          .catch((error) => console.error(error));
        referenceDataStore.loadToolTypes()
          .catch((error) => console.error(error));
        scopeStore.getStructureLevelOptions()
          .catch((error) => console.error(error));

        if (UserHelper.hasAccessRight([
          APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN
        ])) {
          referenceDataStore.loadInspectorList()
            .catch((error) => console.error(error));
        }
      };

      // Load shared data
      loadSharedData().then();
    }
    // eslint-disable-next-line
  }, [translationLoaded, scopeStore, referenceDataStore, scopeStore.isCurrentScopeLoading]);

  if (isUserLoading || isDisconnecting || !isDBopen || !translationLoaded || scopeStore.isCurrentScopeLoading) {
    return <SkeletonMain />;
  }

  return (
    <ErrorBoundary>
      <CustomSnackBar
        message={(
          <>
            <FontAwesomeIcon className="mr1" icon={faSignalAltSlash} />
            {translate('errors.offline')}
          </>
        )}
        open={isOffline}
      />
      <CustomSnackBar message={translate('errors.integrityCompromised')} open={isIntegrityCompromised} />
      <CustomSnackBar message={translate('errors.indexedDBNotSupported')} open={isIndexedDBNotSupported} />

      <Routes />
      <GoogleAnalytics />
    </ErrorBoundary>
  );
});