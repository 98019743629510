import React, { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useFormState } from 'react-use-form-state';
import SignatureCanvas from 'react-signature-canvas'; // Docs: https://www.npmjs.com/package/react-signature-canvas

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEraser } from '@fortawesome/pro-regular-svg-icons';
import {
  Button, DialogActions, DialogContent, Grid, IconButton, TextField, Typography
} from '@material-ui/core';
import { translate } from 'utils';
import { ModalHeader } from './_ModalHeader';

const SignatureCanvasContainer = styled.div`
  width: 280px;
  height: 280px;
  margin: 2rem auto 1rem auto;
  border: 2px solid var(--black);

  canvas {
    width: 100%;
    height: 100%;
  }
`;

export const SignatureModal = ({
  onConfirm, onClose, closeOnSubmit, defaultValues, questionText
}) => {
  const { defaultName, defaultOccupation, defaultSignature } = defaultValues;
  const [formState, { text }] = useFormState({
    name: defaultName || '',
    occupation: defaultOccupation || ''
  });

  let signature = {};

  useEffect(() => {
    if (defaultSignature) {
      signature.fromDataURL(defaultSignature);
    }
  // eslint-disable-next-line
  }, [defaultSignature]);

  const handleSignatureClear = useCallback(() => signature.clear(), [signature]);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    const base64Content = signature.getCanvas().toDataURL('image/png');
    const today = new Date().toISOString();

    onConfirm({
      name: formState.values.name,
      occupation: formState.values.occupation,
      creationDate: today,
      base64Content,
      extension: '.png'
    });

    if (closeOnSubmit) {
      onClose();
    }
  }, [signature, closeOnSubmit, formState.values, onConfirm, onClose]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('button.sign')}</ModalHeader>

      <DialogContent>
        <Grid className="mb1" container direction="column">
          <Grid item>
            <TextField
              autoComplete="off"
              autoFocus
              error={formState.errors.name && formState.errors.name !== null}
              fullWidth
              label={translate('forms.inputs.signName')}
              name="name"
              required
              {...text('name')}
              InputLabelProps={{
                shrink: true
              }}
              margin="dense"
              variant="outlined"
            />
          </Grid>
          <Grid item>
            <TextField
              autoComplete="off"
              error={formState.errors.occupation && formState.errors.occupation !== null}
              fullWidth
              label={translate('forms.inputs.signOccupation')}
              name="occupation"
              {...text('occupation')}
              InputLabelProps={{
                shrink: true
              }}
              margin="dense"
              variant="outlined"
            />
          </Grid>
        </Grid>

        <Typography gutterBottom>
          {questionText}
        </Typography>

        <SignatureCanvasContainer>
          <SignatureCanvas
            clearOnResize={false}
            // eslint-disable-next-line react/jsx-no-bind
            ref={(ref) => { signature = ref; }}
            throttle={5}
            velocityFilterWeight={0.3}
          />
        </SignatureCanvasContainer>
      </DialogContent>

      <DialogActions>
        <IconButton onClick={handleSignatureClear}>
          <FontAwesomeIcon color="var(--blue)" icon={faEraser} />
        </IconButton>
        <Button variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};

SignatureModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  closeOnSubmit: PropTypes.bool,
  defaultValues: PropTypes.shape({})
};

SignatureModal.defaultProps = {
  closeOnSubmit: true,
  defaultValues: {}
};