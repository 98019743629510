/* eslint-disable indent */
import React, { useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button, DropdownButton, HeaderNav
} from 'components';
import { useAccessRights, useModal, useStores } from 'hooks';
import { DocumentHelper, transformObjectListToStringQueriesParam, translate } from 'utils';
import { APPLICATION_ROLES, EXPORT_TYPES } from 'utils/constants';
import { Grid, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExport, faFileImport, faSpinner, faTh
} from '@fortawesome/pro-regular-svg-icons';
import { InspectionService } from 'services';
import { useMediaQuery } from 'react-responsive';

export const InspectionListNav = observer(({ search, filters, listSize }) => {
  const { inspectionStore, userStore } = useStores();
  const { isOffline } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const hasAccessRightsManager = useAccessRights({
    roles: [APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.CAMPAIGN_MANAGER]
  });
  const hasAccessRightsAdmin = useAccessRights({
    roles: [APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.SUPER_ADMIN]
  });
  const showModal = useModal();
  const [isExportingAffectation, setIsExportingAffectation] = useState(false);
  const [isExportingPlanification, setIsExportingPlanification] = useState(false);

  const handleDocumentDownload = useCallback((resp, message) => {
    const link = document.createElement('a');
    link.download = resp.name;
    link.href = DocumentHelper.getExcelWithBase64(resp.base64Content);
    link.click();
    enqueueSnackbar(translate(message), { variant: 'success', autoHideDuration: 5000 });
  }, [enqueueSnackbar]);

  const MAX_SIZE_EXPORTS = 1000;
  const exportAffectation = useCallback((filtersQueriesParams, message) => {
    setIsExportingAffectation(true);
    if (listSize > MAX_SIZE_EXPORTS) {
      InspectionService.exportAsynchronousInspectionsAffectation({ search, filters: filtersQueriesParams })
          .then(() => enqueueSnackbar(translate('confirms.exportDataInProgress'), { variant: 'success', autoHideDuration: 5000 }))
          .catch((error) => enqueueSnackbar(
              (error && error.message) || translate('errors.inspectionAffectationExportFailed'),
              { variant: 'error', autoHideDuration: 5000 }
          ))
          .finally(() => {
            setIsExportingAffectation(false);
          });
    } else {
      InspectionService.exportInspectionsAffectation({ search, filters: filtersQueriesParams })
          .then((resp) => handleDocumentDownload(resp, message))
          .catch((error) => enqueueSnackbar(error && error.message, { variant: 'error', autoHideDuration: 5000 }))
          .finally(() => setIsExportingAffectation(false));
    }
  }, [handleDocumentDownload, enqueueSnackbar, listSize, search]);

  const exportPlanification = useCallback((filtersQueriesParams, message) => {
    setIsExportingPlanification(true);
    if (listSize > MAX_SIZE_EXPORTS) {
      InspectionService.exportAsynchronousInspectionsPlanification({ search, filters: filtersQueriesParams })
          .then(() => enqueueSnackbar(translate('confirms.exportDataInProgress'), { variant: 'success', autoHideDuration: 5000 }))
          .catch((error) => enqueueSnackbar(
              (error && error.message) || translate('errors.inspectionPlanificationExportFailed'),
              { variant: 'error', autoHideDuration: 5000 }
          ))
          .finally(() => {
            setIsExportingPlanification(false);
          });
    } else {
      InspectionService.exportInspectionsPlanification({ search, filters: filtersQueriesParams })
          .then((resp) => handleDocumentDownload(resp, message))
          .catch((error) => enqueueSnackbar(error && error.message, { variant: 'error', autoHideDuration: 5000 }))
          .finally(() => setIsExportingPlanification(false));
    }
  }, [handleDocumentDownload, enqueueSnackbar, listSize, search]);

  const handleExportInspections = useCallback((event) => {
    const { currentTarget: { dataset } = {} } = event;
    const filtersQueriesParams = transformObjectListToStringQueriesParam(filters);
    const message = 'confirms.exportInspectionsSuccess';

    switch (dataset.type) {
      case EXPORT_TYPES.AFFECTATION:
        exportAffectation(filtersQueriesParams, message);
        break;
      case EXPORT_TYPES.PLANIFICATION:
        exportPlanification(filtersQueriesParams, message);
        break;
      default:
        break;
    }
  }, [filters, exportAffectation, exportPlanification]);

  const handleImportInspections = useCallback((event) => {
    const { currentTarget: { dataset } = {} } = event;

    showModal({
      type: 'IMPORT_INSPECTIONS',
      importType: dataset.type,
      onConfirm: () => {
        enqueueSnackbar(translate('confirms.inspectionsImported'), { variant: 'success' });

        return inspectionStore.loadInspectionList({ isOffline })
          .catch(() => enqueueSnackbar(translate('errors.inspectionListFailed'), { variant: 'error' }));
      }
    });
  }, [showModal, inspectionStore, isOffline, enqueueSnackbar]);

  const handleImportTemplate = useCallback(() => {
    showModal({
      type: 'IMPORT_TEMPLATE',
      onConfirm: () => {
        enqueueSnackbar(translate('confirms.templateImported'), { variant: 'success' });
      }
    });
  }, [showModal, enqueueSnackbar]);

  const handleImportForm = useCallback(() => {
    showModal({
      type: 'IMPORT_FORM',
      onConfirm: () => {
        enqueueSnackbar(translate('confirms.surveyImported'), { variant: 'success' });
      }
    });
  }, [showModal, enqueueSnackbar]);

  const handleExportForm = useCallback(() => {
    showModal({
      type: 'EXPORT_FORM',
      onConfirm: (resp) => {
        handleDocumentDownload(resp, 'confirms.exportSurveySuccess');
      }
    });
  }, [handleDocumentDownload, showModal]);

  return (
    <HeaderNav isDisplayed={useMediaQuery({ minWidth: 769 })}>
      <Grid item>
        <DropdownButton icon={faFileExport} label="pageInspectionList.exportInspections">
          <Grid container direction="column" spacing={2}>
            {hasAccessRightsManager && (
              <Grid item>
                <Tooltip
                  aria-label={translate('pageInspectionList.exportTooltip')}
                  title={translate('pageInspectionList.exportTooltip')}
                >
                  <div>
                    <Button
                      data-type={EXPORT_TYPES.AFFECTATION}
                      disabled={isExportingAffectation}
                      startIcon={<FontAwesomeIcon icon={isExportingAffectation ? faSpinner : faFileExport} spin={isExportingAffectation} />}
                      type="secondary"
                      onClick={handleExportInspections}
                    >
                      {translate('button.exportAffectation')}
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            )}

            <Grid item>
              <Button
                data-type={EXPORT_TYPES.PLANIFICATION}
                disabled={isExportingPlanification}
                startIcon={<FontAwesomeIcon icon={isExportingPlanification ? faSpinner : faFileExport} spin={isExportingPlanification} />}
                type="secondary"
                onClick={handleExportInspections}
              >
                {translate('button.exportPlanification')}
              </Button>
            </Grid>
          </Grid>
        </DropdownButton>
      </Grid>

      <Grid item>
        <DropdownButton icon={faFileImport} label="pageInspectionList.importInspections">
          <Grid container direction="column" spacing={2}>
            {hasAccessRightsManager && (
              <Grid item>
                <Button
                  data-type={EXPORT_TYPES.AFFECTATION}
                  startIcon={<FontAwesomeIcon icon={faFileImport} />}
                  type="secondary"
                  onClick={handleImportInspections}
                >
                  {translate('button.importAffectation')}
                </Button>
              </Grid>
            )}

            <Grid item>
              <Button
                data-type={EXPORT_TYPES.PLANIFICATION}
                startIcon={<FontAwesomeIcon icon={faFileImport} />}
                type="secondary"
                onClick={handleImportInspections}
              >
                {translate('button.importPlanification')}
              </Button>
            </Grid>
          </Grid>
        </DropdownButton>
      </Grid>

      {hasAccessRightsAdmin && (
        <Grid item>
          <Button
            startIcon={<FontAwesomeIcon icon={faTh} />}
            type="secondary"
            onClick={handleImportTemplate}
          >
            {translate('button.importTemplate')}
          </Button>
        </Grid>
      )}

      {hasAccessRightsAdmin && (
        <Grid item>
          <Button
            startIcon={<FontAwesomeIcon icon={faTh} />}
            type="secondary"
            onClick={handleImportForm}
          >
            {translate('button.importSurvey')}
          </Button>
        </Grid>
      )}

      {hasAccessRightsAdmin && (
        <Grid item>
          <Button
            startIcon={<FontAwesomeIcon icon={faTh} />}
            type="secondary"
            onClick={handleExportForm}
          >
            {translate('button.exportSurvey')}
          </Button>
        </Grid>
      )}
    </HeaderNav>
  );
});