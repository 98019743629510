/* ***
** Usage

const Component = () => {
  const hasAccessRights = useAccessRights({ roles: [APPLICATION_ROLES.ADMIN] });
};

*/

import { useStores } from 'hooks';
import { UserHelper } from 'utils';
import { APPLICATION_ROLES } from 'utils/constants';

const useAccessRights = ({
  roles = [
  APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.ADMIN,
  APPLICATION_ROLES.VALIDATOR, APPLICATION_ROLES.CAMPAIGN_MANAGER,
  APPLICATION_ROLES.INSPECTOR, APPLICATION_ROLES.CUSTOMER
], checkOffline = false
} = {}) => {
  const { userStore } = useStores();
  const { isConnected, isOffline } = userStore;

  if (checkOffline && isOffline) {
    return true;
  }

  return (isConnected && UserHelper.hasAccessRight(roles));
};

export default useAccessRights;