import React from 'react';
import { TableCell, Typography } from '@material-ui/core';
import shortid from 'shortid';
import { translate } from 'utils';

export const TextAddress = ({ address }) => {
  if (!address || !address.city) {
    return (
      <Typography>
        {translate('errors.noAddress')}
      </Typography>
    );
  }

  return (
    <Typography>
      {`${address.address1 || ''} ${address.address2 ? `(${address.address2})` : ''}`}
      <br />
      {`${address.postalCode || ''} ${address.city || ''}`}
    </Typography>
  );
};

const CellAddress = () => ({
  name: 'address',
  label: 'common.address',
  template: (row) => (
    <TableCell key={shortid.generate()}>
      {row.address && row.address.city ? (
        <TextAddress address={row.address} />
      ) : '-'}
    </TableCell>
  )
});

export default CellAddress;