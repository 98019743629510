import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleDown, faAngleDoubleUp, faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { FullWrapper } from 'components';
import { translate } from 'utils';

import { PropTypes } from 'prop-types';

const Container = styled.div`
  position: relative;
`;

const Nav = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
  max-height: ${(props) => (props.isCollapsed ? 0 : 'var(--header-nav-height)')};
  background: var(--tecnea-blue-lighter);
  z-index: var(--zindexBig);
  transition: all 0.3s;

  ${(props) => props.isCollapsed && css`
    overflow: hidden;
  `}
`;

const Collapser = styled.div`
  position: absolute;
  right: 2rem;
  bottom: -3rem;
  width: 60px;
  height: 60px;
  padding: 3.5rem 0 0rem 0;
  text-align: center;
  color: var(--tecnea-blue);
  border-radius: 50%;
  background: var(--tecnea-blue-lighter);
  cursor: pointer;
  z-index: var(--zindexMedium);
`;

export const HeaderNav = ({
  isDisplayed, backLink, children, hideCollapser
}) => {
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleCollapse = useCallback(() => setIsCollapsed(!isCollapsed), [isCollapsed]);

  const tooltipLabel = isCollapsed ? translate('button.showAdminNav') : translate('button.hideAdminNav');

  if (!isDisplayed) return null;

  return (
    <Container>
      <Nav isCollapsed={isCollapsed}>
        <FullWrapper>
          <Grid container justifyContent="space-between" style={{ padding: '1rem 0' }}>
            <Grid item>
              {backLink && (
                <Tooltip aria-label={translate('common.back')} placement="right" title={translate('common.back')}>
                  <IconButton color="primary" onClick={() => history.push(backLink)}>
                    <FontAwesomeIcon icon={faArrowLeft} size="xs" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
            <Grid item>
              <Grid alignItems="center" container spacing={2}>
                {children}
              </Grid>
            </Grid>
          </Grid>
        </FullWrapper>
      </Nav>
      {!hideCollapser && (
        <Collapser onClick={handleCollapse}>
          <Tooltip aria-label={tooltipLabel} title={tooltipLabel}>
            <div>
              <FontAwesomeIcon icon={isCollapsed ? faAngleDoubleDown : faAngleDoubleUp} size="lg" />
            </div>
          </Tooltip>
        </Collapser>
      )}
    </Container>
  );
};

HeaderNav.propTypes = {
  backLink: PropTypes.string,
  hideCollapser: PropTypes.bool,
  isDisplayed: PropTypes.bool
};

HeaderNav.defaultProps = {
  backLink: '',
  hideCollapser: false,
  isDisplayed: true
};