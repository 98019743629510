import { PageCookies } from 'pages';
import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { UserHelper } from 'utils';
import {
  ADMIN_ROLES,
  CAMPAIGN_ACCESS_ROLES,
  CUSTOMER_ACCESS_ROLES,
  INSPECTIONS_ACCESS_ROLES,
  PATHS
} from 'utils/constants';

import { Footer, Header, SkeletonMain } from 'components';

const GlobalCampaignList = lazy(() => import('components/Campaign/CampaignList/GlobalCampaignList'));
const CustomerPage = lazy(() => import('components/Campaign/CampaignList/CustomerCampaignList'));
const CustomerCampaignDetail = lazy(() => import('components/Campaign/CampaignDetail/CustomerCampaignDetail'));
const InspectionDetail = lazy(() => import('components/Inspection/InspectionDetail/InspectionDetail'));
const InspectionForm = lazy(() => import('components/Inspection/InspectionForm/InspectionForm'));
const InspectionList = lazy(() => import('components/Inspection/InspectionList/InspectionList'));
const PageProfile = lazy(() => import('pages/PageProfile'));
const PageRGPD = lazy(() => import('pages/PageRGPD'));
const PageHome = lazy(() => import('pages/PageHome'));

const SkeletonPage = () => (
  <>
    <Header />
    <SkeletonMain />
  </>
);

const withLayout = (BaseComponent) => (props) => (
  <>
    <div style={{ height: '100vh', display: 'flex', flexDirection: 'column' }}>
      <Header />
      <div style={{ flex: 1 }}>
        <BaseComponent {...props} />
      </div>
      <Footer />
    </div>
  </>
);

const PrivateRoute = ({ component: Component, authorizedRoles = [], ...rest }) => (
  UserHelper.hasAccessRight([...authorizedRoles, ...ADMIN_ROLES])
    ? <Route {...rest} render={() => withLayout(Component)()} />
    : <Redirect to={PATHS.HOME} />
);

const RouteWithLayout = ({ component, ...props }) => <Route component={withLayout(component)} {...props} />;

export const Routes = () => (
  <Suspense fallback={SkeletonPage()}>
    <Switch>
      {/* INSPECTION ROUTES */}
      <PrivateRoute authorizedRoles={INSPECTIONS_ACCESS_ROLES} component={InspectionForm} path={PATHS.INSPECTION_FORM} />
      <PrivateRoute authorizedRoles={INSPECTIONS_ACCESS_ROLES} component={InspectionDetail} path={PATHS.INSPECTION} />
      <PrivateRoute authorizedRoles={INSPECTIONS_ACCESS_ROLES} component={InspectionList} path={PATHS.INSPECTION_LIST} />
      {/* CAMPAIGN ROUTES */}
      <PrivateRoute authorizedRoles={CAMPAIGN_ACCESS_ROLES} component={GlobalCampaignList} exact path={PATHS.CAMPAIGN_LIST} />
      {/* CUSTOMER ROUTES */}
      <PrivateRoute authorizedRoles={CUSTOMER_ACCESS_ROLES} component={CustomerPage} exact path={PATHS.CUSTOMER_PAGE} />
      <PrivateRoute
        authorizedRoles={CUSTOMER_ACCESS_ROLES}
        component={CustomerCampaignDetail}
        exact
        path={PATHS.CUSTOMER_CAMPAIGN}
      />
      {/* ADMIN ROUTES */}
      {/* <PrivateRoute component={PageAdmin} path={PATHS.ADMINISTRATION} /> */}
      {/* PUBLIC ROUTES */}
      <RouteWithLayout component={PageProfile} exact path={PATHS.PROFILE} />
      <RouteWithLayout component={PageRGPD} exact path={PATHS.RGPD} />
      <RouteWithLayout component={PageCookies} exact path={PATHS.COOKIES} />
      <RouteWithLayout component={PageHome} path={PATHS.HOME} />
      { /* REDIRECTION */ }
      <Redirect to={PATHS.HOME /* TODO: put PageHome once we enable it */} />
    </Switch>
  </Suspense>
);