import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET

// POST
const createReport = (type, report) => RequestHelper.POST(API_ROUTES.REPORT_CREATE(type), report);

export const ReportService = {
  createReport
};