/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, DialogActions, DialogContent, Divider, Grid, Typography
} from '@material-ui/core';
import { DocumentHelper, translate } from 'utils';
import { InspectionService } from 'services';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { EXPORT_TYPES } from 'utils/constants';
import { useImportingReducer } from 'hooks';
import { ModalHeader } from './_ModalHeader';
import { InputFile } from '../Form';
import { TextError } from '../Text';

export const ImportInspectionsModal = ({
  onConfirm, onClose, importType
}) => {
  const [inspectionsImported, setInspectionsImported] = useState(null);
  const [state, dispatch] = useImportingReducer();

  const handleImportInspections = useCallback((doc) => {
    if (doc) {
      setInspectionsImported(doc);
      dispatch({ type: 'reset' });
    }
  }, [dispatch]);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    if (!inspectionsImported) return;

    const sendInspections = {
      name: inspectionsImported.name,
      base64Content: DocumentHelper.getDocumentWithoutBase64(inspectionsImported)
    };

    dispatch({ type: 'isImporting' });

    switch (importType) {
    case EXPORT_TYPES.AFFECTATION:
      InspectionService.importInspectionsAffectation(sendInspections)
        .then(() => {
          onConfirm();
          onClose();
        })
        .catch((error) => {
          dispatch({ type: 'error', error });
          setInspectionsImported(null);
        });
      break;
    case EXPORT_TYPES.PLANIFICATION:
      InspectionService.importInspectionsPlanification(sendInspections)
        .then(() => {
          onConfirm();
          onClose();
        })
        .catch((error) => {
          dispatch({ type: 'error', error });
          setInspectionsImported(null);
        });
      break;
    default:
      break;
    }
  }, [inspectionsImported, importType, onConfirm, onClose, dispatch]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('pageInspectionList.importInspections')}</ModalHeader>

      <DialogContent style={{ overflow: 'hidden' }}>
        {state.error && state.error.message ? (
          <Typography component="div" gutterBottom>
            <TextError style={{ margin: 0, textAlign: 'left' }}>
              {state.error.message}
            </TextError>
          </Typography>
        ) : state.error && Object.keys(state.error).length > 0 && (
          <>
            <div>
              {Object.keys(state.error).map((err, index) => (
                <Typography component="div" gutterBottom key={index}>
                  <TextError style={{ margin: 0, textAlign: 'left' }}>
                    {`${err} : `}
                  </TextError>
                  {state.error[err].map((e, i) => (
                    <Typography component="p" key={i}>{e}</Typography>
                  ))}
                </Typography>
              ))}
            </div>
            <Divider style={{ margin: '2rem 0' }} />
          </>
        )}

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography gutterBottom>{translate('pageInspectionList.importInspectionsModal')}</Typography>
          </Grid>
          <Grid item>
            <InputFile
              document={inspectionsImported}
              fileAccepted=".xlsx"
              handleAddDocument={handleImportInspections}
              id="importInspections"
              label="pageInspectionList.importInspections"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={state.isImporting} variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!inspectionsImported || state.isImporting}
          startIcon={<FontAwesomeIcon icon={state.isImporting ? faSpinner : faFileImport} spin={state.isImporting} />}
          type="submit"
        >
          {translate('button.import')}
        </Button>
      </DialogActions>
    </form>
  );
};

ImportInspectionsModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};