import { RequestHelper } from 'utils';
import { API_ROUTES } from 'utils/constants';

// GET
const getInspectionList = ({ search, filters, page }) => RequestHelper.GET(API_ROUTES.INSPECTIONS({ search, filters, page }));

const getInspection = (id) => RequestHelper.GET(API_ROUTES.INSPECTION(id));

const getInspectionForm = (id) => RequestHelper.GET(API_ROUTES.INSPECTION_FORM(id));

const getInspectionStatuses = () => RequestHelper.GET(API_ROUTES.INSPECTION_STATUSES());
const getSurveyTypes = (withEmptyForm) => RequestHelper.GET(API_ROUTES.INSPECTION_SURVEY_TYPES(withEmptyForm));

const getInpectionFormResultOptions = () => RequestHelper.GET(API_ROUTES.INSPECTION_FORM_RESULT_OPTIONS());

const getInpectionHistoric = (id) => RequestHelper.GET(API_ROUTES.INSPECTION_HISTORIC(id));

const generateReportPDF = (id) => RequestHelper.GET(API_ROUTES.INSPECTION_GENERATE_PDF(id));

const exportInspectionsPlanification = ({ search, filters }) => RequestHelper.GET(
  API_ROUTES.INSPECTION_EXPORT_PLANIFICATION({ search, filters })
);

const exportAsynchronousInspectionsPlanification = ({ search, filters }) => RequestHelper.POST(
  API_ROUTES.INSPECTION_ASYNCHRONOUS_EXPORT_PLANIFICATION({ search, filters })
);

const exportInspectionsAffectation = ({ search, filters }) => RequestHelper.GET(
  API_ROUTES.INSPECTION_EXPORT_AFFECTATION({ search, filters })
);

const exportAsynchronousInspectionsAffectation = ({ search, filters }) => RequestHelper.POST(
  API_ROUTES.INSPECTION_ASYNCHRONOUS_EXPORT_AFFECTATION({ search, filters })
);

const exportInspectionsSynthesis = ({ search, filters }) => RequestHelper.GET(
  API_ROUTES.INSPECTION_EXPORT_SYNTHESIS({ search, filters })
);

const exportInspectionForm = (inspectionType) => RequestHelper.GET(
  API_ROUTES.INSPECTION_EXPORT_FORM(inspectionType)
);

const getCertificallImage = (certificallId) => RequestHelper.GET(
  API_ROUTES.CERTIFICALL_IMAGE(certificallId)
);

// POST
const saveAppointment = (id, appointment) => RequestHelper.POST(API_ROUTES.INSPECTION_APPOINTMENT(id), appointment);

const generateReportPDFFromExcel = (id, excel) => RequestHelper.POST(API_ROUTES.INSPECTION_GENERATE_EXCEL(id), excel);

const importInspectionsPlanification = (inspectionsImported) => RequestHelper.POST(
  API_ROUTES.INSPECTION_IMPORT_PLANIFICATION(), inspectionsImported
);

const importInspectionsAffectation = (inspectionsImported) => RequestHelper.POST(
  API_ROUTES.INSPECTION_IMPORT_AFFECTATION(), inspectionsImported
);

const importInspectionForm = (formImported) => RequestHelper.POST(
  API_ROUTES.INSPECTION_IMPORT_FORM(), formImported
);

// PUT
const updateInspection = (id, newStatus) => RequestHelper.PUT(API_ROUTES.INSPECTION_UPDATE(id), { status: newStatus });

const reassignInspector = (id, inspector) => RequestHelper.PUT(API_ROUTES.INSPECTION_REASSIGN(id), inspector);

const saveInspection = (id, form) => RequestHelper.PUT(API_ROUTES.INSPECTION_SAVE(id), form);

const publishInspection = (id, form) => RequestHelper.PUT(API_ROUTES.INSPECTION_PUBLISH(id), form);

const validateInspection = (id, form) => RequestHelper.PUT(API_ROUTES.INSPECTION_VALIDATE(id), form);

const archiveInspection = (id) => RequestHelper.PUT(API_ROUTES.INSPECTION_ARCHIVE(id));

export const InspectionService = {
  getInspectionList,
  getInspection,
  getInspectionForm,
  getInspectionStatuses,
  getInpectionFormResultOptions,
  getInpectionHistoric,
  getCertificallImage,
  generateReportPDF,
  exportInspectionsPlanification,
  exportAsynchronousInspectionsPlanification,
  exportInspectionsAffectation,
  exportAsynchronousInspectionsAffectation,
  exportInspectionsSynthesis,
  exportInspectionForm,
  saveAppointment,
  generateReportPDFFromExcel,
  importInspectionsPlanification,
  importInspectionForm,
  importInspectionsAffectation,
  updateInspection,
  reassignInspector,
  saveInspection,
  publishInspection,
  validateInspection,
  archiveInspection,
  getSurveyTypes
};