import React, { useCallback } from 'react';
import { FormControlLabel, Switch } from '@material-ui/core';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react-lite';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';

const SwitchVisibility = observer(({
  name, value, label, onChange
}) => {
  const handleChange = useCallback((event) => {
    onChange(event.target.name, event.target.checked);
  }, [onChange]);

  return (
    <>
      <FormControlLabel
        control={(
          <Switch
            checked={value}
            color="primary"
            name={name}
            onChange={handleChange}
          />
        )}
        label={(
          <>
            <FontAwesomeIcon
              color={value ? 'var(--success-color)' : 'var(--warning-color)'}
              data-testid={label}
              icon={value ? faEye : faEyeSlash}
              style={{ minWidth: 25 }}
            />
            {translate(label)}
          </>
        )}
        style={{ marginBottom: '1rem' }}
      />
    </>
  );
});

SwitchVisibility.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired
};

SwitchVisibility.defaultProps = {
  label: 'common.switchVisibility'
};

export default SwitchVisibility;