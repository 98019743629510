import { faBars, faSignalAltSlash, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Fab, Grid, IconButton, Tooltip
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { Button, Desktop } from 'components';
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { Link, useHistory } from 'react-router-dom';
import { UserAccountService } from 'services';
import styled from 'styled-components';
import { KeyCloakUtils, translate } from 'utils';
import { ROUTES } from 'utils/constants';
import { BrowserDetectionHelper } from 'utils/helpers';
import { HeaderMenu } from './HeaderMenu';
import { SideMenu } from './SideMenu';

const HeaderContainer = styled.div`
  position: relative;
  height: var(--header-height);
  z-index: var(--zindexBig);
  background: var(--primary-color) url('/assets/images/bg/header-decoration.png');
  background-position: var(--header-item-width) center;
  background-repeat: no-repeat;
  overflow: hidden;
`;

const CustomGridItem = styled(Grid)`
  position: relative;
  width: var(--header-item-width);
  height: var(--header-height);
  background: var(--white);

  img {
    max-width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: -120px;
    border-left: 60px solid var(--white);
    border-right: 60px solid transparent;
    border-bottom: 60px solid transparent;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Logo = styled(Link)`
  color: var(--white);
  font-size: 2.6rem;

  &:hover {
    text-decoration: none;
    color: var(--grey-light);
  }

  span {
    font-family: 'Roboto-Light', 'Roboto','OpenSans', Arial, Helvetica, sans-serif;
  }

  sup {
    margin-left: 2px;
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    margin-left: 2rem;
  }
`;

const ButtonChangeLanguage = styled(IconButton)`
  &:disabled {
    svg {
      display: none;
    }
  }

  span {
    font-size: 1.7rem;
    color: var(--white);
  }
`;

const UserName = styled.span`
  max-width: 140px;
  color: var(--white);
  margin-right: 2rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 2px;

  @media (max-width: 768px) {
    max-width: 110px;
  }
`;

const StyledButtonMenu = styled(Fab).attrs({
  size: 'small'
})`
  position: relative;

  span {
    font-size: 1.4rem;
  }

  .buttonMenuIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -4px;
    right: -4px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: var(--tecnea-blue);
    background: var(--white);
  }
`;

export const Header = observer(() => {
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();
  const history = useHistory();
  const { userStore, i18nStore, scopeStore } = useStores();
  const {
    isConnecting, isConnected, userConnected, isOffline
  } = userStore;
  const { currentLanguage, languageList } = i18nStore;
  const {
    currentStructureLevel, structureLevelOptions, isStructureLevelOptionsLoading
  } = scopeStore;

  const [toggleMenu, setToggleMenu] = useState(false);

  const displayPopupBrowserModal = useCallback(() => showModal && showModal({
    type: 'BROWSER_POPUP',
    title: translate('warnings.warning'),
    primaryText: translate('modalBrowser.popupWarningBrowserPrimary'),
    secondaryText: translate('modalBrowser.popupWarningBrowserSecondary')
  }), [showModal]);

  useEffect(() => {
    if (isConnected) {
      BrowserDetectionHelper.modalDisplayControl(displayPopupBrowserModal);
    }
  }, [displayPopupBrowserModal, isConnected]);

  const connectUser = useCallback(() => {
    userStore.setIsConnecting();

    if (userStore.keycloak) {
      userStore.keycloakLogin()
        .catch(() => {
          userStore.setIsConnecting(false);
          enqueueSnackbar(translate('errors.offlineNoKeycloak'), { variant: 'error' });
        })
        .finally(() => userStore.setIsConnecting(false));
    } else {
      KeyCloakUtils.init().then((keycloak) => {
        userStore.connectUser(keycloak);
      })
        .catch(() => {
          userStore.setIsConnecting(false);
          enqueueSnackbar(translate('errors.offlineNoKeycloak'), { variant: 'error' });
        })
        .finally(() => userStore.setIsConnecting(false));
    }
  }, [userStore, enqueueSnackbar]);

  const handleChangeLanguage = useCallback((language) => {
    if (language) {
      if (userStore.isConnected && language.value !== localStorage.getItem('i18nextLng')) {
        UserAccountService.updateLocale(language.value).then(() => {
          i18nStore.loadLanguage(language.value);
          userStore.keycloak.updateToken(999999)
            .then((refreshed) => {
              refreshed && userStore.keycloak.login();
            })
            .catch(() => enqueueSnackbar(translate('errors.noRefreshToken'), { variant: 'error' }));
        });
      } else {
        i18nStore.loadLanguage(language.value);
      }
    }
  }, [enqueueSnackbar, userStore.isConnected, userStore.keycloak, i18nStore]);

  const displayModalLanguage = useCallback(() => showModal({
    type: 'CHANGE_LANGUAGE',
    onConfirm: (language) => handleChangeLanguage(language),
    defaultValues: currentLanguage
  }), [showModal, handleChangeLanguage, currentLanguage]);

  const handleToggleMenu = useCallback(() => setToggleMenu(!toggleMenu), [toggleMenu]);

  const updateCurrentInstitution = ({ institution }) => {
    if ((currentStructureLevel?.value || institution?.value) && currentStructureLevel?.value !== institution?.value) {
      UserAccountService.updateCurrentInstitution(institution).then((response) => {
        if (response) {
          history.push('/campagnes');
          userStore.keycloak.updateToken(999999)
            .then((refreshed) => {
              refreshed && userStore.keycloak.login();
            });
        }
      });
    }
  };

  const changeInstitution = () => showModal({
    type: 'CHANGE_INSTITUTION',
    structureLevelOptions,
    isStructureLevelOptionsLoading,
    defaultValue: currentStructureLevel.value ?? '',
    onConfirm: updateCurrentInstitution
  });

  return (
    <header>
      <HeaderContainer>
        <Grid alignItems="center" container justifyContent="space-between" wrap="nowrap">
          <CustomGridItem item>
            <Link to={ROUTES.HOME}>
              <img alt="TECNEA_INSPECTION" height="100%" src="/assets/images/companies/logo-tec-energie.svg" />
            </Link>
          </CustomGridItem>
          <Grid item>
            <Grid alignItems="center" container>
              <Grid item>
                <Logo data-link={ROUTES.HOME} to={ROUTES.HOME}>
                  <strong>DATA</strong>
                  <span>
                    C2E
                    <sup>®</sup>
                  </span>
                </Logo>
              </Grid>
              {isOffline && (
                <Desktop>
                  <Grid item>
                    <Tooltip title={translate('errors.offline')}>
                      <IconButton color="primary" size="small">
                        <FontAwesomeIcon className="ml1" color="var(--white)" icon={faSignalAltSlash} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Desktop>
              )}
            </Grid>
          </Grid>
          {isConnecting ? <Skeleton height={30} variant="rect" width="200px" /> : (
            <Grid item>
              <Grid
                alignItems="center"
                container
                justifyContent="flex-end"
                style={{ paddingRight: '2rem', height: 'var(--header-height)', flexWrap: 'nowrap' }}
              >
                {languageList.length > 1 && (
                  <Grid item style={{ paddingRight: isMobileOnly ? 0 : '2rem' }}>
                    <Tooltip
                      title={translate('modalChangeLanguage.tooltip', { language: currentLanguage.toUpperCase() })}
                    >
                      <span>
                        <ButtonChangeLanguage onClick={displayModalLanguage}>
                          <span className="mr1">{currentLanguage.split('_')[0].toUpperCase()}</span>
                          <FontAwesomeIcon color="#ffffff" icon={faChevronDown} size="xs" />
                        </ButtonChangeLanguage>
                      </span>
                    </Tooltip>
                  </Grid>
                )}
                {isConnected ? (
                  <>
                    <Grid item>
                      {userConnected && <UserName>{userConnected.name}</UserName>}
                      <>
                        <br />
                        {structureLevelOptions && structureLevelOptions.length ? (
                          <Button
                            disabled={!structureLevelOptions || !structureLevelOptions.length}
                            style={{
                              color: 'var(--white)', fontWeight: 'bold', margin: '0 10px 0 0', padding: '0'
                            }}
                            variant="text"
                            onClick={changeInstitution}
                          >
                            {currentStructureLevel?.label || translate('common.allInstitutions')}
                          </Button>
                        ) : (
                          <></>
                        )}
                      </>
                    </Grid>
                    <StyledButtonMenu
                      color="primary"
                      data-cy="headerMenu"
                      data-tour="step-header-user"
                      onClick={handleToggleMenu}
                    >
                      <span>{`${userConnected.given_name.charAt(0)}${userConnected.family_name.charAt(0)}`}</span>
                      <span className="buttonMenuIcon">
                        <FontAwesomeIcon icon={faBars} size="xs" />
                      </span>
                    </StyledButtonMenu>
                  </>
                ) : !isOffline && (
                  <Button
                    startIcon={<FontAwesomeIcon icon={faUserCircle} />}
                    type="secondary"
                    onClick={connectUser}
                  >
                    {translate('button.connect')}
                  </Button>
                )}
              </Grid>
            </Grid>
          )}

        </Grid>
        <SideMenu isOpen={toggleMenu} setToggleMenu={setToggleMenu} />
      </HeaderContainer>

      <HeaderMenu isConnected={isConnected} userConnected={userConnected} />
    </header>
  );
});