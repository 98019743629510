import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import {
  Divider, Drawer, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography
} from '@material-ui/core';
import {
  APP_VERSION, CREATED, ENVIRONMENT,
  MENU_ITEMS, REVISION, ROUTES
} from 'utils/constants';
import { translate, UserHelper } from 'utils';
import { useAccessRights, useStores } from 'hooks';

import { observer } from 'mobx-react-lite';
import { Desktop } from 'components';
import { format, parseISO } from 'date-fns';

const SideMenuContainer = styled.div`
  display: flex;
  flex: 1;

  svg {
    color: var(--tecnea-blue);
  }

  ul {
    width: 100%;
  }
`;

export const SideMenu = observer(({
  isOpen, setToggleMenu
}) => {
  const { userStore } = useStores();
  const { isConnected } = userStore;
  const hasAccessRights = useAccessRights({ checkOffline: true });
  // const hasAccessRightsAdmin = useAccessRights({ roles: [APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.SUPER_ADMIN] });

  const handleToggleMenu = useCallback(() => setToggleMenu(false), [setToggleMenu]);

  const disconnect = useCallback(() => {
    userStore.disconnectUser();
  }, [userStore]);

  return (
    <Drawer anchor="right" open={isOpen} onClose={handleToggleMenu}>
      <SideMenuContainer>
        <List>
          {isConnected && (
            <ListItem
              button
              component={Link}
              data-cy="headerMenuUser"
              to={ROUTES.PROFILE('current')}
              onClick={handleToggleMenu}
            >
              <ListItemIcon><FontAwesomeIcon icon={faUserCircle} size="lg" /></ListItemIcon>
              <ListItemText primary={translate('menu.profile')} />
            </ListItem>
          )}

          {hasAccessRights && (
            <>
              {isConnected
                ? MENU_ITEMS.map((item) => UserHelper.hasAccessRight(item.accessRights) && (
                  <ListItem
                    button
                    component={Link}
                    data-cy="headerMenuInspectionList"
                    key={item.url}
                    to={item.url}
                    onClick={handleToggleMenu}
                  >
                    <ListItemIcon><FontAwesomeIcon icon={item.icon} size="lg" /></ListItemIcon>
                    <ListItemText primary={translate(item.title)} />
                  </ListItem>
                )) : MENU_ITEMS.map((item) => (
                  <ListItem
                    button
                    component={Link}
                    data-cy="headerMenuInspectionList"
                    key={item.url}
                    to={item.url}
                    onClick={handleToggleMenu}
                  >
                    <ListItemIcon><FontAwesomeIcon icon={item.icon} size="lg" /></ListItemIcon>
                    <ListItemText primary={translate(item.title)} />
                  </ListItem>
                ))}
            </>
          )}

          {/*           {hasAccessRightsAdmin && (
            <ListItem
              button
              component={Link}
              data-cy="headerMenuAdministration"
              key={shortid.generate()}
              to={PATHS.ADMINISTRATION}
              onClick={handleToggleMenu}
            >
              <ListItemIcon><FontAwesomeIcon icon={faPenSquare} size="lg" /></ListItemIcon>
              <ListItemText primary={translate('menu.admin')} />
            </ListItem>
          )} */}

          <Divider />

          <ListItem
            button
            component={Link}
            data-cy="headerMenuLogout"
            to=""
            onClick={disconnect}
          >
            <ListItemIcon><FontAwesomeIcon icon={faSignOutAlt} size="lg" /></ListItemIcon>
            <ListItemText primary={translate('menu.disconnect')} />
          </ListItem>
        </List>
      </SideMenuContainer>

      <Typography align="center">
        {`${ENVIRONMENT} - ${APP_VERSION}`}
        <br />
        <Tooltip interactive leaveDelay={1000} title={REVISION}>
          <Typography component="span">
            {`Révision: ${REVISION.substring(0, 5)}`}
          </Typography>
        </Tooltip>
        <br />
        {CREATED && (parseISO(CREATED) instanceof Date) && (
          `Créé le: ${format(parseISO(CREATED), 'dd/MM/yyyy à HH:mm:ss')}`
        )}
      </Typography>

      <Desktop>
        <img
          alt={translate('menu.logoAlt')}
          src={`${process.env.PUBLIC_URL}/assets/images/bg/footer-mobile.png`}
          style={{ maxWidth: '100%' }}
          title={translate('menu.logoAlt')}
        />
      </Desktop>
    </Drawer>
  );
});

SideMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setToggleMenu: PropTypes.func.isRequired
};