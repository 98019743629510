/* ***
** Usage

const Component = () => {
  const hasAccessRights = useImportingReducer({ roles: [APPLICATION_ROLES.ADMIN] });
};

*/

import { useReducer } from "react";

const reducer = (state, action) => {
  switch (action.type) {
  case 'isImporting':
    return {
      isImporting: true,
      error: ''
    };
  case 'error': {
    return {
      isImporting: false,
      error: action.error || ''
    };
  }
  case 'reset': {
    return {
      isImporting: false,
      error: ''
    };
  }
  default:
    return state;
  }
};

const useImportingReducer = () => {
  const [state, dispatch] = useReducer(reducer, { isImporting: false, error: '' });

  return [state, dispatch];
};

export default useImportingReducer;