import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { DialogActions, DialogContent } from '@material-ui/core';
import sanitizeHtml from 'sanitize-html';
import { translate } from 'utils';
import { Button } from '../Button';
import { ModalHeader } from './_ModalHeader';

export const WarningModal = ({
  onClose, onConfirm, onCancel, onAction, text, title, buttonCancel, buttonConfirm, buttonAction, confirmOnly
}) => {
  const handleCancel = useCallback(() => {
    onCancel();
    onClose();
  }, [onCancel, onClose]);

  const handleAction = useCallback(() => {
    onAction && onAction();
    onClose();
  }, [onAction, onClose]);

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm();
    }

    return onClose();
  }, [onConfirm, onClose]);

  return (
    <>
      <ModalHeader className={confirmOnly ? '' : 'warningModal'} onClose={onClose}>
        {!confirmOnly && <FontAwesomeIcon icon={faExclamationTriangle} />}
        {translate(title)}
      </ModalHeader>

      <DialogContent dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} style={{ maxWidth: '500px' }} />

      <DialogActions>
        {buttonCancel && (
          <Button type="default" variant="text" onClick={handleCancel}>{translate(buttonCancel)}</Button>
        )}
        {buttonAction && (
          <Button type="default" variant="text" onClick={handleAction}>{translate(buttonAction)}</Button>
        )}
        <Button
          id="warningModalConfirm"
          type={confirmOnly ? '' : 'danger'}
          onClick={handleConfirm}
        >
          {translate(buttonConfirm)}
        </Button>
      </DialogActions>
    </>
  );
};

WarningModal.propTypes = {
  buttonAction: PropTypes.string,
  buttonCancel: PropTypes.string,
  buttonConfirm: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onAction: PropTypes.func,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({})
  ]),
  title: PropTypes.string
};

WarningModal.defaultProps = {
  buttonAction: '',
  buttonCancel: 'button.cancel',
  buttonConfirm: 'confirms.confirm',
  onAction: null,
  onCancel: () => {},
  onConfirm: null,
  text: '',
  title: 'warnings.warning'
};