import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getCampaignList = ({ page, filter }) => RequestHelper.POST(API_ROUTES.CAMPAIGNS(page), filter);

const getCampaign = (id) => RequestHelper.GET(API_ROUTES.CAMPAIGN(id));

// POST
const importCampaign = (campaign) => RequestHelper.POST(API_ROUTES.CAMPAIGN_IMPORT(), campaign);

const hasInspectionInProgress = (id) => RequestHelper.GET(API_ROUTES.CAMPAIGN_HAS_INSPECTION_IN_PROGRESS(id));

const importReport = (id, report) => RequestHelper.POST(API_ROUTES.CAMPAIGN_REPORT(id), report);

const getReport = (id) => RequestHelper.GET(API_ROUTES.CAMPAIGN_REPORT(id));

// GET INSTITUTION OPTIONS
const getInstitutionOptions = ({ freeSearch = '' }) => RequestHelper.GET(API_ROUTES.INSTITUTION_OPTIONS(freeSearch));

export const CampaignService = {
  getCampaignList,
  getCampaign,
  importCampaign,
  hasInspectionInProgress,
  importReport,
  getReport,
  getInstitutionOptions
};