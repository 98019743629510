/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, DialogActions, DialogContent, Grid
} from '@material-ui/core';
import { DocumentHelper, translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTh } from '@fortawesome/pro-regular-svg-icons';
import { InspectionService, ReportService } from 'services';
import { useImportingReducer } from 'hooks';
import { ModalHeader } from './_ModalHeader';
import { InputFile, SelectField } from '../Form';
import { TextError } from '../Text';

export const ImportTemplateModal = ({
  onConfirm, onClose
}) => {
  const [templateImported, setTemplateImported] = useState(null);
  const [inspectionType, setInspectionType] = useState(null);
  const [inspectionTypes, setInspectionTypes] = useState([]);
  const [state, dispatch] = useImportingReducer();
  useEffect(() => {
    InspectionService.getSurveyTypes(false)
      .then((result) => setInspectionTypes(result))
      .catch((error) => dispatch({ type: 'error', error }));
  }, [dispatch]);

  const handleImportTemplate = useCallback((doc) => {
    if (doc) {
      setTemplateImported(doc);
      dispatch({ type: 'reset' });
    }
  }, [dispatch]);

  const handleChangeInspectionType = useCallback((_, value) => setInspectionType(value), []);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    if (!templateImported) return;

    const sendTemplate = {
      id: inspectionType.value,
      file: {
        base64Content: DocumentHelper.getDocumentWithoutBase64(templateImported),
        name: templateImported.name
      }
    };

    dispatch({ type: 'isImporting' });

    ReportService.createReport(inspectionType.value, sendTemplate)
      .then((response) => {
        onConfirm(response);
        onClose();
      })
      .catch((error) => {
        dispatch({ type: 'error', error });
        setTemplateImported(null);
      });
  }, [templateImported, inspectionType, onConfirm, onClose, dispatch]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('button.importTemplate')}</ModalHeader>

      <DialogContent>
        {state.error && (
          <TextError style={{ textAlign: 'left' }}>
            {state.error.message}
          </TextError>
        )}

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <InputFile
              document={templateImported}
              fileAccepted=".xlsx"
              handleAddDocument={handleImportTemplate}
              handleDeleteDocument={null}
              id="importTemplate"
              label="button.importTemplate"
              required
            />
          </Grid>
          <Grid item>
            <SelectField
              isClearable={false}
              label="common.inspectionType"
              name="inspectionType"
              options={inspectionTypes}
              required
              value={inspectionType}
              onChange={handleChangeInspectionType}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={state.isImporting} variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!templateImported || state.isImporting}
          startIcon={<FontAwesomeIcon icon={state.isImporting ? faSpinner : faTh} spin={state.isImporting} />}
          type="submit"
        >
          {translate('button.import')}
        </Button>
      </DialogActions>
    </form>
  );
};

ImportTemplateModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};