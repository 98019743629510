import { scopeStore } from 'stores';

const hasAccessRight = (requiredRoles = []) => {
  if (scopeStore.currentRoles) {
    return requiredRoles.some((role) => scopeStore.currentRoles.includes(role));
  }
  return false;
};

const hasSingleAccessRight = (role) => {
  if (scopeStore.currentRoles) {
    return (scopeStore.currentRoles.length === 1 && scopeStore.currentRoles[0] === role);
  }
  return false;
};

export const UserHelper = {
  hasAccessRight,
  hasSingleAccessRight
};