import { ListItem } from '@material-ui/core';
import { FormElement } from 'components';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { translate } from 'utils';

export const StyledListItem = styled(ListItem)`
  cursor: pointer;

  &:hover {
    background-color: #DEEBFF
  }
`;

export const StyledRequiredSelectHack = styled.input`
  height: 1px;
  width: 100%;
  margin: 0;
  padding: 0;
  border: none;
  opacity: 0;
  cursor: default;
`;

const SelectField = ({
  name, options, value, label, onChange, required,
  placeholder, isClearable, needTranslation, ...props
}) => {
  const displayNoOptions = useCallback(() => translate('common.noOptions'), []);

  const handleChange = useCallback((val) => {
    if (val instanceof Array && val.length === 0) {
      return onChange(name, null);
    }

    return onChange(name, val);
  }, [onChange, name]);

  const getClassName = () => {
    let selectClassName = 'selectField';

    if (required) {
      if (!value || !value.value) {
        selectClassName += ' invalid';
      } else {
        selectClassName += ' valid';
      }
    }

    return selectClassName;
  };

  return (
    <FormElement
      label={needTranslation ? translate(label) : label}
      required={required}
    >
      <Select
        className={getClassName()}
        isClearable={isClearable}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        menuPosition="fixed"
        name={name}
        noOptionsMessage={displayNoOptions}
        options={options}
        placeholder={translate(placeholder)}
        required={required}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 2000 }) }}
        value={value}
        onChange={handleChange}
        {...props}
      />

      {required ? (
        <StyledRequiredSelectHack
          autoComplete="off"
          defaultValue={(value && value.value) || null}
          required
          tabIndex="-1"
        />
      ) : <StyledRequiredSelectHack autoComplete="off" tabIndex="-1" />}
    </FormElement>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
  needTranslation: PropTypes.bool,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  value: PropTypes.oneOfType([
    PropTypes.shape({}),
    PropTypes.arrayOf(PropTypes.shape({}))
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool
};

SelectField.defaultProps = {
  isClearable: true,
  needTranslation: true,
  options: [],
  placeholder: 'common.selectOption',
  required: false,
  value: null
};

export default SelectField;