import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { PageSubtitle, TextError } from 'components';
import { translate } from 'utils';

export const Section = ({
  title, error, children
}) => (
  <Grid container direction="column">
    <Grid item>
      <PageSubtitle subtitle={title} />
      {error && <TextError color="var(--danger-color)">{translate(error)}</TextError>}
    </Grid>
    <Grid item>
      <Grid container direction="column" spacing={3}>
        {children}
      </Grid>
    </Grid>
  </Grid>
);

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.element)
  ]).isRequired,
  error: PropTypes.string,
  title: PropTypes.string.isRequired
};

Section.defaultProps = {
  error: ''
};