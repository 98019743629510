/* eslint-disable indent */
import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import {
  Button, HeaderNav
} from 'components';
import { useModal, useStores } from 'hooks';
import { translate } from 'utils';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-regular-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { ToolsService } from 'services';

export const ToolsListNav = observer(({ getToolsList }) => {
  const showModal = useModal();
  const { referenceDataStore } = useStores();
  const { toolTypes } = referenceDataStore;
  const { enqueueSnackbar } = useSnackbar();

  const createTool = useCallback((form) => {
    if (form.id) {
      return ToolsService.updateTool(form.id, form).then(() => {
        enqueueSnackbar(translate('confirms.toolUpdate'), { variant: 'success' });
        getToolsList();
      }).catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
    }

    return ToolsService.createTool(form).then(() => {
      enqueueSnackbar(translate('confirms.toolCreate'), { variant: 'success' });
      getToolsList();
    }).catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
  }, [enqueueSnackbar, getToolsList]);

  const handleCreateTool = useCallback((row) => showModal({
    type: 'CREATE_TOOL',
    onConfirm: createTool,
    defaultValues: row.model ? row : {},
    reloadList: getToolsList,
    toolTypes
  }), [showModal, createTool, toolTypes, getToolsList]);

  return (
    <HeaderNav hideCollapser isDisplayed={useMediaQuery({ minWidth: 769 })}>
      <Grid item>
        <Button
          data-cy="createTool"
          data-tour="step-admin-toolCreate"
          startIcon={<FontAwesomeIcon icon={faTools} />}
          onClick={handleCreateTool}
        >
          {translate('button.createTool')}
        </Button>
      </Grid>
    </HeaderNav>
  );
});