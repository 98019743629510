import React from 'react';
import PropTypes from 'prop-types';
import { DialogTitle } from '@material-ui/core';
import { CloseButton } from './CloseButton';

export const ModalHeader = ({
  onClose, children, className
}) => (
  <DialogTitle className={className} style={{ paddingRight: '4rem' }}>
    {children}
    <CloseButton onClose={onClose} />
  </DialogTitle>
);

ModalHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string
};

ModalHeader.defaultProps = {
  className: ''
};