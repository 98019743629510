import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Datepicker, SelectField } from 'components';
import { useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Grid } from '@material-ui/core';

export const InspectionListFilters = observer(({ currentFilters, setCurrentFilters }) => {
  const { referenceDataStore } = useStores();
  const { inspectionStatuses, inspectorList } = referenceDataStore;

  const statuses = (currentFilters.find((filt) => filt.key === 'statuses')
    && currentFilters.find((filt) => filt.key === 'statuses').value) || null;
  const inspector = (currentFilters.find((filt) => filt.key === 'inspectorId')
    && currentFilters.find((filt) => filt.key === 'inspectorId').value) || null;
  const fromDate = (currentFilters.find((filt) => filt.key === 'fromDate')
    && currentFilters.find((filt) => filt.key === 'fromDate').value) || null;
  const toDate = (currentFilters.find((filt) => filt.key === 'toDate')
    && currentFilters.find((filt) => filt.key === 'toDate').value) || null;

  const handleChange = useCallback((filterName, value) => {
    if (value) {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName), {
        key: filterName,
        label: value.label || value,
        value
      }]);
    } else {
      setCurrentFilters([...currentFilters.filter((filt) => filt.key !== filterName)]);
    }
  }, [currentFilters, setCurrentFilters]);

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <SelectField
          isMulti
          label="common.status"
          name="statuses"
          options={inspectionStatuses}
          value={statuses}
          onChange={handleChange}
        />
      </Grid>
      {inspectorList && inspectorList.length > 0 && (
        <Grid item>
          <SelectField
            isMulti={false}
            label="common.inspectorName"
            name="inspectorId"
            options={inspectorList}
            value={inspector}
            onChange={handleChange}
          />
        </Grid>
      )}
      <Grid item>
        <Datepicker
          clearable
          label="common.startDay"
          maxDate={toDate || undefined}
          name="fromDate"
          value={fromDate}
          onChange={handleChange}
        />
      </Grid>
      <Grid item>
        <Datepicker
          clearable
          label="common.endDay"
          minDate={fromDate || undefined}
          name="toDate"
          value={toDate}
          onChange={handleChange}
        />
      </Grid>
    </Grid>
  );
});

InspectionListFilters.propTypes = {
  currentFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setCurrentFilters: PropTypes.func.isRequired
};