import {
  action, decorate, observable, runInAction
} from 'mobx';
import { UserAccountService } from 'services';

class ScopeStore {
  constructor() {
    this.isCurrentScopeLoading = true;
    this.isStructureLevelOptionsLoading = true;
    this.currentStructureLevel = {};
    this.currentRoles = [];
    this.structureLevelOptions = [];
  }

  getCurrentScope() {
    return UserAccountService.getCurrentScope()
      .then((resp) => runInAction('loadCurrentScope', () => {
        this.currentStructureLevel = resp.currentStructureLevel;
        this.currentRoles = resp.roles;
      })).finally(() => runInAction('setCurrentScopeLoadingFalse', () => {
        this.isCurrentScopeLoading = false;
      }));
  }

  getStructureLevelOptions() {
    return UserAccountService.getAvailableStructureLevelsOptions()
      .then((resp) => runInAction('loadStructureLevelOptions', () => {
        this.structureLevelOptions = resp;
      })).finally(() => runInAction('setStructureLevelOptionsLoadingFalse', () => {
        this.isStructureLevelOptionsLoading = false;
      }));
  }
}

decorate(ScopeStore, {
  isCurrentScopeLoading: observable,
  isStructureLevelOptionsLoading: observable,
  currentInstitution: observable,
  currentRoles: observable,
  loadCurrentScope: action,
  structureLevelOptions: observable,
  loadStructureLevelOptions: action
});

export const scopeStore = new ScopeStore();