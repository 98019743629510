/* eslint-disable react/no-array-index-key */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, DialogActions, DialogContent, Divider, Grid, Typography
} from '@material-ui/core';
import { DocumentHelper, translate } from 'utils';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { useImportingReducer, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import { observer } from 'mobx-react-lite';
import { ModalHeader } from './_ModalHeader';
import { InputFile } from '../Form';
import { TextError } from '../Text';

export const ImportReportTemplateModal = observer(({
  onClose, inspectionId, inspectionIdentifier
}) => {
  const { inspectionStore } = useStores();
  const { isGeneratingReportFromExcel } = inspectionStore;
  const { enqueueSnackbar } = useSnackbar();
  const [templateImported, setTemplateImported] = useState(null);
  const [state, dispatch] = useImportingReducer();

  const handleImportTemplate = useCallback((doc) => {
    if (doc) {
      setTemplateImported(doc);
      dispatch({ type: 'reset' });
    }
  }, [dispatch]);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    if (!templateImported) return;

    const excel = {
      name: templateImported.name,
      base64Content: DocumentHelper.getDocumentWithoutBase64(templateImported)
    };

    dispatch({ type: 'isImporting' });

    inspectionStore.generateReportPDFFromExcel({ inspectionId, excel })
      .then(({ fileReader, blob }) => {
        fileReader.readAsArrayBuffer(blob);

        // eslint-disable-next-line no-param-reassign
        fileReader.onload = () => {
          DocumentHelper.createDownloadableDocument({
            result: fileReader.result,
            linkTitle: translate('file.inspectionReportFileName', { inspectionIdentifier })
          });

          return onClose();
        };
      })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
  }, [templateImported, inspectionId, inspectionIdentifier, inspectionStore, onClose, dispatch, enqueueSnackbar]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('button.importTemplateExcel')}</ModalHeader>

      <DialogContent>
        {state.error && state.error.message ? (
          <Typography component="div" gutterBottom>
            <TextError style={{ margin: 0, textAlign: 'left' }}>
              {state.error.message}
            </TextError>
          </Typography>
        ) : state.error && Object.keys(state.error).length > 0 && (
          <>
            <div>
              {Object.keys(state.error).map((err, index) => (
                <Typography component="div" gutterBottom key={index}>
                  <TextError style={{ margin: 0, textAlign: 'left' }}>
                    {`${err} : `}
                  </TextError>
                  {state.error[err].map((e, i) => (
                    <Typography component="p" key={i}>{e}</Typography>
                  ))}
                </Typography>
              ))}
            </div>
            <Divider style={{ margin: '2rem 0' }} />
          </>
        )}

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography gutterBottom>
              {translate('pageInspectionDetail.importTemplateExcel')}
            </Typography>
          </Grid>
          <Grid item>
            <InputFile
              document={templateImported}
              fileAccepted=".xlsx"
              handleAddDocument={handleImportTemplate}
              handleDeleteDocument={null}
              id="importTemplate"
              label="button.importTemplateExcel"
              maxSize={100}
              required
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={isGeneratingReportFromExcel} variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!templateImported || isGeneratingReportFromExcel}
          startIcon={(
            <FontAwesomeIcon
              icon={isGeneratingReportFromExcel ? faSpinner : faFileImport}
              spin={isGeneratingReportFromExcel}
            />
          )}
          type="submit"
        >
          {translate('button.import')}
        </Button>
      </DialogActions>
    </form>
  );
});

ImportReportTemplateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  inspectionId: PropTypes.string.isRequired,
  inspectionIdentifier: PropTypes.string.isRequired
};