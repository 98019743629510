import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const StyledText = styled.p`
  ${(props) => props.position && css`position: ${props.position};`}
  ${(props) => props.display && css`display: ${props.display};`}
  ${(props) => props.top && css`top: ${props.top};`}
  ${(props) => props.left && css`left: ${props.left};`}
  ${(props) => props.right && css`right: ${props.right};`}
  ${(props) => props.bottom && css`bottom: ${props.bottom};`}
  ${(props) => props.width && css`width: ${props.width};`}
  ${(props) => props.margin && css`margin: ${props.margin};`}
  ${(props) => props.marginLeft && css`margin-left: ${props.marginLeft};`}
  ${(props) => props.marginRight && css`margin-right: ${props.marginRight};`}
  ${(props) => props.marginTop && css`margin-top: ${props.marginTop};`}
  ${(props) => props.marginBottom && css`margin-bottom: ${props.marginBottom};`}
  ${(props) => props.padding && css`padding: ${props.padding};`}
  ${(props) => props.color && css`color: ${props.color};`}
  ${(props) => props.lineHeight && css`line-height: ${props.lineHeight};`}
  ${(props) => props.fontSize && css`font-size: ${props.fontSize};`}
  ${(props) => props.fontFamily && css`font-family: ${props.fontFamily};`}
  ${(props) => props.fontStyle && css`font-style: ${props.fontStyle};`}
  ${(props) => props.fontWeight && css`font-weight: ${props.fontWeight};`}
  ${(props) => props.textAlign && css`text-align: ${props.textAlign};`}
  ${(props) => props.textDecoration && css`text-decoration: ${props.textDecoration};`}
  ${(props) => props.textTransform && css`text-transform: ${props.textTransform};`}
  ${(props) => props.textIndent && css`text-indent: ${props.textIndent};`}
  ${(props) => props.verticalAlign && css`vertical-align: ${props.verticalAlign};`}
  ${(props) => props.letterSpacing && css`letter-spacing: ${props.letterSpacing};`}
  ${(props) => props.border && css`border: ${props.border};`}
  ${(props) => props.background && css`background: ${props.background};`}
  ${(props) => props.textShadow && css`text-shadow: ${props.textShadow};`}
  ${(props) => props.boxShadow && css`box-shadow: ${props.boxShadow};`}
  ${(props) => props.zindex && css`z-index: ${props.zindex};`}
`;

const StyledTextMultiline = styled(StyledText)`
  white-space: pre-line;
`;

export const TextError = styled.p`
  margin: 3rem 0;
  color: ${(props) => props.color || 'var(--grey-darker)'};
  font-weight: 600;
  text-align: center;
`;

export const Text = ({ children, ...props }) => (
  <StyledText {...props}>{children}</StyledText>
);

Text.defaultProps = {
  children: PropTypes.element.isRequired
};

export const TextMultiline = ({ children, ...props }) => (
  <StyledTextMultiline {...props}>{children}</StyledTextMultiline>
);