/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { translate } from 'utils';
import { formatDate } from 'utils/helpers';
import { Datepicker, ToggleDeclaredValues } from '../../Inputs';
import { DeclaredAndResult } from '../../Question';

const QuestionDate = ({
  name, value = null, questionText, declared, result, placeholder,
  required, onChange, disablePast, disableFuture, maxDate, minDate,
  hasDeclared, hasResult, onChangeResult, initialized, isDeclaredSet = null
}) => {
  const declaredParsed = hasDeclared && declared && formatDate(declared);

  const [declaredValueAccepted, setDeclaredValueAccepted] = useState(isDeclaredSet);

  const displayDeclaredValues = Boolean(initialized && declared && declaredValueAccepted === null);

  return (
    <>
      {displayDeclaredValues && (
        <Grid alignItems="center" container spacing={2}>
          <Grid item>
            <Typography>{translate('pageInspectionForm.declaredValues')}</Typography>
          </Grid>
          <Grid item>
            <ToggleDeclaredValues
              declared={declared}
              declaredValueAccepted={declaredValueAccepted}
              name={name}
              setDeclaredValueAccepted={setDeclaredValueAccepted}
              value={value}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      )}

      <Grid alignItems="center" container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Datepicker
            clearable
            dataCy={name}
            disabled={displayDeclaredValues}
            disableFuture={disableFuture}
            disablePast={disablePast}
            fullWidth
            label={questionText}
            maxDate={maxDate}
            minDate={minDate}
            name={name}
            needTranslation={false}
            placeholder={displayDeclaredValues ? declaredParsed : placeholder}
            required={required}
            value={value}
            onChange={onChange}
          />
        </Grid>
        <DeclaredAndResult
          declared={declaredParsed}
          hasDeclared={hasDeclared}
          hasResult={hasResult}
          result={result}
          onChangeResult={onChangeResult}
        />
      </Grid>
    </>
  );
};

QuestionDate.propTypes = {
  disableFuture: PropTypes.bool,
  disablePast: PropTypes.bool,
  maxDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  minDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ])
};

QuestionDate.defaultProps = {
  disableFuture: false,
  disablePast: false,
  maxDate: new Date('2100-01-01'),
  minDate: new Date('1900-01-01')
};

export default QuestionDate;