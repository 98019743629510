/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import {
  faHistory, faPen, faSearchPlus, faSpinner, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Grid } from '@material-ui/core';
import { IconButton, StyledRequiredSelectHack } from 'components';
import { useModal, useStores } from 'hooks';
import React, {
  useCallback, useEffect, useState
} from 'react';
import styled from 'styled-components';
import { translate } from 'utils';
import { CERTIFICALL_NIE } from 'utils/constants';
import { InputField, InputFile } from '../../Inputs';

const Container = styled.div`
  position: relative;
  width: 320px;
  height: 180px;
  padding: 0;
  margin: 6px 8px;
`;

const StyledImage = styled.img`
  background: rgba(0, 0, 0, 0.7);
  object-fit: contain;
  object-position: center;
  width: 320px;
  height: 180px;
`;

const StyledIcon = styled.div`
  background: rgba(0, 0, 0, 0.7);
  width: 320px;
  height: 180px;

  svg {
    color: white;
    width: 280px !important;
    height: 140px;
    margin: 20px;
  }
`;

const Overlay = styled.div`
  background-color: rgba(0, 0, 0, 0);
  opacity: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;
  transition: .3s ease;
  -webkit-transition: .3s ease;
  -ms-transition: .3s ease;
  text-align: center;
  align-content: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1;
  }
`;

const CertificallImage = styled.img`
  position: absolute;
  bottom: 10px;
  left: 10px;
  height: 20px;
  cursor: pointer;
`;

const QuestionDocument = ({
  certified = false, name, questionKey, value = {}, onChange, required, maxAnswerCount = 1
}) => {
  const { certificallStore } = useStores();
  const { images, comment } = value;
  const [currentImages, setCurrentImages] = useState(images);
  const [comm, setComm] = useState(comment || '');
  const showModal = useModal();

  useEffect(() => {
    onChange(name, {
      images: currentImages,
      comment: comm
    });
    // eslint-disable-next-line
  }, [currentImages, comm]);

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(userAgent)
      // eslint-disable-next-line
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(userAgent.substr(0, 4))
    );
  };

  const openCertificall = (id) => {
    const a = document.createElement('a');
    a.href = `https://app.certificall.app?nie=${CERTIFICALL_NIE}&reportId=${id}`;
    if (!isMobile()) {
      a.setAttribute('target', '_blank');
    }
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const handleAddDocument = useCallback((fileFormatted) => {
    if (fileFormatted.certificallId) {
      // Open CertifiCall
      openCertificall(fileFormatted.certificallId);
      // Start the sync
      certificallStore.addImage({
        ...fileFormatted,
        questionKey
      });
    }
    setCurrentImages([
      ...currentImages,
      fileFormatted
    ]);
    // eslint-disable-next-line
  }, [currentImages, certificallStore, questionKey]);

  const handleDeleteDocument = useCallback((index) => {
    showModal({
      type: 'WARNING',
      title: 'warning.deleteImage.title',
      text: translate('warning.deleteImage.text'),
      onConfirm: () => {
        const newImages = [...currentImages];
        newImages.splice(index, 1);
        setCurrentImages(newImages);
      }
    });
  }, [showModal, currentImages]);

  const handleChangeComment = useCallback((_, inputVal) => setComm(inputVal), []);

  const handleOpenImage = useCallback((image) => {
    showModal({
      type: 'DEFAULT',
      title: image.name || translate('common.defaultModal'),
      content: (
        <img
          alt={image.name}
          src={image.editedBase64Content ?? image.base64Content}
        />
      ),
      fullscreen: true
    });
  },
  [showModal]);

  const handleOpenCertificate = useCallback((image) => {
    window.open(image.certificateUrl, '_blank').focus();
  }, []);

  const handleEditImage = useCallback((image, index) => {
    showModal({
      type: 'EDIT_IMAGE',
      image: {
        ...image,
        base64Content: image.editedBase64Content ?? image.base64Content
      },
      onConfirm: (newImage) => {
        const newImages = [...currentImages];
        newImages[index] = {
          ...newImages[index],
          editedBase64Content: newImage.base64Content
        };
        setCurrentImages(newImages);
      },
      fullscreen: true
    });
  }, [showModal, currentImages, setCurrentImages]);

  const cancelEditImage = useCallback((index) => {
    showModal({
      type: 'WARNING',
      title: 'warning.cancelEditImage.title',
      text: translate('warning.cancelEditImage.text'),
      onConfirm: () => {
        const newImages = [...currentImages];
        newImages[index] = {
          ...newImages[index],
          editedBase64Content: null
        };
        setCurrentImages(newImages);
      }
    });
  }, [showModal, currentImages, setCurrentImages]);

  return (
    <Grid
      container
      direction="column"
      id={name}
      spacing={2}
    >
      <Grid item>
        <Grid container direction="column">
          <Grid item>
            <InputFile
              certified={certified}
              disabled={currentImages.length >= maxAnswerCount}
              fileAccepted=".jpg, .jpeg, .png"
              handleAddDocument={handleAddDocument}
              id={`${name}_image_default`}
              labelButton="forms.inputs.selectImage"
              needResize
              required={required}
            />
            {required ? (
              <StyledRequiredSelectHack
                autoComplete="off"
                required
                tabIndex={-1}
              />
            ) : <StyledRequiredSelectHack />}
          </Grid>
          <Grid item>
            <Divider style={{ height: '2px', margin: '1rem 0' }} />
          </Grid>
        </Grid>
      </Grid>

      {currentImages.length > 0 && (
        <Grid item>
          <Grid
            container
            direction="row"
            style={{
              maxHeight: '300px', flexWrap: 'nowrap', overflowX: 'auto', overflowY: 'hidden'
            }}
          >
            {currentImages.map((image, index) => (
              <Grid item key={`${name}_image_${index}`} style={{ maxWidth: 'none', order: -index }}>
                <Grid container direction="column">
                  <Grid item>
                    <Container>
                      {(image.editedBase64Content || image.base64Content) ? (
                        <>
                          <StyledImage
                            alt={image.name}
                            src={image.editedBase64Content ?? image.base64Content}
                          />
                          <Overlay onClick={() => handleOpenImage(image)}>
                            <FontAwesomeIcon color="white" icon={faSearchPlus} size="3x" />
                          </Overlay>
                          {image.certificateUrl && (
                            <CertificallImage
                              alt="Certificall logo"
                              src={`${process.env.PUBLIC_URL}/assets/images/icons/certificall_logo.png`}
                              onClick={() => handleOpenCertificate(image)}
                            />
                          )}
                        </>
                      ) : (
                        <StyledIcon>
                          <FontAwesomeIcon icon={faSpinner} spin />
                        </StyledIcon>
                      )}
                    </Container>
                  </Grid>
                  <Grid item>
                    <Grid container direction="row" justifyContent="flex-end">
                      {image.editedBase64Content && (
                        <IconButton type="primary" onClick={() => cancelEditImage(index)}>
                          <FontAwesomeIcon icon={faHistory} />
                        </IconButton>
                      )}
                      {image.base64Content && (
                        <IconButton type="primary" onClick={() => handleEditImage(image, index)}>
                          <FontAwesomeIcon icon={faPen} />
                        </IconButton>
                      )}
                      <IconButton type="danger" onClick={() => handleDeleteDocument(index)}>
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}

      <Grid item sm={6} xs={12}>
        <InputField
          label={translate('forms.inputs.commentDoc')}
          minRows={4}
          multiline
          name="comment"
          value={comm}
          onChange={handleChangeComment}
        />
      </Grid>
    </Grid>
  );
};

export default QuestionDocument;