import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getUserAccounts = () => RequestHelper.GET(API_ROUTES.USER_ACCOUNTS());

const getUserAccountDetail = (userAccountHashId) => RequestHelper.GET(API_ROUTES.USER_ACCOUNTS_DETAIL(userAccountHashId));

const getCurrentScope = () => RequestHelper.GET(API_ROUTES.CURRENT_SCOPE());

const getAvailableStructureLevelsOptions = () => RequestHelper.GET(API_ROUTES.CURRENT_STRUCTURE_LEVEL_OPTIONS());

const resetPassword = () => RequestHelper.GET(API_ROUTES.RESET_PASSWORD());

// PUT
const updateLocale = (locale) => RequestHelper.PUT(API_ROUTES.UPDATE_LOCALE(locale));

const updateCurrentInstitution = (institution) => RequestHelper.PUT(API_ROUTES.CURRENT_SCOPE(), institution);

export const UserAccountService = {
  getUserAccounts,
  getUserAccountDetail,
  updateLocale,
  getCurrentScope,
  getAvailableStructureLevelsOptions,
  updateCurrentInstitution,
  resetPassword
};