import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export const frenchLocale = 'fr_FR';

export const i18next = i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false, // For development mode
    resources: {},
    keySeparator: '.', // to have multiple level of translations

    interpolation: {
      escapeValue: false
    }
  });