import {
  action, decorate, observable, runInAction
} from 'mobx';
import { InspectionService, ReferenceDataService } from 'services';
import { REF_DATA_MULTIPLE_MAP } from 'utils/constants';
import { referenceDataFixture } from '__fixtures__';

class ReferenceDataStore {
  constructor() {
    this.referenceData = null || referenceDataFixture;
    this.inspectionStatuses = [];
    this.inspectionFormResultOptions = [];
    this.inspectorList = [];
    this.toolTypes = [];
  }

  loadReferenceData() {
    return ReferenceDataService.getReferenceData()
      .then((refData) => runInAction('LoadReferenceData', () => {
        const allRefData = refData;
        Object.values(refData).forEach((refD) => {
          allRefData[refD.code] = {
            ...refD,
            multiple: REF_DATA_MULTIPLE_MAP[refD.code]
          };
        });

        this.referenceData = allRefData;
      }))
      .catch((err) => { throw err; });
  }

  loadInpectionStatuses() {
    return InspectionService.getInspectionStatuses()
      .then((resp) => runInAction('LoadInpectionStatuses', () => {
        this.inspectionStatuses = resp;
        return this.inspectionStatuses;
      }))
      .catch((err) => { throw err; });
  }

  loadInpectionFormResultOptions() {
    return InspectionService.getInpectionFormResultOptions()
      .then((resp) => runInAction('LoadInpectionFormResultOptions', () => {
        this.inspectionFormResultOptions = resp.map((result) => ({
          value: result.value,
          label: result.label
        }));
      }))
      .catch((err) => { throw err; });
  }

  loadInspectorList() {
    return ReferenceDataService.getInspectorList()
      .then((resp) => runInAction('LoadInspectorList', () => {
        this.inspectorList = resp;
        return this.inspectorList;
      }))
      .catch((err) => { throw err; });
  }

  loadToolTypes() {
    return ReferenceDataService.getToolTypes()
      .then((resp) => runInAction('LoadInspectorList', () => {
        this.toolTypes = resp.map((type) => ({
          ...type
        }));
      }))
      .catch((err) => { throw err; });
  }

  getRefData(key) {
    return this.referenceData && this.referenceData[key] ? this.referenceData[key] : {};
  }
}

decorate(ReferenceDataStore, {
  referenceData: observable,
  inspectionStatuses: observable,
  inspectionFormResultOptions: observable,
  inspectorList: observable,
  toolTypes: observable,
  loadInpectionStatuses: action,
  loadReferenceData: action,
  loadInpectionFormResultOptions: action,
  loadInspectorList: action,
  loadToolTypes: action,
  getRefData: action
});

export const referenceDataStore = new ReferenceDataStore();