import { Grid, Button as MaterialButton, IconButton as MaterialIconButton } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyledMaterialButton = styled(
  ({
    children, type, margin, ...props
  }) => <MaterialButton {...props} color={type === 'danger' ? 'default' : type}>{children}</MaterialButton>
)`
  ${(props) => props.margin && css`
    margin: ${props.margin} !important;
  `}

  ${(props) => props.type === 'danger' && css`
    color: var(--white) !important;
    background-color: var(--delete-color) !important;
  `}

  &:hover {
    ${(props) => props.type === 'danger' && css`
      color: var(--delete-color);
    `}
  }
`;

const StyledMaterialIconButton = styled(
  ({
    children, type, ...props
  }) => <MaterialIconButton {...props} color={type === 'danger' ? 'default' : type}>{children}</MaterialIconButton>
)`
  &:hover {
    ${(props) => props.type === 'danger' && css`
      color: var(--delete-color);
    `}
  }
`;

export const Button = ({
  children, ...props
}) => (
  <StyledMaterialButton {...props}>
    {children}
  </StyledMaterialButton>
);

export const IconButton = ({
  children, ...props
}) => (
  <StyledMaterialIconButton {...props}>
    {children}
  </StyledMaterialIconButton>
);

export const ButtonBigIcon = ({
  label, icon, onClick
}) => (
  <MaterialButton
    color="primary"
    style={{ padding: '1rem 2rem' }}
    variant="text"
    onClick={onClick}
  >
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <FontAwesomeIcon icon={icon} size="3x" />
      </Grid>
      <Grid item>
        {translate(label)}
      </Grid>
    </Grid>
  </MaterialButton>
);

Button.propTypes = {
  type: PropTypes.string,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  variant: PropTypes.string
};

Button.defaultProps = {
  disabled: false,
  margin: '',
  type: 'primary',
  variant: 'contained'
};