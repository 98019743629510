import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { FullWrapper } from 'components';
import { useAccessRights } from 'hooks';
import React from 'react';
import { NavLink } from 'react-router-dom';
import shortid from 'shortid';
import styled from 'styled-components';
import { translate, UserHelper } from 'utils';
import { MENU_ITEMS } from 'utils/constants';

const StyledHeaderMenu = styled.nav`
  position: relative;
  height: var(--header-height);
  background: var(--white);
  border-bottom: 1px solid var(--grey-light);
  z-index: var(--zindexBig);

  @media (max-width: 560px) {
    display: none;
  }
`;

const StyledLink = styled(NavLink)`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 2rem 3rem;
  color: var(--grey-darker);
  text-transform: uppercase;

  &.active,
  &:hover {
    color: var(--tecnea-blue);
    text-decoration: none;

    &:after {
      content: '';
      position: absolute;
      bottom: 2px;
      left: 0;
      right: 0;
      height: 4px;
      background: var(--tecnea-blue);
    }
  }

  svg {
    margin-right: 1rem;
  }
`;

const MenuItem = ({ item }) => (
  <Grid item key={shortid.generate()}>
    <StyledLink activeClassName="active" data-link={item.url} to={item.url}>
      <FontAwesomeIcon icon={item.icon} size="lg" />
      {translate(item.title)}
    </StyledLink>
  </Grid>
);

export const HeaderMenu = ({ isConnected }) => {
  const hasAccessRights = useAccessRights({ checkOffline: true });

  return (
    <StyledHeaderMenu>
      <FullWrapper>
        <Grid alignItems="center" container justifyContent="flex-end">
          {hasAccessRights && (
            <Grid item>
              <Grid container>
                {/* <Grid item>
                <StyledLink activeClassName="active" data-link={MENU_HOME.url} to={MENU_HOME.url}>
                <FontAwesomeIcon icon={MENU_HOME.icon} size="lg" />
                {translate(MENU_HOME.title)}
                </StyledLink>
              </Grid> */}
                {isConnected
                  ? MENU_ITEMS.map((item) => UserHelper.hasAccessRight(item.accessRights) && (
                    <MenuItem item={item} key={item.title} />
                  ))
                  : MENU_ITEMS
                    .filter((item) => item.title === 'menu.inspections')
                    .map((item) => <MenuItem item={item} key={item.title} />)}
              </Grid>
            </Grid>
          )}
        </Grid>
      </FullWrapper>
    </StyledHeaderMenu>
  );
};