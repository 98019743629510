import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { GeolocationService } from 'services';

import { IconButton, InputFile } from 'components';
import { DocumentHelper, translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPenSquare, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { useModal } from 'hooks';

const QuestionGPS = ({ value, onChange }) => {
  const { base64Content, name: imgName } = value;
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();
  const [fileUploaded, setFileUploaded] = useState(imgName ? { name: imgName, base64Content } : null);
  const [geolocStart, setGeolocStart] = useState(!imgName ? base64Content : null);
  const [currentPosition, setCurrentPosition] = useState(null);

  const getCommentFormatted = useCallback((pos) => `${pos.latitude},${pos.longitude}`, []);

  const updateQuestion = useCallback(({ image64, name = '', comment }) => {
    const today = new Date();
    const measuredValue = {
      base64Content: image64,
      name,
      comment,
      creationDate: today.toISOString(),
      extension: '.png'
    };

    onChange(null, measuredValue);
  }, [onChange]);

  const getImageGPS = useCallback(() => {
    if (!fileUploaded) {
      GeolocationService.getGeoportailSatelliteImage()
        .then(({ image, position }) => {
          setCurrentPosition(position);
          DocumentHelper.resizeImage({ result: image })
            .then((image64) => {
              setGeolocStart(image64);
              updateQuestion({
                image64,
                name: 'Image générée par Google Maps',
                comment: getCommentFormatted(position)
              });
            });
        })
        .catch(() => enqueueSnackbar(translate('forms.errors.enableGPS'), { variant: 'error' }));
    }
  }, [fileUploaded, updateQuestion, getCommentFormatted, enqueueSnackbar]);

  useEffect(() => {
    base64Content === null && getImageGPS();
  }, [base64Content, getImageGPS]);

  const handleAddDocument = useCallback((fileFormatted) => {
    setFileUploaded(fileFormatted);

    if (!currentPosition) {
      GeolocationService.getPosition()
        .then((position) => {
          setCurrentPosition(position);
          updateQuestion({
            image64: fileFormatted.base64Content,
            name: fileFormatted.name,
            comment: getCommentFormatted(position)
          });
        })
        .catch(() => enqueueSnackbar(translate('forms.errors.enableGPS'), { variant: 'error' }));
    } else {
      updateQuestion({
        image64: fileFormatted.base64Content,
        name: fileFormatted.name,
        comment: getCommentFormatted(currentPosition)
      });
    }
  }, [currentPosition, getCommentFormatted, updateQuestion, enqueueSnackbar]);

  const handleEditImage = useCallback(() => {
    showModal({
      type: 'EDIT_IMAGE',
      image: fileUploaded || {
        base64Content: geolocStart,
        name: 'Image générée par Google Maps'
      },
      onConfirm: (newImage) => {
        if (fileUploaded) {
          setFileUploaded(newImage);
        } else if (geolocStart) {
          setGeolocStart(newImage.base64Content);
        }

        if (!currentPosition) {
          GeolocationService.getPosition()
            .then((position) => {
              setCurrentPosition(position);
              updateQuestion({
                image64: newImage.base64Content,
                name: newImage.name || '',
                comment: getCommentFormatted(position)
              });
            })
            .catch(() => enqueueSnackbar(translate('forms.errors.enableGPS'), { variant: 'error' }));
        } else {
          updateQuestion({
            image64: newImage.base64Content,
            name: newImage.name || '',
            comment: getCommentFormatted(currentPosition)
          });
        }
      },
      fullscreen: true
    });
  }, [showModal, fileUploaded, geolocStart, getCommentFormatted,
    enqueueSnackbar, currentPosition, updateQuestion]);

  const handleDeleteDocument = useCallback(() => {
    setGeolocStart(null);
    setFileUploaded(null);

    onChange(null, {});
  }, [onChange]);

  return (
    <Grid container direction="column" spacing={2}>
      {!geolocStart && !fileUploaded && (
        <>
          <Grid item>
            <Button
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faMapMarkerAlt} />}
              variant="contained"
              onClick={getImageGPS}
            >
              {translate('button.getImageGPS')}
            </Button>
          </Grid>

          <Grid item sm={6} xs={12}>
            <InputFile
              document={fileUploaded}
              fileAccepted=".jpg, .jpeg, .png"
              handleAddDocument={handleAddDocument}
              id="questionGPS"
              label="forms.inputs.inputImage"
              labelButton="forms.inputs.selectImage"
              needResize
            />
          </Grid>
        </>
      )}

      {(geolocStart || fileUploaded) && (
        <>
          <Grid item>
            <Grid alignItems="flex-start" container spacing={2}>
              <Grid item>
                {geolocStart && (
                  <img
                    alt="Générée par Google Maps"
                    src={geolocStart}
                  />
                )}

                {fileUploaded && (
                  <img
                    alt={fileUploaded.name}
                    src={fileUploaded.base64Content}
                  />
                )}
              </Grid>

              <Grid item>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Button
                      color="secondary"
                      startIcon={<FontAwesomeIcon icon={faPenSquare} />}
                      onClick={handleEditImage}
                    >
                      {translate('common.editImage')}
                    </Button>
                  </Grid>

                  <Grid item>
                    <IconButton type="danger" onClick={handleDeleteDocument}>
                      <FontAwesomeIcon icon={faTrashAlt} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default QuestionGPS;