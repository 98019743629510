import { faBuilding, faSitemap, faStoreAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, DialogActions, DialogContent
} from '@material-ui/core';
import { ModalHeader } from 'components/_commons/Modals/_ModalHeader';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';
import { useFormState } from 'react-use-form-state';
import sanitizeHtml from 'sanitize-html';
import { translate } from 'utils';

export const UpdateUserInstitutionModal = ({
  onClose, onConfirm, defaultValue, structureLevelOptions, isStructureLevelOptionsLoading
}) => {
  const [formState] = useFormState({
    institution: structureLevelOptions.find((insti) => insti.value === defaultValue)
  });

  const formatStructureLevelOption = (structureLevel, metaData) => {
    const icon = {
      INSTITUTION_MODEL: faBuilding,
      COMPANY: faStoreAlt,
      INSTITUTION_GROUP: faSitemap
    }[structureLevel.type];
    const isMenu = metaData.context === 'menu';
    const iconColor = metaData.selectValue.includes(structureLevel) ? 'var(--tecnea-blue-lighter)' : 'var(--tecnea-blue)';
    const indent = '&nbsp;'.repeat(structureLevel.indentNumber * 6);
    const name = structureLevel.type === 'COMPANY' ? `<b>${structureLevel.label}</b>` : structureLevel.label;
    const label = isMenu ? name : structureLevel.label;
    return isMenu ? (
      <>
        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(indent) }} />
        <FontAwesomeIcon color={iconColor} fixedWidth icon={icon} size="sm" style={{ marginRight: 10 }} />
        <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }} />
      </>
    ) : <span dangerouslySetInnerHTML={{ __html: sanitizeHtml(label) }} />;
  };

  const handleValidateModal = (e) => {
    e.preventDefault();
    onConfirm({ institution: formState.values.institution });
    onClose();
  };

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>
        <FontAwesomeIcon icon={faBuilding} />
        {translate('modalInstitutionUpdate.title')}
      </ModalHeader>

      <DialogContent>
        <Select
          formatOptionLabel={formatStructureLevelOption}
          id="selectInstitution"
          isClearable
          isLoading={isStructureLevelOptionsLoading}
          isSearchable
          menuPlacement="bottom"
          menuPortalTarget={document.body}
          noOptionsMessage={() => translate('common.noOptions')}
          options={structureLevelOptions}
          placeholder={translate('common.searchByInstitution')}
          required
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 2000 }), container: () => ({ minWidth: 500 }) }}
          value={formState.values.institution || ''}
          onChange={(value) => formState.setField('institution', value)}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>{translate('button.cancel')}</Button>
        <Button color="primary" type="submit">{translate('button.save')}</Button>
      </DialogActions>
    </form>
  );
};

UpdateUserInstitutionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({})
};

UpdateUserInstitutionModal.defaultProps = {
  defaultValues: {}
};