/* eslint-disable react/jsx-no-bind */
import { faIdCard, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { Card, CardContent, Grid } from '@material-ui/core';
import {
  Button, InfoLine, PageTitle, SkeletonDetailUser, SkeletonNoResult, TextAddress, Wrapper
} from 'components';
import { CustomCardTitle } from 'components/Inspection/InspectionDetail/InspectionDetail';

import { useModal, useStores } from 'hooks';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router';
import { UserAccountService } from 'services';
import styled from 'styled-components';
import { KeyCloakUtils, translate } from 'utils';

import { PATHS } from 'utils/constants';
import { formatDate } from 'utils/helpers';

// const listHeadersTable = (handleDeleteTool) => [
//   {
//     name: 'model',
//     label: 'common.model',
//     template: (row) => (
//       <TableCell key={shortid.generate()}>
//         {row.model || '-'}
//       </TableCell>
//     )
//   }, {
//     name: 'serialNumber',
//     label: 'common.serialNumber',
//     template: (row) => (
//       <TableCell key={shortid.generate()}>
//         {row.serialNumber}
//       </TableCell>
//     )
//   }, {
//     name: 'lastCheckupDate',
//     label: 'common.lastCheckupDate',
//     template: (row) => (
//       <TableCell key={shortid.generate()}>
//         <FontAwesomeIcon icon={faCalendarAlt} style={{ marginRight: '0.5rem' }} />
//         {(row.lastCheckupDate && `${formatDate(row.lastCheckupDate)}`) || '-'}
//       </TableCell>
//     )
//   }, {
//     name: 'picture',
//     label: 'common.picture',
//     template: (row) => (
//       <TableCell key={shortid.generate()}>
//         {row.documents && row.documents.length > 0 ? (
//           <img
//             alt={row.identifier}
//             src={DocumentHelper.getDocumentWithBase64(row.documents[0].base64Content)}
//             style={{ maxHeight: '80px' }}
//           />
//         ) : '-'}
//       </TableCell>
//     )
//   }, {
//     name: 'validated',
//     label: 'common.validated',
//     template: (row) => (
//       <TableCell key={shortid.generate()}>
//         <FontAwesomeIcon
//           color={row.validated ? 'var(--primary-color)' : 'var(--danger-color)'}
//           icon={row.validated ? faCheck : faTimes}
//         />
//       </TableCell>
//     )
//   }, {
//     name: 'actions',
//     label: 'common.actions',
//     template: (row) => (
//       <TableCell key={shortid.generate()}>
//         <Tooltip aria-label={translate('button.deleteTool')} title={translate('button.deleteTool')}>
//           <IconButton onClick={() => handleDeleteTool(row)}>
//             <FontAwesomeIcon color="var(--danger-color)" icon={faTrashAlt} />
//           </IconButton>
//         </Tooltip>
//       </TableCell>
//     )
//   }
// ];

const StyledUserDetailHeader = styled.header`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 2rem 0 1rem 0;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    color: var(--white);
    font-size: 2.6rem;
    border-radius: 50%;
    border: 2px solid var(--tecnea-blue-dark);
    background-color: var(--tecnea-blue);
  }

  button {
    margin-left: 2rem;
  }
`;

const PageProfile = () => {
  const { userAccountHashId } = useParams();
  const { userStore } = useStores();
  const { userConnected, isConnected } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();
  // const showModal = useModal();

  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [isLoadingTools, setIsLoadingTools] = useState(false);
  // const [toolList, setToolList] = useState([]);
  //
  // const getToolList = useCallback((inspector) => {
  //   if (userConnected) {
  //     setIsLoadingTools(true);
  //
  //     ToolsService.getToolList(userConnected.tecnea.institutionHashId)
  //       .then((response) => {
  //         setToolList(response
  //           .filter((tool) => !inspector.tools.find((inspTool) => inspTool.id === tool.id))
  //           .map((tool) => ({ value: tool.id, label: tool.identifier })));
  //       })
  //       .catch((error) => enqueueSnackbar(translate(error.message), { variant: 'error' }))
  //       .finally(() => setIsLoadingTools(false));
  //   }
  // }, [userConnected, enqueueSnackbar]);
  const keycloak = KeyCloakUtils.currentKeycloakInstance();

  const resetPassword = useCallback(() => UserAccountService.resetPassword()
    .then(() => {
      keycloak.logout();
    })
    .catch((err) => {
      enqueueSnackbar((err && err.message) || err, { variant: 'error' });
    }), [enqueueSnackbar, keycloak]);

  const displayModalUpdatePassword = useCallback(
    () => showModal({
      type: 'WARNING',
      title: 'userDetails.action.updatePassword',
      text: translate('userDetails.confirmation.updatePassword'),
      onConfirm: () => resetPassword(),
      buttonCancel: 'button.cancel',
      buttonConfirm: 'button.confirm',
      confirmOnly: true
    }),
    [resetPassword, showModal]
  );

  const getUserAccountDetail = useCallback(() => {
    UserAccountService.getUserAccountDetail(userAccountHashId)
      .then((resp) => setProfile(resp))
      .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }))
      .finally(() => setIsLoading(false));
  }, [userAccountHashId, enqueueSnackbar]);

  useEffect(() => {
    !userConnected && setIsLoading(false);

    if (userConnected) {
      getUserAccountDetail();
    }
  }, [userConnected, getUserAccountDetail]);

  // const handleAddToolForInspector = useCallback((_, tool) => {
  //   ToolsService.addToolForInspector(tool.value, profile.inspector.id)
  //     .then(() => {
  //       getUserAccountDetail();
  //       enqueueSnackbar(translate('confirms.toolAdded'), { variant: 'success' });
  //     })
  //     .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  // }, [getUserAccountDetail, profile, enqueueSnackbar]);
  //
  // const handleDeleteTool = useCallback((tool) => {
  //   showModal({
  //     type: 'WARNING',
  //     buttonConfirm: 'button.deleteTool',
  //     text: translate('warnings.deleteTool'),
  //     onConfirm: () => {
  //       ToolsService.removeToolForInspector(tool.id, profile.inspector.id)
  //         .then(() => {
  //           getUserAccountDetail();
  //           enqueueSnackbar(translate('confirms.toolRemoved'), { variant: 'success' });
  //         })
  //         .catch((err) => enqueueSnackbar(err.message, { variant: 'error' }));
  //     }
  //   });
  // }, [showModal, getUserAccountDetail, profile, enqueueSnackbar]);

  if (isLoading) return <SkeletonDetailUser />;
  if (!isConnected) return <Redirect to={PATHS.HOME} />;

  if (!profile || !profile.person) {
    return (
      <SkeletonNoResult error="errors.noProfile" />
    );
  }

  const {
    creationDate,
    person: {
      firstName, lastName, occupation, address, companyName, institution, contact
    } = {}
  } = profile;
  const userFullName = `${firstName} ${lastName}`;

  return (
    <Wrapper>
      <StyledUserDetailHeader>
        <span>{`${firstName.charAt(0)}${lastName.charAt(0)}`}</span>
        <PageTitle data-cy="userDetailTitle" title={userFullName} />
      </StyledUserDetailHeader>

      <Grid container spacing={4} wrap="wrap">
        <Grid item sm={6} xs={12}>
          <Card style={{ height: '100%' }}>
            <CustomCardTitle icon={faUserCircle} title="pageUserDetail.myInfos" />
            <CardContent>
              <InfoLine info={occupation} label="common.occupation" />
              <InfoLine info={companyName} label="common.company" />
              <InfoLine info={institution} label="common.institution" />
              <InfoLine
                displayIf={creationDate}
                info={formatDate(creationDate)}
                label="common.creationDate"
              />
              <InfoLine
                displayIf={address && address.city}
                info={<TextAddress address={address} />}
                label="common.address"
              />
            </CardContent>
          </Card>
        </Grid>

        {contact && (
          <Grid item sm={6} xs={12}>
            <Card style={{ height: '100%' }}>
              <CustomCardTitle icon={faIdCard} title="common.contact" />
              <CardContent>
                <InfoLine
                  displayIf={contact.email}
                  info={contact.email}
                  label="common.email"
                  type="email"
                />
                <InfoLine
                  displayIf={contact.phoneNumber}
                  info={contact.phoneNumber}
                  label="common.phone"
                  type="phone"
                />
              </CardContent>
            </Card>
          </Grid>
        )}
        <Grid item>
          <Button onClick={displayModalUpdatePassword}>
            {translate('button.userDetails.updatePassword')}
          </Button>
        </Grid>

        {
          // profile.inspector && (
          //   <Grid item sm={12} style={{ maxWidth: '100%' }}>
          //     <Card style={{ height: '100%' }}>
          //       <CustomCardTitle
          //         icon={faToolbox}
          //         item={(
          //           <SelectField
          //             label="pageAdminTools.title"
          //             name="tools"
          //             options={toolList}
          //             placeholder="button.addTool"
          //             onChange={handleAddToolForInspector}
          //           />
          //         )}
          //         title="pageUserDetail.myTools"
          //       />
          //       <CardContent>
          //         {profile.inspector.tools && (profile.inspector.tools.length === 0 && !isLoadingTools) ? (
          //           <SkeletonNoResult error="errors.noToolsAvailable" />
          //         ) : (
          //           <GenericTable
          //             headers={listHeadersTable(handleDeleteTool)}
          //             isLoading={isLoadingTools}
          //             rows={profile.inspector.tools}
          //             total={profile.inspector.tools.length}
          //           />
          //         )}
          //       </CardContent>
          //     </Card>
          //   </Grid>
          // )
        }

      </Grid>
    </Wrapper>
  );
};

export default PageProfile;