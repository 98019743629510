import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const filterInspections = (campaignId, filter) => RequestHelper.POST(API_ROUTES.CUSTOMER_INSPECTIONS(campaignId), filter);

// POST
const filterCustomerCampaigns = ({
  filter = {}, page = 0
}) => RequestHelper.POST(API_ROUTES.CUSTOMER_CAMPAIGNS(page), filter);

const filterCampaignInspectionReports = (campaignId, filter) => RequestHelper.POST(API_ROUTES.CUSTOMER_INSPECTION_REPORTS(campaignId), filter);

export const CustomerService = {
  filterInspections,
  filterCustomerCampaigns,
  filterCampaignInspectionReports
};