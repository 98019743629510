import { CookiesModal } from 'components/_commons/Modals/CookiesModal';
import React, { useCallback, useEffect } from 'react';

import { Dialog, Slide } from '@material-ui/core';
import {
  BookAppointmentModal, BrowserPopupModal, ChangeLanguageModal,
  ConfirmModal, CreateToolModal, DefaultModal, DisplayPDFModal,
  ErrorModal,
  ExportFormModal,
  ImageEditorModal, ImportCampaignModal, ImportFileModal,
  // eslint-disable-next-line import/named
  ImportFormModal, ImportInspectionsModal,
  ImportReportTemplateModal, ImportTemplateModal, InspectionHistoricModal, ReassignInspectorModal, SignatureModal,
  SubmitInspectionModal, UpdateUserInstitutionModal, ValidateInspectionModal, WarningModal
} from 'components/_commons/Modals';
import PropTypes from 'prop-types';

const MODAL_TYPES = {
  BOOK_APPOINTMENT: BookAppointmentModal,
  BROWSER_POPUP: BrowserPopupModal,
  DEFAULT: DefaultModal,
  CHANGE_LANGUAGE: ChangeLanguageModal,
  CONFIRM: ConfirmModal,
  CREATE_TOOL: CreateToolModal,
  EDIT_IMAGE: ImageEditorModal,
  ERROR: ErrorModal,
  WARNING: WarningModal,
  DISPLAY_PDF: DisplayPDFModal,
  CHANGE_INSTITUTION: UpdateUserInstitutionModal,
  SIGNATURE: SignatureModal,
  IMPORT_CAMPAIGN: ImportCampaignModal,
  IMPORT_INSPECTIONS: ImportInspectionsModal,
  IMPORT_TEMPLATE: ImportTemplateModal,
  IMPORT_FORM: ImportFormModal,
  EXPORT_FORM: ExportFormModal,
  IMPORT_FILE: ImportFileModal,
  IMPORT_TEMPLATE_REPORT_EXCEL: ImportReportTemplateModal,
  INSPECTION_HISTORIC: InspectionHistoricModal,
  SUBMIT_INSPECTION: SubmitInspectionModal,
  REASSIGN_INSPECTOR: ReassignInspectorModal,
  VALIDATE_INSPECTION: ValidateInspectionModal,
  COOKIES_MODAL: CookiesModal
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const Modal = ({
  onClose, onSubmit, open, type, disableClose, fullscreen, ...rest
}) => {
  const CurrentModal = MODAL_TYPES[type];
  const isFullscreen = fullscreen || document.getElementsByTagName('body')[0].clientWidth <= 768;

  const escFunction = useCallback((event) => {
    if (event.key === 'Escape') {
      onClose(event);
    }
  }, [onClose]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  if (!CurrentModal) return null;

  return (
    <Dialog
      disableEscapeKeyDown={disableClose}
      fullScreen={isFullscreen}
      maxWidth="lg"
      open={open}
      TransitionComponent={Transition}
      transitionDuration={300}
      onClose={(event, reason) => {
        // disableBackdropClick is now deprecated, onClose reason needs to be used
        if (disableClose && reason === 'backdropClick') {
          return;
        }
        onClose(event, reason);
      }}
    >
      <CurrentModal
        onClose={onClose}
        onSubmit={onSubmit}
        {...rest}
      />
    </Dialog>
  );
};

Modal.propTypes = {
  disableClose: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  open: PropTypes.bool,
  type: PropTypes.string
};

Modal.defaultProps = {
  disableClose: true,
  onClose: () => { },
  onSubmit: () => { },
  open: false,
  type: 'CONFIRM'
};

export default Modal;