/* eslint-disable react/jsx-no-bind */
import { useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect, useState } from 'react';
import { FormHelper, translate } from 'utils';
import { toJS } from 'mobx';
import {
  Button, LargeWrapper, PageTitle, Question,
  Section, SkeletonFormDocument, TextError
} from 'components';
import { useHistory, useParams } from 'react-router-dom';
import {
  PATHS, ROUTES, VALID
} from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft, faChevronRight, faClipboardCheck, faShareSquare, faSignOutAlt, faSpinner
} from '@fortawesome/pro-regular-svg-icons';
import {
  Grid, Step, StepLabel, Stepper, Tooltip, Typography
} from '@material-ui/core';
import { InspectionFormNav } from './InspectionFormNav';

const InspectionForm = observer(() => {
  const history = useHistory();
  const showModal = useModal();
  const { folderId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { inspectionStore, userStore } = useStores();
  const {
    isLoading, inspection, inspectionForm, inspectionId, isSubmiting
  } = inspectionStore;
  const { isOffline } = userStore;

  const [formCurrentStep, setFormCurrentStep] = useState(0);
  const [formValidationState, setFormValidationState] = useState({});

  const setStepAndScroll = useCallback((step = 0) => {
    if (inspectionForm) {
      setFormCurrentStep(step);

      // Scroll the stepper to the correct step
      const stepper = document.getElementById('inspectionFormStepper');
      const oneStepWidth = Number(stepper.scrollWidth / inspectionForm.surveySections.length);
      stepper.scrollTo({
        left: oneStepWidth * (step - 1),
        behavior: 'smooth'
      });

      // Scroll to the top of the page
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [inspectionForm]);

  useEffect(() => {
    inspectionStore.getInspectionFormCurrentStep().then((step) => {
      setStepAndScroll(step);
    });
  }, [inspectionStore, setStepAndScroll]);

  useEffect(() => {
    formCurrentStep != null && inspectionStore.saveInspectionFormCurrentStep({ step: formCurrentStep })
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));

    inspectionStore.getValidationState()
      .then((validationState) => setFormValidationState(validationState))
      .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
  }, [inspectionStore, formCurrentStep, enqueueSnackbar]);

  const validateQuestion = useCallback((question, sectionId) => {
    const questionKey = inspectionStore.QUESTION_KEY({ sectionId, questionId: question.id });
    const result = FormHelper.validateQuestion(question);
    const newQuestion = {
      ...toJS(question),
      isValid: result === VALID,
      errorMessage: result !== VALID ? result : ''
    };

    inspectionStore.saveQuestion({ key: questionKey, question: newQuestion });

    return !result || result === VALID;
  }, [inspectionStore]);

  const getQuestionsList = useCallback(async (sectionKey) => inspectionStore.getSectionQuestions({ sectionKey })
    .then((questions) => questions), [inspectionStore]);

  useEffect(() => {
    if (inspectionForm && (inspection.canValidate || inspection.canStart)) {
      const validateAllQuestions = async () => {
        const fullFormValidationState = {};

        await Promise.all(inspectionForm.surveySections.map(async (_, index) => {
          const currentSectionId = inspectionForm.surveySections[index].id;
          const sectionKey = `inspection${inspectionId}_section${currentSectionId}`;
          const sectionQuestionList = await getQuestionsList(sectionKey);

          let isValid = true;

          sectionQuestionList.forEach((question) => {
            isValid = validateQuestion(question, currentSectionId) && isValid;
          });

          fullFormValidationState[index] = {
            isValid,
            isChecked: true
          };

          return fullFormValidationState;
        }));

        inspectionStore.saveValidationState(fullFormValidationState);
        setFormValidationState(fullFormValidationState);
      };

      validateAllQuestions();
    }
  }, [inspection, inspectionId, inspectionStore, inspectionForm, getQuestionsList, validateQuestion]);

  const handleBackToInspectionDetail = useCallback(() => {
    history.push(ROUTES.INSPECTION(folderId));
    // showModal({
    //   type: 'WARNING',
    //   text: translate('warnings.quitInspection'),
    //   buttonConfirm: 'button.deleteInspection',
    //   onConfirm: () => {
    //     inspectionStore.deleteInspection()
    //       .then(() => {
    //         enqueueSnackbar(translate('confirms.inspectionDeleted'), { variant: 'success' });
    //         history.push(ROUTES.INSPECTION(folderId));
    //       })
    //       .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }));
    //   }
    // })
  }, [history, folderId]);

  const validateStep = useCallback(() => {
    const currentSectionId = inspectionForm.surveySections[formCurrentStep].id;
    const sectionKey = `inspection${inspectionId}_section${currentSectionId}`;

    return getQuestionsList(sectionKey)
      .then((questionList) => {
        let isValid = true;

        questionList.forEach((question) => {
          isValid = validateQuestion(question, currentSectionId) && isValid;
        });

        const stepValidationState = {
          isValid,
          isChecked: true
        };

        const newFormValidationState = {
          ...formValidationState,
          [formCurrentStep]: stepValidationState
        };

        inspectionStore.saveValidationState(newFormValidationState);
        setFormValidationState(newFormValidationState);

        return newFormValidationState;
      });
  }, [validateQuestion, formValidationState, inspectionForm, inspectionStore, formCurrentStep, getQuestionsList, inspectionId]);

  const changeStep = useCallback((stepIndex) => {
    validateStep().then(() => {
      setStepAndScroll(stepIndex);
    });
  }, [validateStep, setStepAndScroll]);

  const checkFormValidity = useCallback(() =>
    // Validate the current section
    // eslint-disable-next-line implicit-arrow-linebreak
    validateStep().then((newFormValidationState) =>
      // Check if all sections are valid (i.e : the form validation state has a valid entry for each section)
      // eslint-disable-next-line implicit-arrow-linebreak
      !inspectionForm.surveySections
        .find((_, index) => !newFormValidationState[index] || !newFormValidationState[index].isValid)),
  [inspectionForm, validateStep]);

  const goToPreviousStep = useCallback(() => {
    const prevStep = formCurrentStep - 1;
    changeStep(prevStep);
  }, [formCurrentStep, changeStep]);

  const goToNextStep = useCallback(() => {
    const nextStep = formCurrentStep + 1;
    changeStep(nextStep);
  }, [formCurrentStep, changeStep]);

  const submitThen = useCallback((message) => {
    enqueueSnackbar(translate(message), { variant: 'success' });

    inspectionStore.loadInspectionListStored()
      .then(() => history.push(PATHS.INSPECTION_LIST));
  }, [enqueueSnackbar, inspectionStore, history]);

  const submitCatch = useCallback((message, error) => {
    if (isOffline) {
      return enqueueSnackbar(translate(message), { variant: 'warning' });
    }

    if (error && error.message) {
      return enqueueSnackbar(error.message, { variant: 'error' });
    }

    return null;
  }, [isOffline, enqueueSnackbar]);

  const handleSubmitInspection = useCallback(() => {
    checkFormValidity().then(async (isValid) => {
      const lastSection = inspectionForm.surveySections[inspectionForm.surveySections.length - 1];
      const lastQuestionId = lastSection.surveyQuestions[lastSection.surveyQuestions.length - 1].id;
      const lastQuestion = inspectionStore.QUESTION_KEY({ sectionId: lastSection.id, questionId: lastQuestionId });
      const lastQuestionValue = await inspectionStore.getQuestion({ key: lastQuestion }).then((q) => q.measured);

      if (lastQuestionValue && !lastQuestionValue.base64Content) {
        return enqueueSnackbar(translate('errors.lastQuestionRequired'), { variant: 'error' });
      }

      if (isValid) {
        return inspectionStore.submitInspection({ publish: true })
          .then(() => submitThen('confirms.inspectionSubmitted'))
          .catch((err) => submitCatch('warnings.offlineSubmitPublish', err));
      }

      return showModal({
        type: 'SUBMIT_INSPECTION',
        onConfirm: (statusComment) => inspectionStore.submitInspection({
          publish: true, statusComment
        })
          .then(() => submitThen('confirms.inspectionSubmitted'))
          .catch((err) => submitCatch('warnings.offlineSubmitPublish', err))
      });
    });

    return false;
  }, [inspectionStore, inspectionForm, checkFormValidity, showModal, submitThen, submitCatch, enqueueSnackbar]);

  const handleValidateInspection = useCallback(() => {
    checkFormValidity().then(() => inspectionStore.submitInspection({ validate: true })
      .then(() => submitThen('confirms.inspectionValidated'))
      .catch((err) => submitCatch('warnings.offlineSubmitValidate', err)));

    return false;
  }, [inspectionStore, checkFormValidity, submitThen, submitCatch]);

  const getTooltipText = useCallback((stepValidationState) => {
    if (!stepValidationState.isChecked) {
      return translate('forms.uncheckedStep');
    }
    if (!stepValidationState.isValid) {
      return translate('forms.invalidStep');
    }
    return translate('forms.validStep');
  }, []);

  if (isLoading) {
    return <SkeletonFormDocument />;
  }

  if (!isLoading && !inspectionForm) {
    history.push(ROUTES.INSPECTION(folderId));

    return (
      <section>
        <TextError>{translate('errors.noInspectionDetail')}</TextError>
        <TextError>{translate('errors.noInspectionDetailExplanation')}</TextError>
      </section>
    );
  }

  const selectedSection = inspectionForm.surveySections[formCurrentStep];
  const numberOfSections = inspectionForm.surveySections.length;

  return (
    <>
      <InspectionFormNav />

      <LargeWrapper data-testid="inspection-form-container" style={{ paddingBottom: '6rem' }}>
        <PageTitle title={translate('pageInspectionForm.title', { inspectionIdentifier: inspection.inspectionIdentifier })} />

        <Stepper
          activeStep={formCurrentStep}
          alternativeLabel
          id="inspectionFormStepper"
          style={{ overflowX: 'auto' }}
        >
          {inspectionForm.surveySections.map((section, index) => {
            const stepValidationState = (formValidationState && formValidationState[index]) || {};
            const isCurrentStep = formCurrentStep === index;
            return (
              <Step
                completed={stepValidationState.isChecked && stepValidationState.isValid && formCurrentStep !== index}
                key={section.id}
              >
                <Tooltip arrow placement="top" title={getTooltipText(stepValidationState)}>
                  <StepLabel
                    error={stepValidationState.isChecked && !stepValidationState.isValid && formCurrentStep !== index}
                    StepIconProps={{ classes: { completed: 'step-completed' } }}
                    style={{ cursor: 'pointer' }}
                    onClick={() => changeStep(index)}
                  >
                    <Typography
                      style={{
                        fontWeight: isCurrentStep ? '600' : 'normal',
                        fontSize: isCurrentStep ? '1.8rem' : '1.6rem'
                      }}
                    >
                      {section.title}
                    </Typography>
                  </StepLabel>
                </Tooltip>
              </Step>
            );
          })}
        </Stepper>

        <Grid container direction="column" spacing={4}>
          <Grid item>
            {selectedSection && (
              <form id={`section${selectedSection.id}`}>
                <Section key={selectedSection.id} title={selectedSection.title}>
                  {selectedSection.surveyQuestions.map((question) => (
                    <Grid item key={question.id}>
                      <Question
                        id={question.id}
                        sectionId={selectedSection.id}
                      />
                    </Grid>
                  ))}
                </Section>
              </form>
            )}
          </Grid>

          <Grid item>
            {/* Don't show previous & next buttons on the last step */}
            {formCurrentStep !== (numberOfSections - 1) && (
              <Grid alignItems="center" container justifyContent="space-between">
                {formCurrentStep === 0 ? (
                  <Button
                    startIcon={<FontAwesomeIcon flip="horizontal" icon={faSignOutAlt} />}
                    type="secondary"
                    onClick={handleBackToInspectionDetail}
                  >
                    {translate('button.quit')}
                  </Button>
                ) : (
                  <Button
                    data-cy="inspectionFormPrev"
                    disabled={formCurrentStep === 0}
                    startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                    variant="text"
                    onClick={goToPreviousStep}
                  >
                    {translate('button.previous')}
                  </Button>
                )}
                <Button
                  data-cy="inspectionFormNext"
                  endIcon={<FontAwesomeIcon icon={faChevronRight} />}
                  onClick={goToNextStep}
                >
                  {translate('button.next')}
                </Button>
              </Grid>
            )}

            {/* Instead show the final button to send the form */}
            {formCurrentStep === (numberOfSections - 1) && (
              <Grid alignItems="center" container justifyContent="space-between">
                {/* Dont show the previous button if there is only one step */}
                {/* Instead show the delete button if there is only one step */}
                <Grid item>
                  {formCurrentStep > 0 ? (
                    <Button
                      data-cy="inspectionFormPrev"
                      startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
                      variant="text"
                      onClick={goToPreviousStep}
                    >
                      {translate('button.previous')}
                    </Button>
                  ) : (
                    <Button
                      startIcon={<FontAwesomeIcon flip="horizontal" icon={faSignOutAlt} />}
                      type="secondary"
                      onClick={handleBackToInspectionDetail}
                    >
                      {translate('button.quit')}
                    </Button>
                  )}
                </Grid>

                <Grid item>
                  <Grid alignItems="center" container spacing={2}>
                    {inspection.canStart && (
                      <Grid item>
                        <Button
                          disabled={isSubmiting}
                          startIcon={<FontAwesomeIcon icon={isSubmiting ? faSpinner : faShareSquare} spin={isSubmiting} />}
                          onClick={handleSubmitInspection}
                        >
                          {translate('button.submit')}
                        </Button>
                      </Grid>
                    )}

                    {(inspection.canValidate || inspection.canCorrect) && (
                      <Grid item>
                        <Button
                          disabled={isSubmiting}
                          startIcon={<FontAwesomeIcon icon={isSubmiting ? faSpinner : faClipboardCheck} spin={isSubmiting} />}
                          onClick={handleValidateInspection}
                        >
                          {translate('pageInspectionForm.validateInspection')}
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </LargeWrapper>
    </>
  );
});

export default InspectionForm;