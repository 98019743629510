import { faCookieBite } from '@fortawesome/pro-solid-svg-icons';
import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import styled from 'styled-components';
import { translate } from 'utils';
import { Link } from 'react-router-dom';
import { ASSISTANCE_EMAIL, ROUTES } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale, faEnvelope } from '@fortawesome/pro-regular-svg-icons';
import { Desktop, Mobile, Wrapper } from 'components';

const StyledFooter = styled.footer`
  margin-top: 40px;
  text-align: center;

  @media (max-width: 1081px) {
    padding-bottom: 40px;
  }
`;

const StyledDivider = styled(Divider)`
  display: inline-block;
  width: 2px;
  background-color: #7e7e7e !important;
  height: 14px !important;
`;

const StyledLogo = styled.img`
  max-width: 100%;

  @media (min-width: 1080px) {
    margin-top: -2rem;
  }
`;

export const Footer = () => (
  <Wrapper>
    <StyledFooter>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Grid
            alignItems="center"
            container
            justifyContent="center"
            spacing={2}
            wrap="wrap"
          >
            <Grid item>
              <Link to={ROUTES.RGPD}>
                <FontAwesomeIcon className="mr1" icon={faBalanceScale} />
                {translate('common.personalData')}
              </Link>
            </Grid>

            <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <Link to={ROUTES.COOKIES}>
                <FontAwesomeIcon className="mr1" icon={faCookieBite} />
                {translate('link.rgpd.cookies')}
              </Link>
            </Grid>

            <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <a href={`mailto:${ASSISTANCE_EMAIL}`}>
                <FontAwesomeIcon className="mr1" icon={faEnvelope} />
                {`${translate('common.assistance')} (${ASSISTANCE_EMAIL})`}
              </a>
            </Grid>

            {/* <Grid item>
              <StyledDivider orientation="vertical" />
            </Grid>

            <Grid item>
              <a
                href={`${DATATECNEA_URL}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                <FontAwesomeIconStyled icon={faLink} />
                {translate('common.datatecneaLink')}
              </a>
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item>
          <img alt="TECNEA_GROUP" src={`${process.env.PUBLIC_URL}/assets/images/companies/TECNEA_GROUP.png`} />
        </Grid>

        <Grid item>
          <Desktop>
            <StyledLogo
              alt={translate('menu.logoAlt')}
              src={`${process.env.PUBLIC_URL}/assets/images/bg/footer.png`}
              title={translate('menu.logoAlt')}
            />
          </Desktop>
          <Mobile>
            <StyledLogo
              alt={translate('menu.logoAlt')}
              src={`${process.env.PUBLIC_URL}/assets/images/bg/footer-mobile.png`}
              title={translate('menu.logoAlt')}
            />
          </Mobile>
        </Grid>
      </Grid>
    </StyledFooter>
  </Wrapper>
);