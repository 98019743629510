import { TableCell, Typography } from '@material-ui/core';
import { TextAddress } from 'components';
import React from 'react';
import shortid from 'shortid';
import { translate } from 'utils';
import InfoLine from '../InfoLine/InfoLine';

export const TextEntity = ({ entity, title, error }) => {
  if (!entity || !entity.lastName) {
    return (
      <Typography>
        {translate(error || 'errors.noData')}
      </Typography>
    );
  }

  return (
    <>
      {title && <Typography gutterBottom variant="h4">{translate(title)}</Typography>}

      <InfoLine info={entity.lastName} label={entity.institutionIdentifier ? 'common.institutionName' : 'common.lastName'} />
      <InfoLine info={entity.firstName} label="common.firstName" />
      <InfoLine info={entity.institutionIdentifier} label="common.siret" />
      <InfoLine info={entity.email} label="common.email" type="email" />
      <InfoLine info={entity.phoneNumber} label="common.phone" type="phone" />
      <InfoLine info={entity.phoneNumber2} label="common.phone2" type="phone" />

      {entity.address?.address1 && (
        <>
          <Typography>{`${translate('common.address')} : `}</Typography>
          <TextAddress address={entity.address} />
        </>
      )}
    </>
  );
};

const CellEntity = () => ({
  name: 'entity',
  label: 'common.address',
  template: (row) => (
    <TableCell key={shortid.generate()}>
      {row.entity && row.entity.lastName ? (
        <TextEntity entity={row.entity} />
      ) : '-'}
    </TableCell>
  )
});

export default CellEntity;