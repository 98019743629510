import React, { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { Button, HeaderNav } from 'components';
import { useAccessRights, useStores } from 'hooks';
import { translate } from 'utils';
import { APPLICATION_ROLES } from 'utils/constants';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt, faSpinner } from '@fortawesome/pro-regular-svg-icons';

export const InspectionFormNav = observer(() => {
  const { inspectionStore, userStore } = useStores();
  const { isSubmiting, inspection } = inspectionStore;
  const { isOffline } = userStore;
  const { enqueueSnackbar } = useSnackbar();
  const hasAccessRights = useAccessRights({ roles: [APPLICATION_ROLES.INSPECTOR], checkOffline: true });

  const handleSaveInspection = useCallback(() => inspectionStore.submitInspection()
    .then(() => enqueueSnackbar(translate('confirms.inspectionSaved'), { variant: 'success' }))
    .catch((err) => {
      if (isOffline) {
        return enqueueSnackbar(translate('warnings.offlineSubmitSave'), { variant: 'warning' });
      }

      return enqueueSnackbar(err.message, { variant: 'error' });
    }),
  [inspectionStore, isOffline, enqueueSnackbar]);

  return (
    <HeaderNav isDisplayed={Boolean(hasAccessRights && inspection.canStart)}>
      {hasAccessRights && inspection.canStart && (
        <Grid item>
          <div>
            <Button
              disabled={isSubmiting}
              startIcon={<FontAwesomeIcon icon={isSubmiting ? faSpinner : faCloudUploadAlt} spin={isSubmiting} />}
              onClick={handleSaveInspection}
            >
              {translate('button.saveInspectionProgress')}
            </Button>
          </div>
        </Grid>
      )}
    </HeaderNav>
  );
});