/* eslint-disable max-len */
// DOCS: https://docs.mapbox.com/playground/static/
// Limited to 50.000 free request by month
import proj4 from 'proj4';

const mapboxUrl = 'https://api.mapbox.com/styles/v1/mapbox/satellite-v9/static';
const mapboxAccessToken = 'pk.eyJ1Ijoic3VwcmFsb2ciLCJhIjoiY2tzcmg4OW9qMG4wZzJwdW9wNDZ5aXFnMCJ9.aDTrksYPkoB7lmugQHWlPQ';
const defaultZoom = 17;
const defaultSize = '300x200';

const geoservicesUrl = 'https://wxs.ign.fr/pratique/geoportail/wmts?&SERVICE=WMTS&VERSION=1.0.0&REQUEST=GetTile&TILEMATRIXSET=PM&FORMAT=image/jpeg';
const style = 'normal';
const layer = 'ORTHOIMAGERY.ORTHOPHOTOS';
const tileMatrix = 18;
const scale18 = '0.5971642835';
const topLeftCornerXo = '-20037508';
const topLeftCornerYo = '20037508';

const getMarker = (position) => (
  `pin-l-marker+dc004e(${position.longitude},${position.latitude})`
);

const getPosition = () => new Promise((resolve, reject) => navigator.geolocation.getCurrentPosition((position) => {
  resolve(position.coords);
}, (error) => reject(error),
{ enableHighAccuracy: true }));

const getStaticImage = () => getPosition()
  .then((position) => ({
    image: `${mapboxUrl}/${getMarker(position)}/${position.longitude},${position.latitude},${defaultZoom}/${defaultSize}?access_token=${mapboxAccessToken}`,
    position
  }))
  .catch((error) => {
    throw error;
  });

const getTilePosition = (mercatorProjection) => {
  const x = Math.floor(mercatorProjection[0] - topLeftCornerXo);
  const y = Math.floor(topLeftCornerYo - mercatorProjection[1]);
  const pixelToMeters = 256 * scale18;

  const tileX = Math.floor(x / pixelToMeters);
  const tileY = Math.floor(y / pixelToMeters);

  return { tileX, tileY };
};

const getGeoportailSatelliteImage = () => getPosition()
  .then((position) => {
    const mercatorProjection = proj4('EPSG:4326', 'EPSG:3857', [position.longitude, position.latitude]);
    const tilePosition = getTilePosition(mercatorProjection);

    return {
      image: `${geoservicesUrl}&LAYER=${layer}&STYLE=${style}&TILECOL=${tilePosition.tileX}&TILEROW=${tilePosition.tileY}&TILEMATRIX=${tileMatrix}`,
      position
    };
  })
  .catch((error) => {
    throw error;
  });

export const GeolocationService = {
  getPosition,
  getStaticImage,
  getGeoportailSatelliteImage
};