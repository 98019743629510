import { INPUT_TYPES } from 'utils/constants';

export const questionFixture = ({
  id = 1, questionText = 'First question', questionType = 'TEXT', result = null,
  declared = null, measured = null, ...rest
} = {}) => ({
  id,
  questionText,
  questionType,
  declared,
  measured,
  result,
  ...rest
});

export const questionList1 = [
  questionFixture({
    questionText: 'Prendre un photo de la façade et de l’entrée',
    questionType: INPUT_TYPES.DOCUMENT,
    measured: {}
  }),
  questionFixture({
    id: 2,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'BOOLEAN',
    questionText: 'La personne est-elle présente ?'
  })
];

export const questionList2 = [
  questionFixture({
    id: 3,
    questionType: INPUT_TYPES.ENTITY,
    questionText: 'Saisir les informations du bénéficiaire',
    measured: {},
    declared: {}
  })
];

export const questionList3 = [
  questionFixture({
    id: 4,
    questionType: INPUT_TYPES.ADDRESS,
    questionText: 'Saisir l’adresse des travaux',
    measured: {},
    declared: {}
  })
];

export const questionList4 = [
  questionFixture({
    id: 5,
    questionType: INPUT_TYPES.ENTITY,
    questionText: 'Saisir les informations du signataire',
    measured: {},
    declared: {}
  })
];

export const questionList5 = [
  questionFixture({
    id: 6,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'BOOLEAN',
    questionText: `Le professionnel a-t-il effectué, au plus tard avant l’établissement du devis, une visite
    du bâtiment au cours de laquelle il valide que la mise en place des isolants
    dans les combles ou en toiture de ce bâtiment est en adéquation avec ce dernier ?`,
    placeholder: 'Visite du bâtiment ?'
  }),
  questionFixture({
    id: 7,
    questionText: 'Prendre une photo du devis',
    questionType: INPUT_TYPES.DOCUMENT,
    measured: {}
  })
];

export const questionList6 = [
  questionFixture({
    id: 8,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Type de construction'
  }),
  questionFixture({
    id: 9,
    questionType: INPUT_TYPES.SURFACE,
    questionText: 'Superficie du plancher au niveau 0 (en cm)',
    measured: {
      surfaceTotal: '',
      surfaces: []
    }
  }),
  questionFixture({
    id: 10,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Nombre de niveaux habitables'
  }),
  questionFixture({
    id: 11,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'La configuration de l’étage habitable est de questionType combles aménagés'
  })
];

export const questionList7 = [
  questionFixture({
    id: 12,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'La trappe de visite est-elle présente ?'
  }),
  questionFixture({
    id: 13,
    isNumber: true,
    questionText: 'Hauteur de la trappe de visite (en cm)'
  })
];

export const questionList8 = [
  questionFixture({
    id: 14,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Accès aux combles'
  }),
  questionFixture({
    id: 15,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'BOOLEAN',
    questionText: 'Les combles sont-ils aménageables ?'
  }),
  questionFixture({
    id: 16,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Type de plancher isolé dans les combles perdues ?'
  })
];

export const questionList9 = [
  questionFixture({
    id: 17,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'BOOLEAN',
    questionText: 'L’étage est-il de questionType mansarde ?'
  }),
  questionFixture({
    id: 18,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'BOOLEAN',
    questionText: 'S’agit-il d’une mansarde brisée ?'
  }),
  questionFixture({
    id: 19,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'BOOLEAN',
    questionText: `Le brisis a-t-il bénéficié du renforcement de son isolation par la pose d'isolant
    supplémentaire lors de la réalisation de l'opération ?`,
    placeholder: "Renforcement de l'isolation ?"
  }),
  questionFixture({
    id: 20,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: "Quel questionType d'isolation est réalisé sur le brisis ?"
  }),
  questionFixture({
    id: 21,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Type de pose en plaques ou rouleau du brisis'
  }),
  questionFixture({
    id: 22,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: "Épaisseur de l'isolant posé pour le brisis"
  }),
  questionFixture({
    id: 23,
    questionText: "Prendre une photo de l'isolant posé pour le brisis",
    questionType: INPUT_TYPES.DOCUMENT,
    measured: {}
  }),
  questionFixture({
    id: 24,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: "Marque de l'isolant posé pour le brisis"
  }),
  questionFixture({
    id: 25,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: "Référence de l'isolant posé pour le brisis"
  })
];

export const questionList10 = [
  questionFixture({
    id: 26,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'BOOLEAN',
    questionText: `Le terrasson a-t-il bénéficié du renforcement de son isolation
    par la pose d'isolant supplémentaire lors de la réalisation de l'opération ?`,
    placeholder: 'Renforcement de son isolation ?'
  }),
  questionFixture({
    id: 27,
    questionType: INPUT_TYPES.SURFACE,
    questionText: 'Superficie isolée du plafond au dernier niveau.',
    measured: {
      surfaceTotal: '',
      surfaces: []
    }
  }),
  questionFixture({
    id: 28,
    questionType: INPUT_TYPES.SURFACE,
    questionText: 'Superficie non isolée',
    measured: {
      surfaceTotal: '',
      surfaces: []
    }
  })
];

export const questionList11 = [
  questionFixture({
    id: 29,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Marque de l’isolant'
  }),
  questionFixture({
    id: 30,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Référence de l’isolant'
  }),
  questionFixture({
    id: 31,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Type de pose de l’isolant'
  }),
  questionFixture({
    id: 32,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Modalité de pose de l’isolant'
  }),
  questionFixture({
    id: 33,
    isNumber: true,
    questionText: 'Épaisseur de l’isolant posé (cm)'
  }),
  questionFixture({
    id: 34,
    questionText: 'Prendre une photo de l’isolant posé',
    questionType: INPUT_TYPES.DOCUMENT,
    measured: {}
  }),
  questionFixture({
    id: 35,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Matériau employés'
  }),
  questionFixture({
    id: 36,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: "Étanchéité de l'air en sous-couche"
  })
];

export const questionList12 = [
  questionFixture({
    id: 37,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Type de chauffage'
  }),
  questionFixture({
    id: 38,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Mode d’énergie du chauffage principal'
  }),
  questionFixture({
    id: 39,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Mode de chauffage principal'
  }),
  questionFixture({
    id: 40,
    questionText: 'Prendre une photo de la facture du mode de chauffage principal',
    questionType: INPUT_TYPES.DOCUMENT,
    measured: {}
  }),
  questionFixture({
    id: 41,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: "Mode d'énergie du chauffage auxiliaire"
  }),
  questionFixture({
    id: 42,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Mode de chauffage auxiliaire'
  }),
  questionFixture({
    id: 43,
    questionText: 'Prendre une photo de la facture du mode de chauffage auxiliaire',
    questionType: INPUT_TYPES.DOCUMENT,
    measured: {}
  }),
  questionFixture({
    id: 44,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: "Mode de production de l'eau chaude sanitaire"
  }),
  questionFixture({
    id: 45,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Écart au feu'
  })
];

export const questionList13 = [
  questionFixture({
    id: 46,
    questionType: INPUT_TYPES.ENUM,
    dataType: 'TOOL_TYPE',
    questionText: 'Note de satisfaction'
  }),
  questionFixture({
    id: 47,
    questionType: INPUT_TYPES.SIGNATURE,
    questionText: 'Signature du bénéficiaire'
  }),
  questionFixture({
    id: 48,
    questionType: INPUT_TYPES.SIGNATURE,
    questionText: 'Signature de l’inspecteur'
  })
];

export const questionList14 = [
  questionFixture({
    id: 49,
    questionText: 'Ajouter d’autres documents',
    questionType: INPUT_TYPES.DOCUMENT,
    measured: {}
  })
];

export const sectionListFixture = [
  {
    id: 1,
    title: 'Démarrage',
    surveyQuestions: questionList1
  },
  {
    id: 2,
    title: 'Bénéficiaire des travaux',
    surveyQuestions: questionList2
  },
  {
    id: 3,
    title: 'Adresse des travaux',
    surveyQuestions: questionList3
  },
  {
    id: 4,
    title: 'Signataire',
    surveyQuestions: questionList4
  },
  {
    id: 5,
    title: 'Devis',
    surveyQuestions: questionList5
  },
  {
    id: 6,
    title: 'Bâtiment',
    surveyQuestions: questionList6
  },
  {
    id: 7,
    title: 'Trappe de visite',
    surveyQuestions: questionList7
  },
  {
    id: 8,
    title: 'Combles',
    surveyQuestions: questionList8
  },
  {
    id: 9,
    title: 'Mansarde / Brisis',
    surveyQuestions: questionList9
  },
  {
    id: 10,
    title: 'Bâtiment (suite)',
    surveyQuestions: questionList10
  },
  {
    id: 11,
    title: 'Isolant',
    surveyQuestions: questionList11
  },
  {
    id: 12,
    title: 'Chauffage',
    surveyQuestions: questionList12
  },
  {
    id: 13,
    title: 'Signatures',
    surveyQuestions: questionList13
  },
  {
    id: 14,
    title: 'Documents supplémentaires',
    surveyQuestions: questionList14
  }
];

export const inspectionFormFixture = {
  id: 1,
  surveySections: sectionListFixture
};

export const inspectionFixture = {
  id: 1,
  identifier: 1,
  creationDate: '2020-12-01',
  status: 'IN_PROGRESS',
  address: {
    address1: '2203 chemin de saint Claude',
    address2: null,
    postalCode: '06600',
    city: 'ANTIBES'
  },
  beneficiary: {
    lastName: 'Doe',
    firstName: 'John'
  },
  workforce: {
    lastName: 'dur',
    firstName: 'Je travaille'
  }
};

export const inspectionListFixture = [
  inspectionFixture
];