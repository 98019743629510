import { createTheme } from '@material-ui/core/styles';

export const DATAC2E_THEME = createTheme({
  palette: {
    primary: {
      main: '#017db8'
    },
    secondary: {
      main: '#ffffff',
      contrastText: '#017db8',
      dark: '#dde8f6'
    },
    error: {
      main: '#dc004e'
    }
  },
  typography: {
    htmlFontSize: 10,
    h1: {
      fontSize: '3.6rem'
    },
    h2: {
      fontSize: '3.2rem'
    },
    h3: {
      fontSize: '2.4rem'
    },
    h4: {
      fontSize: '2.2rem'
    },
    h5: {
      fontSize: '2rem'
    },
    h6: {
      fontSize: '1.8rem'
    }
  },
  props: {
    MuiButton: {
      variant: 'contained'
    },
    MuiCard: {
      elevation: 2
    }
  },
  breakpoints: {
    values: {
      sm: 768
    }
  }
});