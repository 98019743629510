/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid, IconButton, Tab, TableCell, Tabs, Tooltip, Typography
} from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import {
  GenericFilters, GenericTable, PageTitle,
  SkeletonNoResult, SkeletonTable, Status, Wrapper
} from 'components';
import { useSnackbar } from 'notistack';
import { useAccessRights, useModal, useStores } from 'hooks';
import { observer } from 'mobx-react-lite';
import { transformObjectListToStringQueriesParam, translate } from 'utils';
import shortid from 'shortid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Link, useHistory } from 'react-router-dom';
import { APPLICATION_ROLES, ROUTES } from 'utils/constants';
import { FilterHelper, formatNiceDate } from 'utils/helpers';
import { InspectionListFilters } from './InspectionListFilters';
import { InspectionListNav } from './InspectionListNav';

const listHeadersTableMobile = (handleDeleteInspection) => [
  {
    name: 'campaignIdentifier',
    label: 'common.campaignIdentifierMobile',
    template: (row) => (
      <TableCell key={shortid.generate()} style={{ minWidth: '170px' }}>
        <Typography>
          {row.campaignIdentifier || '-'}
          <FontAwesomeIcon className="ml1" color="var(--tecnea-blue)" icon={faEye} />
        </Typography>
        <Typography>
          {row.batchIdentifier || '-'}
        </Typography>
        <Typography>
          {row.inspectionIdentifier || '-'}
        </Typography>
      </TableCell>
    )
  }, {
    name: 'beneficiaryName',
    label: 'common.beneficiaryNameMobile',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        <Typography>
          {row.beneficiaryName || '-'}
        </Typography>
        <Typography>
          {(row.appointmentDate && formatNiceDate(row.appointmentDate)) || '-'}
        </Typography>
        <Typography>
          {(row.inspectionStatus && <Status status={row.inspectionStatus} />) || '-'}
        </Typography>
      </TableCell>
    )
  }, {
    name: 'actions',
    label: 'common.actions',
    width: 70,
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.isStored && (
          <Tooltip aria-label={translate('button.deleteInspectionStored')} title={translate('button.deleteInspectionStored')}>
            <IconButton onClick={(e) => {
              e.stopPropagation();
              handleDeleteInspection(row);
            }}
            >
              <FontAwesomeIcon color="var(--danger-color)" icon={faTrashAlt} />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
    )
  }
];

const headerActions = (handleDeleteInspection) => ({
  name: 'actions',
  label: 'common.actions',
  template: (row) => (
    <TableCell key={shortid.generate()}>
      <Grid container wrap="nowrap">
        <Grid item>
          <Link to={ROUTES.INSPECTION(row.id)}>
            <Tooltip aria-label={translate('button.viewInspectionDetail')} title={translate('button.viewInspectionDetail')}>
              <IconButton>
                <FontAwesomeIcon color="var(--tecnea-blue)" icon={faEye} />
              </IconButton>
            </Tooltip>
          </Link>
        </Grid>
        {row.isStored && (
          <Grid item>
            <Tooltip aria-label={translate('button.deleteInspectionStored')} title={translate('button.deleteInspectionStored')}>
              <IconButton onClick={() => handleDeleteInspection(row)}>
                <FontAwesomeIcon color="var(--danger-color)" icon={faTrashAlt} />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
      </Grid>
    </TableCell>
  )
});

const listHeadersTable = (handleDeleteInspection) => [
  {
    name: 'campaignIdentifier',
    label: 'common.campaignIdentifier',
    template: (row) => (
      <TableCell key={shortid.generate()} style={{ minWidth: '170px' }}>
        {row.campaignIdentifier || '-'}
        {' / '}
        {row.batchIdentifier || '-'}
      </TableCell>
    )
  }, {
    name: 'identifier',
    label: 'common.inspectionIdentifier',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.inspectionIdentifier || '-'}
      </TableCell>
    )
  }, {
    name: 'beneficiaryName',
    label: 'common.beneficiaryName',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.beneficiaryName || '-'}
      </TableCell>
    )
  }, {
    name: 'appointmentDate',
    label: 'common.appointmentDate',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {(row.appointmentDate && formatNiceDate(row.appointmentDate, true)) || '-'}
      </TableCell>
    )
  },
  {
    name: 'inspectionStatus',
    label: 'common.status',
    template: (row) => (
      <TableCell key={shortid.generate()} style={{ minWidth: '160px' }}>
        {(row.inspectionStatus && <Status status={row.inspectionStatus} />) || '-'}
      </TableCell>
    )
  }, headerActions(handleDeleteInspection)
];

const listHeadersTableAdmin = (handleDeleteInspection) => [
  ...listHeadersTable().slice(0, -1),
  {
    name: 'inspectorName',
    label: 'common.inspectorName',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {row.inspectorName || '-'}
      </TableCell>
    )
  },
  {
    name: 'inspectionResult',
    label: 'common.inspectionResult',
    template: (row) => (
      <TableCell key={shortid.generate()}>
        {(row.inspectionResult && translate(`results.${row.inspectionResult}`)) || '-'}
      </TableCell>
    )
  }, headerActions(handleDeleteInspection)
];

const InspectionList = observer(() => {
  const filterKey = 'inspectionList';
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const showModal = useModal();
  const { inspectionStore, userStore } = useStores();
  const {
    isLoading, inspectionList, inspectionListStored, totalInspections, maxPage
  } = inspectionStore;
  const { isOffline } = userStore;

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(FilterHelper.getFilters(filterKey) || []);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentTab, setCurrentTab] = useState(0);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const hasAccessRightsAdmin = useAccessRights({
    roles: [APPLICATION_ROLES.ADMIN, APPLICATION_ROLES.SUPER_ADMIN, APPLICATION_ROLES.CAMPAIGN_MANAGER]
  });

  const loadInspectionList = useCallback(() => {
    const filtersQueriesParams = transformObjectListToStringQueriesParam(filters);
    inspectionStore.loadInspectionList({
      search, page: currentPage, filters: filtersQueriesParams, isOffline
    })
      .catch(() => enqueueSnackbar(translate('errors.inspectionListFailed'), { variant: 'error' }));
  }, [filters, search, currentPage, isOffline, inspectionStore, enqueueSnackbar]);

  // TODO: uncomment when the home page is implemented
  // useEffect(() => {
  // if (!hasAccessRights) {
  //   history.push(ROUTES.HOME);
  // }
  // }, []);

  useEffect(() => {
    if (!isFirstLoad) {
      loadInspectionList();
    }
  // eslint-disable-next-line
  }, [currentPage, isFirstLoad]);

  useEffect(() => {
    if (currentPage === 0 && !isFirstLoad) loadInspectionList();
    else setCurrentPage(0);

    setIsFirstLoad(false);
  // eslint-disable-next-line
  }, [search, filters]);

  useEffect(() => {
    if (isOffline || inspectionListStored.length > 0) {
      setCurrentTab(1);
    }
  // eslint-disable-next-line
  }, [isOffline]);

  const loadMore = useCallback(() => {
    !isLoading && setCurrentPage(currentPage + 1);
  }, [isLoading, currentPage]);

  const handleRowClick = useCallback((row) => {
    history.push(ROUTES.INSPECTION(row.id));
  }, [history]);

  const handleDeleteInspection = useCallback((row) => {
    showModal({
      type: 'WARNING',
      buttonConfirm: 'button.deleteInspection',
      text: translate('warnings.inspectionDelete'),
      onConfirm: () => {
        inspectionStore.deleteInspection({ folderId: row.id, inspectionId: row.inspectionId })
          .then(() => {
            enqueueSnackbar(translate('confirms.inspectionDeleteSuccess'), { variant: 'success' });

            const filtersQueriesParams = transformObjectListToStringQueriesParam(filters);
            inspectionStore.loadInspectionList({
              search, page: currentPage, filters: filtersQueriesParams, isOffline
            })
              .catch(() => enqueueSnackbar(translate('errors.inspectionListFailed'), { variant: 'error' }));
          })
          .catch(() => enqueueSnackbar(translate('errors.inspectionDeleteFailed'), { variant: 'error' }));
      }
    });
  }, [inspectionStore, enqueueSnackbar, showModal, search, currentPage, filters, isOffline]);

  const handleTabChange = useCallback((_, tab) => setCurrentTab(tab), []);

  const renderGenericFilters = useCallback(({ currentFilters, setCurrentFilters }) => (
    <InspectionListFilters
      currentFilters={currentFilters}
      setCurrentFilters={setCurrentFilters}
    />
  ), []);

  let currentListHeadersTable = listHeadersTable(handleDeleteInspection);
  if (hasAccessRightsAdmin) {
    currentListHeadersTable = listHeadersTableAdmin(handleDeleteInspection);
  }
  if (isMobile) {
    currentListHeadersTable = listHeadersTableMobile(handleDeleteInspection);
  }

  return (
    <>
      <InspectionListNav filters={filters} listSize={totalInspections} search={search} />

      <Wrapper style={{ paddingBottom: '4rem' }}>
        <PageTitle title={translate('pageInspectionList.title')} />

        <Tabs
          centered
          data-cy="inspectionsListTabs"
          data-tour="step-inspectionsListTabs"
          indicatorColor="primary"
          textColor="primary"
          value={currentTab}
          variant="fullWidth"
          onChange={handleTabChange}
        >
          <Tab
            data-cy="inspectionsServer"
            disabled={isOffline}
            key={shortid.generate()}
            label={translate('pageInspectionList.inspectionList')}
          />
          <Tab
            data-cy="inspectionsServer"
            disabled={inspectionListStored.length === 0}
            key={shortid.generate()}
            label={translate('pageInspectionList.inspectionListStored', { number: inspectionListStored.length })}
          />
        </Tabs>

        {[
          !isOffline && (
            <div style={{ marginTop: '2rem' }}>
              <GenericFilters
                ComponentFilter={renderGenericFilters}
                dataTour="step-catalog-filter"
                filterKey={filterKey}
                filters={filters}
                search={search}
                setFilters={setFilters}
                setSearch={setSearch}
                tooltip="pageInspectionList.searchTooltip"
                withDrawer
              />

              {isLoading && inspectionList.length === 0 && (
                <SkeletonTable />
              )}

              {inspectionList && inspectionList.length > 0 ? (
                <GenericTable
                  dataCy="inspectionList"
                  dataTestid="inspection-list"
                  hasMore={currentPage < maxPage}
                  headers={currentListHeadersTable}
                  isLoading={isLoading}
                  loadMore={loadMore}
                  rows={inspectionList}
                  total={totalInspections}
                  onRowCLick={isMobile ? handleRowClick : null}
                />
              ) : !isLoading && (
                <SkeletonNoResult error="errors.noInspectionList" />
              )}
            </div>
          ),
          (
            inspectionListStored.length > 0 ? (
              <GenericTable
                dataCy="inspectionList"
                dataTestid="inspection-list"
                headers={currentListHeadersTable}
                rows={inspectionListStored}
                total={inspectionListStored.length}
                onRowCLick={isMobile ? handleRowClick : null}
              />
            ) : (
              <SkeletonNoResult error="errors.noInspectionListStored" />
            )
          )
        ][currentTab]}
      </Wrapper>
    </>
  );
});

export default InspectionList;