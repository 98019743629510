import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller'; // Docs : https://www.npmjs.com/package/react-infinite-scroller
import {
  Table, TableCell, TableHead, TableRow, TableSortLabel, Typography
} from '@material-ui/core';
import { translate } from 'utils';
import shortid from 'shortid';
import styled from 'styled-components';
import { SkeletonSection } from 'components';

const GenericTableContainer = styled.div`
  position: relative;
  max-width: 100%;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: auto;
`;

const TotalRows = styled(Typography)`
  font-size: 1.4rem;
  color: var(--grey-dark);
  text-align: right;

  @media (max-width: 768px) {
    margin-right: 20px;
  }
`;

const TableSortLabelComponent = ({
  orderBy, order, header, handleSortAction
}) => {
  const handleClick = useCallback(() => handleSortAction(header), [header, handleSortAction]);

  return (
    <TableSortLabel
      active={orderBy === header.name}
      direction={order}
      onClick={handleClick}
    >
      {header.label}
    </TableSortLabel>
  );
};

const TableRowComponent = ({
  hover, row, onRowCLick, headers, cursor
}) => {
  const handleClick = useCallback(() => onRowCLick(row), [onRowCLick, row]);

  return (
    <TableRow
      hover={hover}
      style={{ cursor, height: '55px' }}
      onClick={handleClick}
    >
      {headers.map((column) => (
        column.template(row)
      ))}
    </TableRow>
  );
};

export const GenericTable = ({
  rows, dataCy, dataTestid, dataTour, hasMore, loadMore, headers,
  hover, onRowCLick, stickyHeader, id, displayTotal, total, isLoading
}) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState(headers.orderBy || headers.name);
  const [sortOnProperty, setSortOnProperty] = useState(headers.sortOn || headers.name);

  const handleSort = useCallback(({ name, sortOn }) => {
    const isDesc = orderBy === name && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(name);
    setSortOnProperty(sortOn);
  }, [order, orderBy]);

  const desc = (a, b) => {
    if (b[orderBy] && b[orderBy][sortOnProperty]) {
      if (b[orderBy][sortOnProperty] < a[orderBy][sortOnProperty]) return -1;
      if (b[orderBy][sortOnProperty] > a[orderBy][sortOnProperty]) return 1;
    } else {
      if (b[orderBy] < a[orderBy]) return -1;
      if (b[orderBy] > a[orderBy]) return 1;
    }

    return 0;
  };

  const getSorting = () => (order === 'desc' ? (a, b) => desc(a, b) : (a, b) => -desc(a, b));

  const handleSortAction = useCallback((header) => handleSort({ ...header }), [handleSort]);

  const handleRowClick = useCallback((row) => onRowCLick && onRowCLick(row), [onRowCLick]);

  const handleLoadMore = useCallback(() => loadMore && loadMore(), [loadMore]);

  return (
    <GenericTableContainer
      data-cy={dataCy}
      data-testid={dataTestid}
      data-tour={dataTour}
    >
      {displayTotal && (
        <TotalRows gutterBottom>
          {`${rows.length} / ${total || 0} ${translate('common.totalDisplayed')}`}
        </TotalRows>
      )}

      <Table
        size="small"
        stickyHeader={stickyHeader}
      >
        <TableHead>
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.name} style={{ width: header.width || 'initial' }}>
                {header.isSortable ? (
                  <TableSortLabelComponent
                    handleSortAction={handleSortAction}
                    header={header}
                    order={order}
                    orderBy={orderBy}
                  />
                ) : translate(header.label)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <InfiniteScroll
          element="tbody"
          hasMore={hasMore}
          id={id}
          initialLoad={false}
          loadMore={handleLoadMore}
          pageStart={0}
          style={{ minHeight: '1200px' }}
          threshold={50}
        >
          {rows.slice()
            .sort(getSorting())
            .map((row) => (
              <TableRowComponent
                cursor={onRowCLick ? 'pointer' : 'initial'}
                headers={headers}
                hover={hover}
                key={row.hashId || shortid.generate()}
                row={row}
                onRowCLick={handleRowClick}
              />
            ))}
        </InfiniteScroll>
      </Table>

      {isLoading && <SkeletonSection />}
    </GenericTableContainer>
  );
};

GenericTable.propTypes = {
  dataCy: PropTypes.string,
  dataTour: PropTypes.string,
  displayTotal: PropTypes.bool,
  hasMore: PropTypes.bool,
  headers: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    label: PropTypes.string,
    isSortable: PropTypes.bool,
    sortOn: PropTypes.string,
    template: PropTypes.func
  })).isRequired,
  hover: PropTypes.bool,
  isLoading: PropTypes.bool,
  loadMore: PropTypes.func,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onRowCLick: PropTypes.func,
  stickyHeader: PropTypes.bool
};

GenericTable.defaultProps = {
  dataCy: '',
  dataTour: '',
  displayTotal: true,
  hasMore: false,
  hover: true,
  isLoading: false,
  loadMore: null,
  onRowCLick: null,
  stickyHeader: false
};