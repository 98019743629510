/* eslint-disable react/jsx-no-bind */
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Button, Grid, InputAdornment } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { translate } from 'utils';
import { InputField } from '../../Inputs';
import { DeclaredAndResult } from '../../Question';

const CustomButton = styled(Button)`
  && {
    position: absolute;
    height: 1px;
    min-height: initial;
    padding: 0;
    margin: 0;
    border: none;
    opacity: 0;
    cursor: default;
    pointer-events: none;
  }
`;

const QuestionArithmetic = ({
  name, value, label, placeholder, decimal, declared,
  result, required, onChange, code, formula, formulaElements,
  hasDeclared, hasResult, onChangeResult, unit
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCalculate = useCallback(() => {
    let calculatedValue = formula;

    formulaElements.forEach((elem) => {
      const { value: formulaValue = 0 } = document.getElementById(elem);
      calculatedValue = calculatedValue.replace(elem, Number(formulaValue));
    });

    // eslint-disable-next-line no-eval
    calculatedValue = eval(calculatedValue.replace(/[^-()\d/*+.]/g, ''));

    // eslint-disable-next-line no-restricted-globals
    if (isFinite(calculatedValue)) {
      return onChange(name, calculatedValue);
    }

    return enqueueSnackbar(translate('errors.notNumericValue'), { variant: 'warning' });
  }, [formula, formulaElements, name, onChange, enqueueSnackbar]);

  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item sm={6} xs={12}>
        <Grid container direction="column">
          <InputField
            autoComplete="off"
            disabled
            InputProps={{
              endAdornment: (unit && (
                <InputAdornment position="end">{unit}</InputAdornment>
              )),
              lang: 'en',
              step: decimal ? '0.01' : '1'
            }}
            label={placeholder || label}
            name={name}
            required={required}
            type="number"
            value={value}
          />
          <CustomButton
            id={`${code}`}
            onClick={handleCalculate}
          />
        </Grid>
      </Grid>
      <DeclaredAndResult
        declared={declared}
        hasDeclared={hasDeclared}
        hasResult={hasResult}
        result={result}
        onChangeResult={onChangeResult}
      />
    </Grid>
  );
};

export default QuestionArithmetic;