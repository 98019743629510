import { DocumentHelper, translate } from 'utils';
import { INPUT_TYPES, VALID } from 'utils/constants';

const isFieldRequired = ({ fieldsToCheck = [] }) => fieldsToCheck.some((field) => field);

const getMeasuredValueFormatted = (question) => {
  let measuredValue = question.measured;

  if (
    question.questionType.type === INPUT_TYPES.ENTITY
  ) {
    measuredValue = {
      ...measuredValue,
      ...measuredValue.address
    };
  }

  if ((
    question.questionType.type === INPUT_TYPES.DOCUMENT
    || question.questionType.type === INPUT_TYPES.SIGNATURE
    || question.questionType.type === INPUT_TYPES.GPS
  )) {
    if (measuredValue.base64Content) {
      measuredValue = {
        ...measuredValue,
        base64Content: DocumentHelper.getDocumentWithBase64(measuredValue.base64Content),
        editedBase64Content: measuredValue.editedBase64Content && DocumentHelper.getDocumentWithBase64(measuredValue.editedBase64Content)
      };
    } else if (measuredValue.images) {
      const images = measuredValue.images
        .map((img) => ({
          ...img,
          base64Content: img.base64Content && DocumentHelper.getDocumentWithBase64(img.base64Content),
          editedBase64Content: img.editedBase64Content && DocumentHelper.getDocumentWithBase64(img.editedBase64Content)
        }));

      measuredValue = {
        ...measuredValue,
        images
      };
    }
  }

  return measuredValue;
};

const preventNumberInput = (e) => {
  const charStr = String.fromCharCode(e.which);
  if (/\d/.test(charStr)) {
    return e.preventDefault();
  }

  return e;
};

const formatNumberInput = (e, currentValue, allowDecimal = false) => {
  // Limit possible inputs to numbers or separators
  if ((e.which < 48 || e.which > 57) && e.which !== 8) {
    if (!allowDecimal || (e.which !== 44 && e.which !== 46)) {
      return e.preventDefault();
    }
    // Only one separator per number
    if (/[,.]/g.test(currentValue)) {
      return e.preventDefault();
    }
  }
  return e;
};

const preventLetterInput = (e) => {
  // 48 and 57 are the delimiters for the numbers code
  // 8 is the backspace code
  if ((e.which < 48 || e.which > 57) && e.which !== 8) {
    return e.preventDefault();
  }

  return e;
};

const checkMaxValue = (e, value, max) => {
  if (Number(value) > max) {
    return e.preventDefault();
  }

  return e;
};

const getInputPropsOnlyLetters = () => ({
  pattern: '[A-zÀ-ÿ ]+',
  title: translate('warnings.inputOnlyLetters')
});

const getInputPropsOnlyNumbers = () => ({
  pattern: '[0-9]+',
  title: translate('warnings.inputOnlyNumbers')
});

const normalizeString = (string) => string.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

const roundNumber = (number) => Math.round((Number(number) + Number.EPSILON) * 100) / 100;

const truncateNumber = (number) => Math.trunc((Number(number) + Number.EPSILON) * 100) / 100;

const isEmpty = (object) => !Object.values(object).some((field) => !!field);

const formatEmail = (email) => email.toLowerCase();

const validateSignatureQuestion = (question) => {
  const { measured = {} } = question;
  if (measured.base64Content) {
    return VALID;
  }
  if (question.required) {
    return translate('forms.errors.requiredSignature');
  }
  return '';
};

const validateDocumentQuestion = (question) => {
  const { measured = {} } = question;
  if (measured.images.some((image) => image.base64Content)) {
    return VALID;
  }
  if (question.required) {
    return translate('forms.errors.requiredDocument');
  }
  return '';
};

const validateSurfaceQuestion = (question) => {
  const { measured = {} } = question;
  if (measured.totalSurface) {
    if (Number(measured.totalSurface) > 0) {
      return VALID;
    }
    return translate('forms.errors.invalidSurface');
  }
  if (question.required) {
    return translate('forms.errors.requiredSurface');
  }
  return '';
};

const validateAddressQuestion = (question) => {
  const { measured = {} } = question;
  // If the value isn't set
  if (isEmpty(measured)) {
    // If the field is required, the field is invalid
    if (question.required) {
      return translate('forms.errors.requiredFields');
    }
    // Else, there is no validation on the field
    return '';
  }
  // If the value is set, check each input individually
  // Address 1 is mandatory
  if (!measured.address1) {
    return translate('forms.errors.requiredAddress');
  }
  // Postal code is mandatory
  if (!measured.postalCode) {
    return translate('forms.errors.requiredPostalCode');
  }
  // City is mandatory
  if (!measured.city) {
    return translate('forms.errors.requiredCity');
  }
  return VALID;
};

const validateEntityQuestion = (question) => {
  const { measured = {} } = question;
  // If the value isn't set
  if (isEmpty(measured)) {
    // If the field is required, the field is invalid
    if (question.required) {
      return translate('forms.errors.requiredFields');
    }
    // Else, there is no validation on the field
    return '';
  }
  // If the value is set, check each input individually
  // First name or siret is mandatory
  if (!measured.firstName && !measured.institutionIdentifier) {
    return translate('forms.errors.requiredFirstName');
  }
  // Last name is mandatory
  if (!measured.lastName) {
    return translate('forms.errors.requiredLastName');
  }
  // Email should have the correct format
  if (measured.email && !/^\S+@\S+\.\S+$/.test(measured.email)) {
    return translate('forms.errors.invalidEmail');
  }
  // Siret should be of length 14
  if (measured.institutionIdentifier && measured.institutionIdentifier.length !== 14) {
    return translate('forms.errors.invalidSiret');
  }
  // Address should be unset or valid
  const address = {
    address1: measured.address1,
    address2: measured.address2,
    postalCode: measured.postalCode,
    city: measured.city
  };
  const addressQuestion = {
    ...question,
    required: false, // the adress isn't mandatory on entity questions
    measured: address
  };
  const addressResult = validateAddressQuestion(addressQuestion);
  if (addressResult && addressResult !== VALID) {
    return addressResult;
  }
  return VALID;
};

const validateEnumQuestion = (question) => {
  // If the value is set, the field is valid
  if (question.measured && question.measured.label) {
    return VALID;
  }
  // If the value isn't set and the field is required, the field is invalid
  if (question.required) {
    return translate('forms.errors.requiredEnum');
  }
  // In other cases, there is no validation on the field
  return '';
};

const validateDateQuestion = (question) => {
  // If the value is set, the field is valid
  if (question.measured) {
    if (!Number.isNaN(Date.parse(question.measured))) {
      return VALID;
    }

    return translate('forms.errors.invalidDate');
  }
  // If the value isn't set and the field is required, the field is invalid
  if (question.required) {
    return translate('forms.errors.requiredField');
  }
  // In other cases, there is no validation on the field
  return '';
};

const validateNumberQuestion = (question) => {
  // If the value is set, the field is valid
  if (/^[0-9]+([.,][0-9]*)?$/.test(question.measured)) {
    return VALID;
  }
  // If the value is not a valid number, the field is invalid
  if (question.measured) {
    return translate('forms.errors.invalidNumber');
  }
  // If the value isn't set and the field is required, the field is invalid
  if (question.required) {
    return translate('forms.errors.requiredField');
  }
  // In other cases, there is no validation on the field
  return '';
};

const validateGPSQuestion = (question) => {
  const { measured = {} } = question;
  if (measured.base64Content) {
    return VALID;
  }

  if (question.required) {
    return translate('forms.errors.requiredGPS');
  }

  return '';
};

const validateFeedbackQuestion = (question) => {
  const { measured = {} } = question;
  if (measured) {
    return VALID;
  }
  if (question.required) {
    return translate('forms.errors.requiredFeedback');
  }
  return '';
};

const validateTextQuestion = (question) => {
  // If the value is set, the field is valid
  if (question.measured) {
    return VALID;
  }
  // If the value isn't set and the field is required, the field is invalid
  if (question.required) {
    return translate('forms.errors.requiredField');
  }
  // In other cases, there is no validation on the field
  return '';
};

const validateQuestion = (question) => {
  // Returns the first error message for the specified question
  // If the question is valid, return a constant "VALID"
  // If the question is empty and not required, return an empty string
  switch (question.questionType.type) {
  case INPUT_TYPES.DATE:
    return validateDateQuestion(question);
  case INPUT_TYPES.ENUM:
    return validateEnumQuestion(question);
  case INPUT_TYPES.ENTITY:
    return validateEntityQuestion(question);
  case INPUT_TYPES.ADDRESS:
    return validateAddressQuestion(question);
  case INPUT_TYPES.SURFACE:
    return validateSurfaceQuestion(question);
  case INPUT_TYPES.DOCUMENT:
    return validateDocumentQuestion(question);
  case INPUT_TYPES.SIGNATURE:
    return validateSignatureQuestion(question);
  case INPUT_TYPES.NUMBER:
    return validateNumberQuestion(question);
  case INPUT_TYPES.GPS:
    return validateGPSQuestion(question);
  case INPUT_TYPES.FEEDBACK:
    return validateFeedbackQuestion(question);
  case INPUT_TYPES.TEXT:
  default:
    return validateTextQuestion(question);
  }
};

export const FormHelper = {
  isFieldRequired,
  getMeasuredValueFormatted,
  preventNumberInput,
  formatNumberInput,
  preventLetterInput,
  checkMaxValue,
  getInputPropsOnlyLetters,
  getInputPropsOnlyNumbers,
  normalizeString,
  formatEmail,
  roundNumber,
  truncateNumber,
  validateQuestion
};