/* eslint-disable react/jsx-no-bind */
import { faEdit, faSignature } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid } from '@material-ui/core';
import { Button, InfoLine } from 'components';
import { useModal } from 'hooks';
import React, { useCallback } from 'react';
import { translate } from 'utils';

const QuestionSignature = ({
  name, value, onChange, questionText
}) => {
  const showModal = useModal();

  const handleOpenSignatureModal = useCallback(() => showModal({
    type: 'SIGNATURE',
    name,
    defaultValues: {
      defaultName: (value && value.name) || '',
      defaultOccupation: (value && value.occupation) || '',
      defaultSignature: (value && value.base64Content) || ''
    },
    questionText,
    onConfirm: (form) => onChange(name, form)
  }), [name, onChange, showModal, questionText, value]);

  return (
    <Grid alignItems="flex-start" container direction="column" spacing={2}>
      {(!value || !value.name) && (
        <Grid item>
          <Button startIcon={<FontAwesomeIcon icon={faSignature} />} onClick={handleOpenSignatureModal}>
            {translate('button.sign')}
          </Button>
        </Grid>
      )}

      {value && value.name && (
        <>
          <Grid item>
            <Grid alignItems="center" container justifyContent="space-between" spacing={4}>
              <Grid item>
                <InfoLine info={value.name} label="common.name" />
                <InfoLine info={value.occupation} label="common.status" />
              </Grid>
              <Grid item>
                <Button startIcon={<FontAwesomeIcon icon={faEdit} />} onClick={handleOpenSignatureModal}>
                  {translate('button.edit')}
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {value.base64Content && (
            <Grid item>
              <img
                alt={value.name}
                src={value.base64Content}
                style={{ maxWidth: '260px', border: '1px solid var(--grey-darker)' }}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

export default QuestionSignature;