/* eslint-disable react/no-array-index-key */
import { InputFile } from 'components/_commons/Form';
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, DialogActions, DialogContent, Divider, Grid, Typography
} from '@material-ui/core';
import { DocumentHelper, translate } from 'utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTh } from '@fortawesome/pro-regular-svg-icons';
import { InspectionService } from 'services';
import { useImportingReducer } from 'hooks';
import { ModalHeader } from './_ModalHeader';
import { TextError } from '../Text';

export const ImportFormModal = ({
  onConfirm, onClose
}) => {
  const [formImported, setFormImported] = useState(null);
  const [state, dispatch] = useImportingReducer();

  const handleImportForm = useCallback((doc) => {
    if (doc) {
      setFormImported(doc);
      dispatch({ type: 'reset' });
    }
  }, [dispatch]);

  const handleValidateModal = useCallback((e) => {
    e.preventDefault();

    if (!formImported) return;

    const sendForm = {
      base64Content: DocumentHelper.getDocumentWithoutBase64(formImported),
      name: formImported.name
    };

    dispatch({ type: 'isImporting' });

    InspectionService.importInspectionForm(sendForm)
      .then(() => {
        onConfirm();
        onClose();
      })
      .catch((error) => {
        dispatch({ type: 'error', error });
        setFormImported(null);
      });
  }, [formImported, onConfirm, onClose, dispatch]);

  return (
    <form onSubmit={handleValidateModal}>
      <ModalHeader onClose={onClose}>{translate('button.importSurvey')}</ModalHeader>

      <DialogContent>
        {state.error && state.error.message ? (
          <Typography component="div" gutterBottom>
            <TextError style={{ margin: 0, textAlign: 'left' }}>
              {state.error.message}
            </TextError>
          </Typography>
        ) : state.error && Object.keys(state.error).length > 0 && (
          <>
            <div>
              {Object.keys(state.error).map((err, index) => (
                <Typography component="div" gutterBottom key={index}>
                  <TextError style={{ margin: 0, textAlign: 'left' }}>
                    {`${err} : `}
                  </TextError>
                  {state.error[err].map((e, i) => (
                    <Typography component="p" key={i}>{e}</Typography>
                  ))}
                </Typography>
              ))}
            </div>
            <Divider style={{ margin: '2rem 0' }} />
          </>
        )}

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <InputFile
              document={formImported}
              fileAccepted=".xlsx"
              handleAddDocument={handleImportForm}
              handleDeleteDocument={null}
              id="importForm"
              label="button.importSurvey"
              required
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button disabled={state.isImporting} variant="text" onClick={onClose}>{translate('button.cancel')}</Button>
        <Button
          color="primary"
          disabled={!formImported || state.isImporting}
          startIcon={<FontAwesomeIcon icon={state.isImporting ? faSpinner : faTh} spin={state.isImporting} />}
          type="submit"
        >
          {translate('button.import')}
        </Button>
      </DialogActions>
    </form>
  );
};

ImportFormModal.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};