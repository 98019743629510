import React from 'react';
import styled from 'styled-components';
import Skeleton from '@material-ui/lab/Skeleton';

const QuestionContainer = styled.article`
  margin-bottom: 2rem;

  span:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const SkeletonQuestion = () => (
  <QuestionContainer>
    <Skeleton height={25} variant="rect" width={200} />
    <Skeleton height={40} variant="rect" width="100%" />
  </QuestionContainer>
);