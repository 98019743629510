import { API_ROUTES } from 'utils/constants';
import { RequestHelper } from 'utils';

// GET
const getReferenceData = () => RequestHelper.GET(API_ROUTES.REFERENCE_DATA());

const getInspectorList = () => RequestHelper.GET(API_ROUTES.INSPECTORS());

const getToolTypes = () => RequestHelper.GET(API_ROUTES.TOOLS_TYPES());

export const ReferenceDataService = {
  getReferenceData,
  getInspectorList,
  getToolTypes
};