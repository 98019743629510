import React, { createRef } from 'react';
import { configure } from 'mobx';
import ReactDOM from 'react-dom';
import { isMobileOnly } from 'react-device-detect';
import { IconButton, ThemeProvider } from '@material-ui/core';
import { ModalProvider } from 'hooks';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import { DATAC2E_THEME } from 'styles/DATAC2E_THEME';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { App } from './app/App';
import './index.css';
import './locales/i18n';
import * as serviceWorker from './serviceWorker';

configure({
  enforceActions: 'observed'
});

const notistackRef = createRef();

const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const displayCloseButton = (key) => (
  <IconButton onClick={onClickDismiss(key)}>
    <FontAwesomeIcon color="var(--white)" icon={faTimes} />
  </IconButton>
);

ReactDOM.render(
  <BrowserRouter>
    <ThemeProvider theme={DATAC2E_THEME}>
      <SnackbarProvider
        action={displayCloseButton}
        dense={isMobileOnly}
        maxSnack={3}
        ref={notistackRef}
      >
        <ModalProvider>
          <App />
        </ModalProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

const triggerSkipWaiting = (SW, state) => {
  if (state === 'installed' || state === 'waiting') {
    SW.postMessage({ type: 'SKIP_WAITING' });
  }
};

const updateSW = (registration) => {
  const newSW = registration.installing || registration.waiting;

  if (newSW) {
    triggerSkipWaiting(newSW, newSW.state);
    newSW.addEventListener('statechange', (event) => {
      triggerSkipWaiting(newSW, event.target.state);

      if (event.target.state === 'activated') {
        window.location.reload();
      }
    });
  }
};

const SWConfig = {
  onUpdate: (registration) => {
    updateSW(registration);

    registration.addEventListener('updatefound', () => updateSW(registration));
  }
};

serviceWorker.register(SWConfig);