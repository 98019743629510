/* eslint-disable react/jsx-no-bind */
import React, { useCallback, useEffect } from 'react';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { FormHelper, translate } from 'utils';
import { IconButton } from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEquals, faPlusCircle, faTimes, faTrashAlt
} from '@fortawesome/pro-regular-svg-icons';
import { inputsFixtures } from '__fixtures__';
import { InputField } from '../../Inputs';
import { DeclaredAndResult, SubQuestionContainer } from '../../Question';

const Madornement = {
  endAdornment: (
    <InputAdornment position="end">m</InputAdornment>
  )
};

const M2adornement = {
  endAdornment: (
    <InputAdornment position="end">m²</InputAdornment>
  )
};

const MAX = 1000000000;

const StyledSurfaceRow = styled.article`
  padding: 1rem;
  background: var(--tecnea-blue-lighter);
`;

const SurfaceRow = ({
  zoneSurface, onDelete, onChange, onChangeSum
}) => {
  const { length = '', width = '', surface = '' } = zoneSurface;

  return (
    <StyledSurfaceRow>
      <Grid alignItems="center" container direction="row" spacing={2}>
        <Grid item sm={6} xs={12}>
          <Grid alignItems="center" container spacing={2} wrap="nowrap">
            <Grid item xs>
              <InputField
                InputProps={Madornement}
                label={translate('forms.inputs.length')}
                name="length"
                type="number"
                value={length}
                onChange={onChange}
                onKeyPress={(e) => FormHelper.checkMaxValue(e, length, MAX)}
              />
            </Grid>
            <Grid item>
              <FontAwesomeIcon icon={faTimes} />
            </Grid>
            <Grid item xs>
              <InputField
                InputProps={Madornement}
                label={translate('forms.inputs.width')}
                name="width"
                type="number"
                value={width}
                onChange={onChange}
                onKeyPress={(e) => FormHelper.checkMaxValue(e, width, MAX)}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Grid alignItems="center" container spacing={2} wrap="nowrap">
            <Grid item>
              <FontAwesomeIcon icon={faEquals} />
            </Grid>
            <Grid item>
              <InputField
                disabled={Boolean(width) || Boolean(length)}
                fullWidth={false}
                InputProps={M2adornement}
                label={translate('forms.inputs.surface')}
                name="surface"
                type="number"
                value={surface}
                onChange={onChangeSum}
                onKeyPress={(e) => FormHelper.checkMaxValue(e, surface, MAX)}
              />
            </Grid>
            <Grid item>
              <IconButton type="danger" onClick={onDelete}>
                <FontAwesomeIcon icon={faTrashAlt} size="sm" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledSurfaceRow>
  );
};

const QuestionSurface = ({
  name, value = {}, declared, result, required, onChange,
  hasDeclared, hasResult, onChangeResult, maxAnswerCount,
  code, codeArithmetic
}) => {
  const { totalSurface = '' } = value;
  let { surfaces } = value;

  useEffect(() => {
    if (!surfaces) {
      onChange(name, {
        surfaces: [],
        totalSurface: ''
      });
    }
  // eslint-disable-next-line
  }, [onChange, name]);

  const handleChangeTotalSurface = useCallback((_, inputVal) => {
    onChange(name, {
      surfaces,
      totalSurface: inputVal && FormHelper.truncateNumber(inputVal)
    });

    setTimeout(() => document.getElementById(codeArithmetic).click(), 500);
  }, [onChange, name, surfaces, codeArithmetic]);

  const updateSurface = useCallback((index) => {
    if (index >= 0) {
      // eslint-disable-next-line
      surfaces = surfaces.filter((_, i) => i !== index);
    }

    const calcTotalSurface = surfaces.reduce((acc, val) => acc + val.surface, 0);

    handleChangeTotalSurface(null, calcTotalSurface || 0);
  // eslint-disable-next-line
  }, [ surfaces, handleChangeTotalSurface]);

  const handleChangeSurfaceSum = useCallback((_, val, index) => {
    // Update surface sum inside surfaces array
    surfaces[index] = {
      width: 0,
      length: 0,
      surface: val && FormHelper.truncateNumber(val)
    };

    updateSurface();
  }, [surfaces, updateSurface]);

  const handleChangeSurfaceInput = useCallback((inputName, val, index) => {
    // Update surface input inside surfaces array
    const currentSurface = surfaces.find((_, i) => i === index);
    const newCurrentSurface = {
      ...currentSurface,
      [inputName]: val && FormHelper.truncateNumber(val)
    };

    surfaces[index] = {
      ...newCurrentSurface,
      surface: FormHelper.roundNumber(newCurrentSurface.length * newCurrentSurface.width)
    };

    updateSurface();
  }, [surfaces, updateSurface]);

  const handleAddSurface = useCallback(() => {
    surfaces.push(inputsFixtures.SURFACE);
    updateSurface();
  }, [surfaces, updateSurface]);

  if (!surfaces) return null;

  const isTotalSurfaceDisabled = surfaces.some((surf) => surf.surface > 0);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <InputField
            autoComplete="off"
            disabled={isTotalSurfaceDisabled}
            id={code}
            InputProps={M2adornement}
            label={translate('forms.inputs.totalSurface')}
            name="totalSurface"
            required={required}
            type="number"
            value={totalSurface}
            onChange={handleChangeTotalSurface}
            onKeyPress={(e) => FormHelper.checkMaxValue(e, totalSurface, MAX)}
          />
        </Grid>
        <DeclaredAndResult
          declared={declared}
          hasDeclared={hasDeclared}
          hasResult={hasResult}
          result={result}
          onChangeResult={onChangeResult}
        />
      </Grid>
      <SubQuestionContainer style={{ marginTop: '1rem' }}>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <Typography variant="body2">
              {translate('forms.inputs.surfaceDetail')}

              {surfaces.length < maxAnswerCount && (
                <IconButton type="primary" onClick={handleAddSurface}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                </IconButton>
              )}
            </Typography>
          </Grid>
          {surfaces.map((surface, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item key={index}>
              <SurfaceRow
                zoneSurface={surface}
                onChange={(inputName, val) => handleChangeSurfaceInput(inputName, val, index)}
                onChangeSum={(inputName, val) => handleChangeSurfaceSum(inputName, val, index)}
                onDelete={() => updateSurface(index)}
              />
            </Grid>
          ))}
        </Grid>
      </SubQuestionContainer>
    </>
  );
};

export default QuestionSurface;