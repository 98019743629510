/* eslint-disable react/jsx-no-bind */
import { Grid, Typography } from '@material-ui/core';
import { Mobile } from 'components';
import React, { useCallback, useState } from 'react';
import { FormHelper, translate } from 'utils';
import { InputField, ToggleDeclaredValues } from '../../Inputs';
import { InputWrapper, Result } from '../../Question';

const QuestionEntity = ({
  name, value = {}, declared = {}, result, required, onChange,
  hasDeclared, hasResult, onChangeResult, initialized, isDeclaredSet = null
}) => {
  const {
    firstName, lastName, email, institutionIdentifier, address1, address2, postalCode, city
  } = value;

  const {
    firstName: declaredFirstName, lastName: declaredLastName, email: declaredEmail,
    institutionIdentifier: declaredIdentifier, address: declaredAddress
  } = declared;

  const {
    address1: declaredAddress1, address2: declaredAddress2,
    postalCode: declaredPostalCode, city: declaredCity
  } = declaredAddress || {};

  const [declaredValueAccepted, setDeclaredValueAccepted] = useState(isDeclaredSet);

  const handleChange = useCallback((inputName, inputVal) => {
    let inputValue = inputVal;
    if (inputName === 'email') {
      inputValue = FormHelper.formatEmail(inputVal);
    }
    // eslint-disable-next-line no-param-reassign
    value[inputName] = inputValue;

    onChange(name, value);
  }, [onChange, name, value]);

  const handleIdentifierChange = useCallback((inputName, inputVal) => {
    const numericString = inputVal.replace(/\D/g, '');
    handleChange(inputName, numericString);
  }, [handleChange]);

  const displayDeclaredValues = Boolean(initialized && declaredLastName && declaredValueAccepted === null);

  return (
    <>
      {displayDeclaredValues && (
        <Grid alignItems="center" container spacing={2}>
          <Grid item>
            <Typography>{translate('pageInspectionForm.declaredValues')}</Typography>
          </Grid>
          <Grid item>
            <ToggleDeclaredValues
              declared={declared}
              declaredValueAccepted={declaredValueAccepted}
              name={name}
              setDeclaredValueAccepted={setDeclaredValueAccepted}
              value={value}
              onChange={onChange}
            />
          </Grid>
        </Grid>
      )}

      <Grid container direction="column" spacing={2} wrap="nowrap">
        <InputWrapper
          declared={declaredFirstName}
          hasDeclared={hasDeclared}
          hasResult={hasResult}
          result={result}
          onChangeResult={onChangeResult}
        >
          <InputField
            disabled={displayDeclaredValues || !!institutionIdentifier}
            inputProps={FormHelper.getInputPropsOnlyLetters()}
            label={translate('common.entityFirstName')}
            name="firstName"
            placeholder={displayDeclaredValues ? declaredFirstName : ''}
            required={required && !institutionIdentifier}
            value={firstName}
            onChange={handleChange}
            onKeyPress={FormHelper.preventNumberInput}
          />
        </InputWrapper>
        <InputWrapper declared={declaredLastName} hasDeclared={hasDeclared}>
          <InputField
            disabled={displayDeclaredValues}
            label={translate('common.entityLastName')}
            name="lastName"
            placeholder={displayDeclaredValues ? declaredLastName : ''}
            required={required}
            value={lastName}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper declared={declaredEmail} hasDeclared={hasDeclared}>
          <InputField
            disabled={displayDeclaredValues}
            label={translate('common.email')}
            name="email"
            placeholder={displayDeclaredValues ? declaredEmail : ''}
            type="email"
            value={email}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper declared={declaredIdentifier} hasDeclared={hasDeclared}>
          <InputField
            disabled={displayDeclaredValues || !!firstName}
            inputProps={{ maxLength: 14, minLength: 14 }}
            label={translate('common.entitySiret')}
            name="institutionIdentifier"
            placeholder={displayDeclaredValues ? declaredIdentifier : ''}
            required={required && !firstName}
            value={institutionIdentifier}
            onChange={handleIdentifierChange}
          />
        </InputWrapper>
        <InputWrapper declared={declaredAddress1} hasDeclared={hasDeclared}>
          <InputField
            disabled={displayDeclaredValues}
            label={translate('common.address')}
            name="address1"
            placeholder={displayDeclaredValues ? declaredAddress1 : ''}
            required={FormHelper.isFieldRequired({ fieldsToCheck: [postalCode, city] })}
            value={address1}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper declared={declaredAddress2} hasDeclared={hasDeclared}>
          <InputField
            disabled={displayDeclaredValues}
            label={translate('common.address2')}
            name="address2"
            placeholder={displayDeclaredValues ? declaredAddress2 : ''}
            value={address2}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper declared={declaredPostalCode} hasDeclared={hasDeclared}>
          <InputField
            disabled={displayDeclaredValues}
            label={translate('common.postalCode')}
            name="postalCode"
            placeholder={displayDeclaredValues ? declaredPostalCode : ''}
            required={FormHelper.isFieldRequired({ fieldsToCheck: [address1, city] })}
            value={postalCode}
            onChange={handleChange}
          />
        </InputWrapper>
        <InputWrapper declared={declaredCity} hasDeclared={hasDeclared}>
          <InputField
            disabled={displayDeclaredValues}
            inputProps={FormHelper.getInputPropsOnlyLetters()}
            label={translate('common.city')}
            name="city"
            placeholder={displayDeclaredValues ? declaredCity : ''}
            required={FormHelper.isFieldRequired({ fieldsToCheck: [address1, postalCode] })}
            value={city}
            onChange={handleChange}
            onKeyPress={FormHelper.preventNumberInput}
          />
        </InputWrapper>

        {hasResult && (
          <Mobile>
            <Result
              hasResult={hasResult}
              result={result}
              onChangeResult={onChangeResult}
            />
          </Mobile>
        )}
      </Grid>
    </>
  );
};

export default QuestionEntity;